import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Spin,
  Typography,
  Drawer,
  message,
  Input,
} from "antd";
import _ from "lodash";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { deviceSchema } from "../../Utils/Schema";
import {
  createUserDeviceAction,
  updateUserDeviceAction,
} from "../../Actions/UserDeviceAction";
import { getUserByIdAction } from "../../Actions/authenticationAction";
import { useDispatch, useSelector } from "react-redux";
const UserDeviceDrawer = (props) => {
  const { editId, setEditId, isModalVisible, setIsModalVisible } = props;
  const [preloadedDeviceValue, setPreloadedDeviceValue] = useState("");
  const dispatch = useDispatch();
  const {
    allUserDevice,
    userById,
    isUpdateUserDeviceLoading,
    isCreateUserDeviceLoading,
  } = useSelector((state) => {
    const {
      allUserDevice,
      isUpdateUserDeviceLoading,
      isCreateUserDeviceLoading,
    } = state.userDevice;
    const { userById } = state.authentication;
    return {
      isUpdateUserDeviceLoading,
      isCreateUserDeviceLoading,
      allUserDevice,
      userById,
    };
  });
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: { preloadedDeviceValue },
    resolver: yupResolver(deviceSchema),
  });
  const the_device = _.filter(allUserDevice, function (o) {
    return o.id === editId;
  });
  useEffect(() => {
    if (editId) {
      setPreloadedDeviceValue(the_device[0]);
      reset(the_device[0]);
    }
  }, [the_device[0], reset]);
  const handleClose = () => {
    setEditId("");
    setIsModalVisible(false);
    setValue("platform", "");
    setValue("token", "");
    clearErrors();
  };

  const handleCreateDevice = (data) => {
    data.user_id = userById?.id;
    dispatch(createUserDeviceAction(data, successCreateDevice, failureDevice));
  };
  const successCreateDevice = () => {
    setEditId("");
    setIsModalVisible(false);
    reset();
    setValue("platform", "");
    setValue("token", "");
    dispatch(getUserByIdAction(userById?.id));
    clearErrors();
    setTimeout(() => {
      message.success("Your Device Created Successfully");
    }, 1000);
  };

  const failureDevice = (failureResponse) => {
    setTimeout(() => {
      message.error(failureResponse);
    }, 1000);
  };

  const handleEditDevice = (data) => {
    data.id = editId;
    dispatch(updateUserDeviceAction(data, UpdateCallBack, faliureUpdate));
  };
  const UpdateCallBack = () => {
    setIsModalVisible(false);
    setEditId("");
    reset();
    setValue("platform", "");
    setValue("token", "");
    dispatch(getUserByIdAction(userById?.id));
    clearErrors();
    setTimeout(() => {
      message.success("Your Device Updated Successfully");
    }, 1000);
  };
  const faliureUpdate = (failureResponse) => {
    setTimeout(() => {
      message.error(
        failureResponse ? failureResponse?.name[0] : "Something went wrong."
      );
    }, 1000);
  };
  const [width, setWidth] = useState(window.innerWidth);
  const getDimensions = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", getDimensions);
  }, [width]);

  useEffect(() => {
    if (!editId) {
      setEditId("");
    }
  }, [setValue, !editId]);
  return (
    <Drawer
      open={isModalVisible}
      maskClosable={false}
      onClose={handleClose}
      width={width > 370 ? "370px" : "100%"}
      className="drawer"
      title={editId ? "Edit Device" : "Add Device"}
    >
      <Spin
        spinning={
          editId ? isUpdateUserDeviceLoading : isCreateUserDeviceLoading
        }
      >
        <form
          onSubmit={handleSubmit(
            editId ? handleEditDevice : handleCreateDevice
          )}
        >
          <Row gutter={[0, 10]}>
            <Col span={24} className="formRows">
              <Typography className="details" style={{ paddingBottom: "4px" }}>
                Platform
              </Typography>
              <Controller
                as={<Input size="large" />}
                name="platform"
                control={control}
                className="inputLabel"
              />

              {errors.platform && (
                <p style={{ color: "red" }}>{errors.platform.message}</p>
              )}
            </Col>
          </Row>
          <Row gutter={[0, 10]}>
            <Col span={24} className="formRows">
              <Typography className="details" style={{ paddingBottom: "4px" }}>
                Token
              </Typography>
              <Controller
                as={<Input size="large" />}
                name="token"
                control={control}
                className="inputLabel"
              />
            </Col>
          </Row>
          <Row gutter={[8, 8]} style={{ paddingTop: "20px" }}>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              {editId ? (
                <Button size="large" className="editButton" htmlType="submit">
                  Update
                </Button>
              ) : (
                <Button size="large" className="editButton" htmlType="submit">
                  Add
                </Button>
              )}
            </Col>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <Button
                className="cancelButton"
                onClick={handleClose}
                size="large"
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </form>
      </Spin>
    </Drawer>
  );
};

export default UserDeviceDrawer;
