import React, { useState } from "react";
import {
  Row,
  Col,
  Typography,
  Card,
  Avatar,
  Popconfirm,
  Divider,
  message,
  Tooltip,
} from "antd";
import { useDispatch } from "react-redux";
import { deleteTrustee, getTrustees } from "../../Actions/trusteesAction";
import "./Trusteecard.css";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

import TrusteeModal from "./trusteeModal";
import { getSearchUser } from "../../Actions/searchUserAction";
function Trusteecard(props) {
  const {
    trustee,
    trusteeArray,
    isUpdateTrusteesLoading,
    setCommunityId,

    users_role,
    i,
    users_types
  } = props;
  const dispatch = useDispatch();
  const [isUpdateTrusteeModalVisible, setIsUpdateTrusteeModalVisible] =
    useState(false);
  const [editTrusteeId, setEditTrusteeId] = useState("");
  const handleDeleteTrustee = (trusteeId) => {
    dispatch(deleteTrustee(trusteeId, successDeleteTrustee));
  };
  const successDeleteTrustee = () => {
    dispatch(getTrustees(process.env.REACT_APP_COMMUNITY_ID));
    setTimeout(() => {
      message.success("Trustee deleted successfully");
    }, 1000);
    dispatch(getSearchUser("api/user_search?type=regular"));
  };
  const openEditTrusteeModal = () => {
    setIsUpdateTrusteeModalVisible(true);

    setEditTrusteeId(trusteeArray.id);
  };

  const secondColor = "#" + Math.floor(Math.random() * 1677).toString(14);
  const isUserTrustee = users_types.includes("trustee")
  const isUserAdmin = users_types.includes("admin")
  return (
    <Row>
      <Col span={24}>
        <Card className="card">
          <Row gutter={[{ xl: 46, lg: 46, md: 46 }, 0]}>
            <Col xl={8} lg={7} md={7} sm={24}>
              <Avatar
                size={145}
                style={{
                   marginTop: "-50px", 
                   background: secondColor }}
                shape="square"
                src={trustee?.image}
                key={i}
              >
                <Typography style={{ fontSize: "70px", color: "white" }}>
                  {trustee?.first_name[0]}
                </Typography>
              </Avatar>
            </Col>
            <Col xl={16} xxl={15} xs={24} md={17} lg={17} sm={24}>
              <Row gutter={[0, 10]}>
                <Col span={24}>
                  <Row
                    justify="end"
                    gutter={[{ xl: 20, lg: 20, md: 20, sm: 20, xs: 15 }, 0]}
                  >
                    <Col>
                      {(isUserTrustee ||
                        isUserAdmin) && (
                        <Tooltip title="Edit">
                          <EditOutlined
                            onClick={() => openEditTrusteeModal()}
                          />
                        </Tooltip>
                      )}
                      <TrusteeModal
                        isUpdateTrusteeModalVisible={
                          isUpdateTrusteeModalVisible
                        }
                        setIsUpdateTrusteeModalVisible={
                          setIsUpdateTrusteeModalVisible
                        }
                        trustee={trustee}
                        trusteeArray={trusteeArray}
                        editTrusteeId={editTrusteeId}
                        isUpdateTrusteesLoading={isUpdateTrusteesLoading}
                        setCommunityId={setCommunityId}
                      />
                    </Col>
                    <Col>
                      <Popconfirm
                        onConfirm={() => handleDeleteTrustee(trusteeArray.id)}
                        okText="Delete"
                        title="Are you sure?"
                        placement="topRight"
                        cancelText="Cancel"
                      >
                        {(isUserTrustee ||
                          isUserAdmin) && (
                          <Tooltip title="Delete">
                            <DeleteOutlined />
                          </Tooltip>
                        )}
                      </Popconfirm>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row gutter={[0, 10]}>
                <Col className="antZeroPadding">
                  <Typography
                    className="Titletext1"
                    style={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {trustee?.first_name} {trustee?.last_name}
                    <Typography className="PhoneAndEmail" component="span">
                      ({trusteeArray?.designation?.designation_title})
                    </Typography>
                  </Typography>
                </Col>
                <Col span={24}>
                  {/* <Row justify="center"> */}
                  {/* <Typography
                      className="PhoneAndEmail"
                      style={{ marginTop: "-10px",justifyContent:"end",display:"flex",width:"200px" }}
                    >
                      ({trusteeArray?.designation?.designation_title})
                    </Typography> */}
                  {/* </Row> */}
                </Col>
                <Col span={24}>
                  <Typography
                    className="PhoneAndEmail"
                    style={{
                      paddingBottom: "10px",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {trustee.phone} | {trustee.email}
                  </Typography>
                </Col>
              </Row>
            </Col>
          </Row>
          <Divider
            className={
              users_role?.user_type === "trustee" ||
              users_role?.user_type === "admin"
                ? "border"
                : "paddingBorder"
            }
          ></Divider>
          <Row gutter={[0, 10]}>
            <Col span={24} />
            <Col className="Address">
              {/* {trusteeAddress} */}
              {trustee?.user_addresses.length > 0 ? (
                trustee.user_addresses?.map((val) => {
                  return val.length > 0 ? (
                    val.map((Address) => {
                      return (
                        Address?.address_line_1 +
                        " " +
                        Address.home_number +
                        " " +
                        Address.address_line_2 +
                        " " +
                        Address.city +
                        "," +
                        Address.zip
                      );
                    })
                  ) : (
                    <div>Edit your data</div>
                  );
                })
              ) : (
                <div>Edit your data</div>
              )}
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
}
export default React.memo(Trusteecard);
