import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Spin,
  Empty,
  Typography,
  message,
  Card,
  Pagination,
  Tooltip,
  Breadcrumb,
  Drawer,
  Select,
  Input,
  Checkbox,
  Popconfirm,
  Tabs,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getSearchUser } from "../../Actions/searchUserAction";
import CustomTable from "../ReuseableComponents/CustomTable/CustomTable";
import { Controller, useForm } from "react-hook-form";

import {
  DeleteColumnAction,
  EditColumnAction,
} from "../ReuseableComponents/CustomTable/CustomTableColumnActions";
import _ from "lodash";
import { getEvents } from "../../Actions/eventsAction";
import EventsDrawer from "./EventsDrawer";
import { ProfileOutlined } from "@ant-design/icons";
import { history } from "../../Utils/history";
import { getCommunityById } from "../../Actions/communitiesAction";

const Events = (props) => {
  const {
    setValName,
    valName,
    setSelectedEvent,
  } = props;
  const dispatch = useDispatch();
  const { setCommunityId, allEvents, isGetEventsLoading } = useSelector(
    (state) => {
      const { setCommunityId } = state.communities;
      const { allEvents, isGetEventsLoading } = state.events;
      return {
        setCommunityId,
        allEvents,
        isGetEventsLoading,
      };
    }
  );
  const {
    formState: { errors },
    reset,
  } = useForm({
  });
  const [currentPage, setCurrentPage] = useState(1);
  const { total_length } = allEvents || {};
  useEffect(() => {
    dispatch(
      getEvents(
        `api/community_events?community_id=${setCommunityId}&page=${currentPage}`
      )
    );
  }, [currentPage]);
  const [isEventsDrawerVisible, setIsEventsDrawerVisible] = useState(false);
  const openEventDetail = (Id) => {
    history.push(`/communityevents/${Id}`, { id: Id });
  };
  const eventsColumn = [
    {
      title: "Event Name",
      dataIndex: "event_name",
      width: "20%",
      sorter: (a, b) => (a.event_name.localeCompare(b.event_name)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: "City",
      dataIndex: "city_name",
      width: "20%",
      sorter: (a, b) => (a.city_name.localeCompare(b.city_name)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: "Action",
      dataIndex: "id",
      width: "20%",
      render: (id) => {
        return (
          <Row>
            {/* <EditColumnAction id={id} customMethod={openEditDrawer} /> */}
            <Tooltip title="View Event">
              <ProfileOutlined onClick={() => openEventDetail(id)} />
            </Tooltip>
          </Row>
        );
      },
    },
  ];
  const onClickCommunity = () => {
    history.push("/communities");
    dispatch(getCommunityById(""));
    dispatch({
      type: "GET_SELECTED_COMMUNITY_ID",
      payload: "",
    });
  };
  const handleOpenEvent = (Id) => {
    setValName(Id);
    setSelectedEvent(true);
  };
  const onClickEvents = () => {
    setSelectedEvent("");
    setValName("");
    reset();
  };
  const the_name = _.filter(allEvents.community_events, function (o) {
    return o.id === valName;
  });
  return (
      <Spin spinning={isGetEventsLoading} size="large">
      <Row gutter={[32, 32]} 
         className="community" 
      >
      <Col span={24}></Col>
      <Col xl={16} lg={16} md={16} sm={20} xs={20}>
        <Breadcrumb 
        // style={{ margin: 10 }}
        >
          <Breadcrumb.Item
            onClick={() => onClickCommunity()}
            className="breadcrumCommunity"
          >
            Communities
          </Breadcrumb.Item>
          <Breadcrumb.Item
            onClick={() => onClickEvents()}
            className="breadcrumUsers"
          >
            Events
          </Breadcrumb.Item>
          <Breadcrumb.Item className="breadcrumUsers">
            {the_name[0]?.event_name}
            {/* {community_events[0]?.event_name} */}
          </Breadcrumb.Item>
        </Breadcrumb>
      </Col>
      <Col xl={8} lg={8} md={8} sm={8} xs={8}>
          <Col span={24}>
            <Row justify="end">
              <Button
                type="primary"
                onClick={() => setIsEventsDrawerVisible(true)}
              >
                Add Event
              </Button>
              <EventsDrawer
                isEventsDrawerVisible={isEventsDrawerVisible}
                setIsEventsDrawerVisible={setIsEventsDrawerVisible}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </Row>
          </Col>
          </Col>
          <Col span={24}>
            <CustomTable
              columns={eventsColumn}
              data={allEvents?.community_events}
            />
          </Col>
          {allEvents && total_length > 0 ? (
          <Col span={23}>
            <Row justify="center" 
            // style={{ margin: "20px 0px" }}
            >
              <Pagination
                total={allEvents?.total_length}
                onChange={(e) => setCurrentPage(e)}
                current={currentPage}
                showSizeChanger={false}
              />
            </Row>
          </Col>
          ):""}
     </Row>
      </Spin>
  );
};

export default Events;
