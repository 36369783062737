import constants from "../Utils/constants";
const initialState = {
  allUserDevice: [],
  setUserDeviceId: "",
  isUserDeviceLoading: false,
  isCreateUserDeviceLoading: false,
  isUpdateUserDeviceLoading: false,
  isDeleteUserUserDeviceLoading: false,
};

export const UserDeviceReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_USER_DEVICE_REQUEST:
      return {
        ...state,
        isUserDeviceLoading: true,
      };

    case constants.GET_USER_DEVICE_SUCCESS:
      return {
        ...state,
        isUserDeviceLoading: false,
        allUserDevice: action.payload,
      };
    case constants.GET_USER_DEVICE_FAILURE:
      return {
        ...state,
        isUserDeviceLoading: false,
      };

    case constants.CREATE_USER_DEVICE_REQUEST:
      return {
        ...state,
        isCreateUserDeviceLoading: true,
      };
    case constants.CREATE_USER_DEVICE_SUCCESS:
      return {
        ...state,
        isCreateUserDeviceLoading: false,
      };
    case constants.CREATE_USER_DEVICE_FAILURE:
      return {
        ...state,
        isCreateUserDeviceLoading: false,
      };

    case constants.UPDATE_USER_DEVICE_REQUEST:
      return {
        ...state,
        isUpdateUserDeviceLoading: true,
      };
    case constants.UPDATE_USER_DEVICE_SUCCESS:
      return {
        ...state,
        isUpdateUserDeviceLoading: false,
      };
    case constants.UPDATE_USER_DEVICE_FAILURE:
      return {
        ...state,
        isUpdateUserDeviceLoading: false,
      };
    case constants.DELETE_USER_DEVICE_REQUEST:
      return {
        ...state,
        isDeleteUserUserDeviceLoading: true,
      };
    case constants.DELETE_USER_DEVICE_SUCCESS:
      return {
        ...state,
        isDeleteUserUserDeviceLoading: false,
      };
    case constants.DELETE_USER_DEVICE_FAILURE:
      return {
        ...state,
        isDeleteUserUserDeviceLoading: false,
      };
      case constants.GET_SELECTED_USER_DEVICE_ID:
        return {
          ...state,
          setUserDeviceId: action.payload,
        };
    case constants.RESET_APP_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
