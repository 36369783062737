import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  message,
  Spin,
  Tooltip,
  Popconfirm,
  Breadcrumb,
  Card,
  Avatar,
  Typography,
} from "antd";
import _ from "lodash";
import JourneyRouteDrawer from "./JourneyRouteDrawer";
import { useDispatch, useSelector } from "react-redux";
import { ArrowLeftOutlined } from "@ant-design/icons";
import Edit2 from "../../../assets/Edit2.svg";
import { ImageEnv } from "../../CommonLogics/CommonMethods";

import { getJourneyById } from "../../../Actions/JourneyAction";
import {
  getJourneyRoute,
  deleteJourneyRoute,
} from "../../../Actions/JourneyRouteAction";
import Delete from "../../../assets/delete.svg";
import { history } from "../../../Utils/history";

const JourneyRoute = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editId, setEditId] = useState("");
  const [journeyDetailId, setJourneyDetailId] = useState("");
  const [selectedJourney, setSelectedJourney] = useState(false);
  const [valName, setValName] = useState("");
  const [isDefaultImageVisible, setIsDefaultImageVisible] = useState(true);
  const [showUploadImage, setShowUploadImage] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const dispatch = useDispatch();
  let UnpublishData = {};
  let PublishData = {};
  const { allJourney, isjourneyloading, allJourneyRoute, setjourneyRouteId } =
    useSelector((state) => {
      const { allJourney, isjourneyloading, setjourneyId } = state.journey;
      const { allJourneyRoute, journeyRoute, setjourneyRouteId } =
        state.journeyRoute;

      return {
        setjourneyRouteId,
        allJourney,
        isjourneyloading,
        setjourneyId,
        allJourneyRoute,
        journeyRoute,
      };
    });

  useEffect(() => {
    dispatch(
      getJourneyRoute(`api/journey_routes?journey_id=${setjourneyRouteId}`)
    );
  }, [setjourneyRouteId]);
  const the_journey = _.filter(allJourney.journeys, function (o) {
    return o.id === editId;
  });

  const handleDeleteCountries = (Id) => {
    dispatch(deleteJourneyRoute(Id, successDelete, failureDelete));
  };
  const successDelete = () => {
    setTimeout(() => {
      message.success("Your Step Deleted Successfully");
    }, 1000);
    dispatch(
      getJourneyRoute(`api/journey_routes?journey_id=${setjourneyRouteId}`)
    );
  };
  const failureDelete = () => {
    setTimeout(() => {
      message.error("Something went wrong.");
    }, 1000);
  };
  const handleEdit = (id) => {
    setEditId(id);
    setIsModalVisible(true);
    setIsDefaultImageVisible(true);
    setShowUploadImage(false);
  };

  const handleCloseJourney = () => {
    setJourneyDetailId("");
    history.push("/journey");
    dispatch(getJourneyById(""));
    dispatch({
      type: "GET_SELECTED_JOURNEY_ID",
      payload: "",
    });
  };
  return (
    <Spin spinning={isjourneyloading}>
      <Row justify="space-evenly" gutter={[32, 32]}>
        <Col span={24}></Col>
        <Col span={24}></Col>
        <Col xl={1} lg={1} md={1} sm={1} xs={1}>
          <ArrowLeftOutlined onClick={() => handleCloseJourney()} />
        </Col>
        <Col xl={15} lg={15} md={15} sm={23} xs={23}>
          <Breadcrumb>
            <Breadcrumb.Item className="breadcrumCommunity">
              View Blog
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col xl={6} lg={6} md={6} sm={24} xs={24}>
          <Row justify="end">
            <Button
              className="editDesignationButton"
              style={{ width: "100%" }}
              onClick={() => setIsModalVisible(true)}
            >
              Add Step
            </Button>
            <JourneyRouteDrawer
              isModalVisible={isModalVisible}
              setIsModalVisible={setIsModalVisible}
              editId={editId}
              setEditId={setEditId}
              journeyDetailId={journeyDetailId}
              setJourneyDetailId={setJourneyDetailId}
              setValName={setValName}
              valName={valName}
              setSelectedJourney={setSelectedJourney}
              selectedJourney={selectedJourney}
              setIsDefaultImageVisible={setIsDefaultImageVisible}
              isDefaultImageVisible={isDefaultImageVisible}
              setShowUploadImage={setShowUploadImage}
              showUploadImage={showUploadImage}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              setjourneyRouteId={setjourneyRouteId}
            ></JourneyRouteDrawer>
          </Row>
        </Col>
        <Row></Row>
      </Row>

      <Row gutter={[32, 32]}>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          {allJourneyRoute?.map((val, i) => {
            return (
              <Row gutter={[16, 16]} justify="space-between">
                <Col xl={12} lg={12} md={12} sm={24} xs={24} key={i}>
                  <Card
                    className="card-community"
                  >
                    <Row>
                      <Col span={18}>
                        <Typography className="community-Title">
                          {" "}
                          Step : {val.position}
                        </Typography>
                      </Col>
                      <Col
                        xl={6}
                        lg={6}
                        md={6}
                        sm={8}
                        xs={8}
                        key={i}
                        style={{ textAlign: "end" }}
                      >
                        <Tooltip title="Edit">
                          <Button
                            icon={<img src={Edit2} />}
                            type="text"
                            onClick={() => handleEdit(val.id, val)}
                          />
                        </Tooltip>
                        <Tooltip title="Delete">
                          <Popconfirm
                            title="Are you sure?"
                            onConfirm={() => handleDeleteCountries(val.id)}
                            okText="ok"
                            cancelText="Delete"
                          >
                            <Button icon={<img src={Delete} />} type="text" />
                          </Popconfirm>
                        </Tooltip>
                      </Col>
                     
                    
                      <Col xl={4} lg={4} md={4} sm={4} xs={4}>
                        <Avatar
                          shape="square"
                          size={65}
                          className="avtar"
                          src={
                            val.journey_route_image_key &&
                            ImageEnv(val.journey_route_image_key)
                          }
                        >
                          <Typography
                            style={{ fontSize: "20px", color: "white" }}
                          >
                            {val.title?.[0]}
                          </Typography>
                        </Avatar>
                      </Col>
                      
                      <Col xl={20} lg={20} md={20} sm={24} xs={24}>
                        <Row justify='space-around' gutter={[16,16]}>
                      <Col span={24}>
                        <Typography className="detailsPage2">
                          {" "}
                          Title : {val.title}
                        </Typography>
                        </Col>
                        <Col span={24}>
                        <Typography className="detailsPage2">
                          {" "}
                          Description : {val.description}{" "}
                        </Typography>
                      </Col>
                      </Row>
                      </Col>
                     
                    </Row>
                  </Card>
                </Col>
              </Row>
            );
          })}
        </Col>
      </Row>
    </Spin>
  );
};

export default JourneyRoute;
