import React, { useEffect } from "react";
import { Row, Spin, Table, Button, Col, message, Progress } from "antd";
import { useDispatch, useSelector } from "react-redux";

import "../../FindThePeoplePage/FindOtherPeople.css";
import "./VolunteerSideTable.css";

import { history } from "../../Utils/history";
import { updateVerifiedByVolunteerAction } from "../../Actions/nativeVolunteerAction";
// import { useBreakpoints } from "react-responsive-hooks";
import {useBreakpoints} from "../../Screens/CommonLogics/ResponsiveBreakPoints"

import { getFamiliesByNativeForVolunterAction } from "../../Actions/FamiliesByNativeForVolunteerAction";

const VolunteerSideTable = () => {
  const { IsXL } = useBreakpoints();

  const dispatch = useDispatch();

  const {
    currentUser,
    allFamiliesByNativeForVolunteer,
    isFamiliesByNativeForVolunteerLoading,
  } = useSelector((state) => {
    const { searchUsers, isSearchUserLoading } = state.searchUser;
   
    const { currentUser } = state.authentication;
    
    const {
      allFamiliesByNativeForVolunteer,
      isFamiliesByNativeForVolunteerLoading,
    } = state.FamiliesByNativeForVolunteer;
    return {
      searchUsers,
      isSearchUserLoading,

      currentUser,
      allFamiliesByNativeForVolunteer,
      isFamiliesByNativeForVolunteerLoading,
    };
  });
  const {users_types} = currentUser || {};
  const isUserVolunteer = users_types.includes("volunteer")
  const redirectMethod = () => {
    history.push("/addfamily");
  };
  useEffect(() => {
    dispatch(getFamiliesByNativeForVolunterAction());
   
    if (!isUserVolunteer) {
      redirectMethod();
    }
  }, [dispatch]);
  const notVerifiedFamilies = allFamiliesByNativeForVolunteer.filter(
    (Families) => {
      return Families.verified_family === false;
    }
  );
  // const { IsXL } = useBreakpoints();

  const columns = [
    {
      title: "Family Name",

      width: !IsXL && "15em",
      render: ({ last_name }) => {
        return last_name;
      },
    },
    // {
    
    //   title: t("content.19"),
    //   // dataIndex:"user_addresses",
    //   render:({user_addresses}) => {
    //   return  user_addresses?.map((User) => {
    //     return User?.map((UserAddress) => {
    //         return(
    //         <Row>
    //           <Col span={24}>
    //          {UserAddress.address_line_1 + " " + UserAddress.address_line_2 + " " + UserAddress.city + "," + UserAddress.zip}
    //           </Col>
    //         </Row>

    //        )
    //       })

    //     })

    //     // .[0].address_line_1

    //   },

    // },
    // {
    //   title: t("content.17"),
    //   dataIndex: "phone",
    //   width:!IsXL && "15em"
    // },
    {
      title: "Family Progress",
      render: ({ family_progres }) => {
        return (
          <Progress
            percent={family_progres}
            strokeColor={
              family_progres < 50
                ? "red"
                : family_progres < 70
                ? "#0064FF"
                : "green"
            }
          />
        );
      },
      width: !IsXL && "15em",
    },
    {
      dataIndex: "id",
      render: (id) => <ActionComponent id={id} />,

      width: !IsXL && "15em",
    },
  ];

  const height = window.innerHeight - 64;

  return (
   
    <div
      style={{
        height: height,
        overflow: "hidden",
        overflowY: "scroll",
        backgroundColor: "#FFFFFF",
        // margin: "10px",
      }}
      className="customLayout"
    >
      <Row justify="space-around" 
      // style={{ margin: "20px" }}
      >
        <Col span={24}>
          <Spin spinning={isFamiliesByNativeForVolunteerLoading} size="large">
            <div className="AdminTable">
              <Table
                columns={columns}
                dataSource={notVerifiedFamilies}
                className="table"
              />
            </div>
          </Spin>
        </Col>
      </Row>
    </div>
  );
};
export default VolunteerSideTable;

const ActionComponent = (props) => {
  const dispatch = useDispatch();

  const { id } = props;
  const acceptUserMethod = (UserId) => {
    let acceptUserData = {};
    acceptUserData.id = UserId;

    acceptUserData.verified_family = true;
    dispatch(
      updateVerifiedByVolunteerAction(acceptUserData, successAcceptUserMethod)
    );
  };

  const successAcceptUserMethod = () => {
    setTimeout(() => {
      message.success("Family Verified Successfully");
    }, 1000);

    dispatch(getFamiliesByNativeForVolunterAction());
  };

  // const rejectUserMethod = (UserId) => {
  //   let rejectUserData = {};
  //   rejectUserData.id = UserId;
  //   rejectUserData.verified_family = false;
  //   dispatch(
  //     updateVerifiedByVolunteerAction(rejectUserData, successRejectUserMethod)
  //   );
  // };

  // const successRejectUserMethod = () => {
  //   setTimeout(() => {
  //     message.success("Family Rejected Successfully");
  //   }, 1000);
  //   dispatch(getFamiliesByNativeForVolunterAction());
  // };
  return (
    <Row justify="space-around">
      <Col span={10}>
        <Button
          type="primary"
          className="acceptButton"
          onClick={() => acceptUserMethod(id)}
        >
          Accept
        </Button>
      </Col>
      {/* <Col span={10}>
                <Button type="primary" className="rejectButton" onClick={() => rejectUserMethod(id)}>{t("content.157")}</Button>
           </Col> */}
    </Row>
  );
};
