import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Typography,
  Input,
  Divider,
  Checkbox,
  Upload,
  Spin,
  message,
} from "antd";
import { Controller, useForm } from "react-hook-form";
import "./Admin.css";
import "../AddFamilymember/Addfamily.css";

import {
  updateCommunitiesAction,
  getCommunityById,
} from "../../Actions/communitiesAction";
import { useDispatch, useSelector } from "react-redux";

import _ from "lodash";
import { settingsSchema } from "../../Utils/Schema";
import { yupResolver } from "@hookform/resolvers/yup";
import { PlusOutlined } from "@ant-design/icons";

const Settings = () => {

  const { community, isUpdateCommunityLoading } = useSelector((state) => {
    const { community, isUpdateCommunityLoading } = state.communities;
    return {
      community,
      isUpdateCommunityLoading,
    };
  });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCommunityById(process.env.REACT_APP_COMMUNITY_ID));
  }, [dispatch]);

  const { handleSubmit, control, errors } = useForm({
  
    resolver: yupResolver(settingsSchema),
    defaultValues: community,
  });

  const [isTrusteeChecked, setIsTrusteeChecked] = useState(
    community?.show_trustee
  );
  const [isEventsChecked, setIsEventsChecked] = useState(community?.show_event);
  const [isFindOtherPeopleChecked, setIsFindOtherPeopleChecked] = useState(
    community?.show_find_other_people
  );
  const [isMatrimonialChecked, setIsMatrimonialChecked] = useState(
    community?.show_matrimonial
  );

  const [isBusinessProfileChecked, setIsBusinessProfileChecked] = useState(
    community?.show_business_profile
  );
  const [newFiles, setNewFiles] = useState([]);
  const [isDefaultImageVisible, setIsDefaultImageVisible] = useState(true);
  const [showUploadImage, setShowUploadImage] = useState(false);
  const IsEmpty = _.isEmpty(newFiles);
  const editCommunityMethod = (data) => {
    data.id = community.id;

    data.show_trustee = isTrusteeChecked;
    data.show_event = isEventsChecked;
    data.community_logo = IsEmpty ? null : newFiles;
    data.show_find_other_people = isFindOtherPeopleChecked;
    data.show_matrimonial = isMatrimonialChecked;
    data.show_business_profile = isBusinessProfileChecked;
    dispatch(
      updateCommunitiesAction(data, successEditCommunityMethod, IsEmpty)
    );
  };

  const successEditCommunityMethod = () => {
    setTimeout(() => {
      message.success("Your Community Edited Successfully");
    }, 1000);
    dispatch(getCommunityById(process.env.REACT_APP_COMMUNITY_ID));
  };
  const trusteeCheckboxMethod = (e) => {
    setIsTrusteeChecked(e);
  };

  const eventsCheckedMethod = (e) => {
    setIsEventsChecked(e);
  };
  const findOtherPeopleCheckedMethod = (e) => {
    setIsFindOtherPeopleChecked(e);
  };
  const matrimonialCheckedMethod = (e) => {
    setIsMatrimonialChecked(e);
  };
  const businessProfileCheckedMethod = (e) => {
    setIsBusinessProfileChecked(e);
  };
  const propsForUpload = {
    action: editCommunityMethod,
    onRemove: (file) => {},
    beforeUpload: (file) => {
      setNewFiles(file);
      return false;
    },
    multiple: false,
  };
  // const ButtonAfter = (
  //   <Upload {...propsForUpload} fileList={[]}>
  //     <Button
  //       type="primary"
  //       size="medium"
  //       style={{ margin: "3px" }}
  //       className="editDesignationButton"
  //     >
  //       Upload
  //     </Button>
  //   </Upload>
 
  // );

  //  if(!isTrusteeChecked){
  //  community.show_trustee = false
  // }
  //  if(isTrusteeChecked){
  //   community.show_trustee = true
  // }
  // if(isEventsChecked){

  //   community.show_event = true
  // }
  // if(!isEventsChecked){
  //   community.show_event = false
  // }

  // else{
  // }
  const handleUploadMethod = () => {
    setShowUploadImage(true);
    setIsDefaultImageVisible(false);
  
  };
  return (
    <div>
      <form onSubmit={handleSubmit(editCommunityMethod)}>
        <Spin spinning={isUpdateCommunityLoading} size="large">
          <Row gutter={[10, 22]}>
            <Col>
              <Row justify="center">
                <Col>
                  {isDefaultImageVisible && community?.community_logo && (
                    <Upload
                      listType="picture-card"
                      openFileDialogOnClick={false}
                    >
                      <img
                        src={community?.community_logo}
                        width={102}
                        alt=""
                      ></img>
                      {/* <PlusOutlined size={44}/>Upload Image */}
                    </Upload>
                  )}
                </Col>
                <Col>
                  <Upload
                    {...propsForUpload}
                    listType="picture-card"
                    maxCount={1}
                    showUploadList={showUploadImage}
                    onChange={() => handleUploadMethod()}
                  >
                    <PlusOutlined size={44} />
                    Upload Image
                  </Upload>
                </Col>
              </Row>
            </Col>

            <Col xl={6}>
              <Typography
                className="SettingsInputLabel"
                style={{ paddingBottom: "7px" }}
              >
                Community Name
              </Typography>
              <Controller
                as={<Input className="inputLabel" size="large" />}
                name="name"
                control={control}
              />

              {errors.name && (
                <p style={{ color: "red" }}>{errors.name.message}</p>
              )}
            </Col>
            <Col span={24}>
              <Typography
                className="SettingsInputLabel"
                style={{ paddingBottom: "7px" }}
              >
                Describe Community
              </Typography>
              <Controller
                as={
                  <Input.TextArea
                    size="large"
                    maxLength={100}
                    style={{ height: 120 }}
                  />
                }
                name="description"
                control={control}
              />
              {errors.description && (
                <p style={{ color: "red" }}>{errors.description.message}</p>
              )}
            </Col>
            <Divider className="SettingsBorderLine" />
          </Row>
          <Row gutter={[0, 16]}>
            <Col span={24}>
              <Typography className="SettingsAccessTitle">
              Access
              </Typography>
            </Col>
            <Col span={24} />
          </Row>
          <Row gutter={[0, 16]}>
            <Col span={24}>
              <Row gutter={[17, 0]}>
                <Col>
                  {/* <Controller */}
                  <Checkbox
                    value={"Find Other People"}
                    checked={isFindOtherPeopleChecked}
                    className="SettingsCheckboxTitle"
                    onChange={(e) =>
                      findOtherPeopleCheckedMethod(e.target.checked)
                    }
                  >
                    Find Other People
                  </Checkbox>
                  {/* name="checkbox1"
                            control={control}
                           /> */}
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[17, 0]}>
                <Col>
                  {/* <Controller */}

                  <Checkbox
                    value={isEventsChecked ? true : false}
                    checked={isEventsChecked}
                    onChange={(e) => eventsCheckedMethod(e.target.checked)}
                    className="SettingsCheckboxTitle"
                  >
                    Events
                  </Checkbox>
                  {/* name="checkbox2"
                           control={control} */}
                  {/* /> */}
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[17, 0]}>
                <Col span={24}>
                  <Checkbox
                    value={"Trustee"}
                    checked={isTrusteeChecked}
                    onChange={(e) => trusteeCheckboxMethod(e.target.checked)}
                    className="SettingsCheckboxTitle"
                  >
                   Trustees
                  </Checkbox>

                  {/* name="checkbox3"
                           control={control}
                          /> */}
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[17, 0]}>
                <Col>
                  {/* <Controller */}
                  <Checkbox
                    value={"Matrimonials"}
                    checked={isMatrimonialChecked}
                    className="SettingsCheckboxTitle"
                    onChange={(e) => matrimonialCheckedMethod(e.target.checked)}
                  >
                    Matrimonials
                  </Checkbox>

                  {/* name="checkbox4"
                           control={control}
                        
                        /> */}
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[17, 0]}>
                <Col>
                  {/* <Controller */}
                  <Checkbox
                    value={"Business profiles"}
                    checked={isBusinessProfileChecked}
                    className="SettingsCheckboxTitle"
                    onChange={(e) =>
                      businessProfileCheckedMethod(e.target.checked)
                    }
                  >
                    Business Profiles
                  </Checkbox>
                  {/* name="checkbox5"
                           control={control}
                          /> */}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={[10, 37]}>
            <Col span={24} />
            <Col>
              <Button
                type="primary"
                htmlType="submit"
                className="SettingsButton"
              >
                Save
              </Button>
            </Col>

            <Col>
              <Button className="SettingsCancelButton">Cancel</Button>
            </Col>
          </Row>
        </Spin>
      </form>
    </div>
  );
};
export default Settings;
