import React, { useState, useEffect } from "react";
import {Button, Row, Col, Typography, message, } from "antd";
import "../App.css";
// import Signup from "./Signup";
import {useForm } from "react-hook-form";
// import GujaratiButton from "../components/GujaratiButton";
// import EnglishButton from "../components/EnglishButton";
// import Community from "./Community/Community";
// import Selectcircle from "./Selectcircle/Selectcircle";
// import FindOtherPeople from "../FindThePeoplePage/FindOtherPeople";
import { loginUserAction } from "../Actions/authenticationAction";
import { useDispatch, useSelector } from "react-redux";
import { history } from "../Utils/history";
// import { getCommunityById } from "../Actions/communitiesAction";
import PhoneInput from "react-phone-input-international";
import "react-phone-input-international/lib/style.css";
import { phoneFormatterAccordingToCountryCode } from "./CommonLogics/CommonMethods";
import _ from "lodash";

// const { TabPane } = Tabs;
const Login = () => {
  const { community } = useSelector(
    (state) => {
      const { isLoginUserLoading, isCreateUserLoading } = state.authentication;
      const { community } = state.communities;

 
      return {
      
        isLoginUserLoading,
        isCreateUserLoading,
        community,
      };
    }
  );
  const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(getCommunityById(process.env.REACT_APP_COMMUNITY_ID));
  // }, [dispatch]);
  const [tabKey, setTabKey] = useState(history?.location?.state?.is_signup ? "2" : "1");
  const [errorMessage, setErrorMessage] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [countryFormat, setCountryFormat] = useState("");

  const [number, setNumber] = useState("");
  // function callback(key) {
  //   setTabKey(key);
  // }
  const {

    handleSubmit,
  } = useForm({
  
    // resolver: yupResolver(loginSchema),
  });
  // const [step, setStep] = useState(1);
  // const nextstep = () => {
  //   setStep(step + 1);
  // };
  // const prevstep = () => {
  //   setStep(step - 1);
  // };
  const loginUser = (data) => {
    const Text = number;
    const TextText = Text.replace(`+${countryCode}`, "");
    const checkNumberLength = TextText.toString().length;

    if (checkNumberLength === 10) {
      data.phone = TextText;
      // .replace(/[^\d\+]/g,"")
      data.country_code = `+${countryCode}`;

      dispatch(loginUserAction(data, successLoginUserMethod,failureLogin));
   
    } else {
      setErrorMessage("The number you entered must be of 10 digits only");
    }
  };
  // const successLoginUserMethod = (loginResponse, PhoneNumber) => {
  //   if (loginResponse.error) {
  //     setErrorMessage(loginResponse.error);
  //   } else if (
  //     loginResponse.message === "You are blocked by admin you cannot login"
  //   ) {
  //     setErrorMessage(loginResponse.message);
  //   } else {
  //     history.push("/OtpVerification", { loginResponse, PhoneNumber });
  //   }
  // };
  const successLoginUserMethod = (
    loginResponse,
    PhoneNumber,
    Number,
    LoginSuccessCountryCode
  ) => {
    if (loginResponse.error) {
      setErrorMessage(loginResponse.error);
    } else if (
      loginResponse.message === "You are blocked by admin you cannot login"
    ) {
      setErrorMessage(loginResponse.message);
    } else {
      history.push("/OtpVerification", {
        loginResponse,
        PhoneNumber,
        Number,
        LoginSuccessCountryCode,
        DisplayPhoneNumber: phoneFormatterAccordingToCountryCode(
          countryFormat,
          _.drop(_.toArray(number))
        ),
      });
    }
  };
  const failureLogin = (failureResponse) => {
    setTimeout(() => {
      message.error(failureResponse ? failureResponse?.error || failureResponse?.message  : "Something went wrong.");
    }, 1000);
};
  // const hideErrorMessageMethod = () => {
  //   setErrorMessage("Hello");
  // };
  const phoneNumberMethod = (value, country, e, formattedValue) => {
    setCountryCode(country.dialCode);
    setNumber(formattedValue);
    setCountryFormat(country.format);
  };

  return (
    <div>
      {/* {step === 1 && ( */}
        <Row justify="center">
          <Col xs={{ span: 24, offset: 1 }} xl={24} md={24} sm={24}>
            <Row>
              <Col span={24}>
                <Typography className="headerTitle">
                  {/* {t("welcomeText.3")} */}
                   Welcome To {community?.name} Samaj Admin
                </Typography>
              </Col>
            </Row>
            <Row gutter={[0, 16]} justify='center'>
              <Col span={24}></Col>
              <Col xl={6} sm={6} lg={6} md={6}></Col>
              <Col xl={12} sm={12} lg={12} md={12}>
                <Typography className="pageContent">
                 <h4>Login</h4> 
                  {/* {community?.description
                    ? community?.description
                    : t("content.2")} */}
                </Typography>
              </Col>
            </Row>
            <Row>
              <Col>
               
              </Col>
            </Row>
            {tabKey === "1" && (
              
              <form onSubmit={handleSubmit(loginUser)}>
                <Row gutter={[0, 15]}>
                  <Col span={24}></Col>
                  <Col xl={24} xs={24} md={24} lg={24} sm={22}>
                    {/* <Controller
                        as={
                          <Input
                            prefix={buttonBefore}
                            style={{ borderRadius: "4px" }}
                            // maxLength={10}
                            // type="number"
                            controls={false}
                            onClick={hideErrorMessageMethod}
                          />
                        }
                        placeholder={t("placeholder.2")}
                        name="phone"
                        control={control}
                      
                        className="loginInput"
                      /> */}
                    <PhoneInput
                      country={"in"}
                      onChange={(value, country, e, formattedValue) =>
                        phoneNumberMethod(value, country, e, formattedValue)
                      }
                      // containerClass="loginInputNe"
                      placeholder="Enter Your Contact Number"
                      onClick={() => setErrorMessage("")}
                      autoFormat={false}
                      onlyCountries={["in", "ca", "us", "au", "nz"]}
                      // isValid={(value,country) => {

                      //   const FullPhoneNumber = value
                      //   const Number = FullPhoneNumber.replace(country.dialCode,"")
                      //   if(Number.length === 10){
                      //     return true
                      //   }
                      //   else{
                      //     return false
                      //   }
                      // }}
                    />
                  </Col>
                </Row>

                {errorMessage === "Phone Number does not exist." && (
                  <Typography style={{ color: "red" }}>
                   Phone Number does not exist
                  </Typography>
                )}
                {errorMessage ===
                  "The number you entered must be of 10 digits only" && (
                  <Typography style={{ color: "red" }}>
                    {errorMessage}
                  </Typography>
                )}
                {errorMessage ===
                  "You are blocked by admin you cannot login" && (
                  <Typography style={{ color: "red" }}>
                    {errorMessage}
                  </Typography>
                )}
                <Row gutter={[0, 15]} justify='center'>
                  <Col span={24}></Col>

                  <Col span={12} 
                  style={{
                    marginTop: "15px"
                     }}>
                    <Button size="large" className="loginBtn" htmlType="submit">
                    Login
                    </Button>
                  </Col>
                </Row>
              </form>
            )}
            
            {/* {tabKey === "2" && (
              <Typograthy/>
              <Signup
                isCreateUserLoading={isCreateUserLoading}
                community={community}
              />
            )} */}
            
          </Col>
        </Row>
       {/* )} */}
   
    </div>
  );
};

export default Login;

