import { combineReducers } from "redux";
import authReducer from "./authReducer";
import { communitiesReducer } from "./communitiesReducer";
import { familyReducer } from "./familyReducer";
import { familyMemberReducer } from "./familyMemberReducer";
import { matrimonialReducer } from "./matrimonialReducers";
import { communitiesNativeReducer } from "./communitiesNativeReducer";
import { businessReducer } from "./businessReducer";
import { eventsReducer } from "./eventsReducer";
import { trusteesReducer } from "./trusteesReducer";
import { usersReducer } from "./usersReducer";
import { searchUserReducer } from "./searchUserReducer";
import { familyAddressReducer } from "./familyAddressReducer";
import { authenticationReducer } from "./authenticationReducer";
import { designationsReducer } from "./designationsReducer";
import { userFamilyReducer } from "./userFamilyReducer";

import { educationsReducer } from "./educationReducer";
import { industryReducer } from "./industriesReducer";
import { nativeVolunteerReducer } from "./nativeVolunteerReducer";
import { publicActivityReducer } from "./publicActivityReducer";
import { communityMeetingReducer } from "./communityMeetingReducer";
import { FamiliesByNativeForVolunteerReducer } from "./FamiliesByNativeForVolunteerReducer";
import { documentsReducer } from "./documentsReducer";
import { messageReducer } from "./messageReducer";
import { ticketsReducer } from "./ticketReducer";
import { windowMaintenanceReducer } from "./maintenancewindowReducer";
import { communityCardsReducer } from "./communityCardsReducers";
import { templatesReducer } from "./templatesReducer";
import { templatesElementsReducer } from "./templatesElementsReducer";
import { countriesReducer } from "./countriesReducer";
import { contactusReducer } from "./contactusReducer";
import { journeyReducer } from "./journeyReducer";
import { journeyRouteReducer } from "./journeyRouteReducer";
import { UserDeviceReducer } from "./UserDeviceReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  communities: communitiesReducer,

  family: familyReducer,
  familyMember: familyMemberReducer,
  matrimonial: matrimonialReducer,
  business: businessReducer,
  events: eventsReducer,
  trustees: trusteesReducer,
  users: usersReducer,
  searchUser: searchUserReducer,
  communitiesNative: communitiesNativeReducer,
  familyAddresses: familyAddressReducer,
  authentication: authenticationReducer,
  designations: designationsReducer,
  userFamily: userFamilyReducer,
  educations: educationsReducer,
  industry: industryReducer,
  nativeVolunteers: nativeVolunteerReducer,
  publicActivity: publicActivityReducer,
  communityMeetings: communityMeetingReducer,
  FamiliesByNativeForVolunteer: FamiliesByNativeForVolunteerReducer,
  documents: documentsReducer,
  message: messageReducer,
  tickets: ticketsReducer,
  maintenance: windowMaintenanceReducer,
  communityCards: communityCardsReducer,
  templates: templatesReducer,
  templatesElements: templatesElementsReducer,
  countries: countriesReducer,
  contactus: contactusReducer,
  journey: journeyReducer,
  journeyRoute:journeyRouteReducer,
  userDevice:UserDeviceReducer,
});

export default rootReducer;
