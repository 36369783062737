import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Spin,
  Empty,
  Typography,
  Card,
  Pagination,
  Tooltip,
} from "antd";
import { getUsers } from "../../Actions/usersAction";
import DesignationEmptyScreen from "../../assets/DesignationEmptyScreen.svg";
import { useDispatch, useSelector } from "react-redux";
import Edit from "../../assets/Edit.svg";
import Delete from "../../assets/delete.svg";
import { getMatrimonial } from "../../Actions/matrimonialAction";
const CommunityMatrimonial = (props) => {
  const {
    id,
    setIsModalVisible,
    setEditId,
    setIsDefaultImageVisible,
    setShowUploadImage,
  } = props;
  const dispatch = useDispatch();
const {
    setCommunityId,
    allMatrimonial,
    isSearchUserLoading,
  } = useSelector((state) => {
    const { currentUser } = state.authentication;
    const { community, allCommunities, setCommunityId } = state.communities;
    const { searchUsers,isSearchUserLoading } = state.searchUser;
    const { allMatrimonial } = state.matrimonial;
    return {
      isSearchUserLoading,
      currentUser,
      community,
      setCommunityId,
      searchUsers,
      allMatrimonial,
    };
  });

  useEffect(() => {
    dispatch(getUsers());
        dispatch(getMatrimonial(`api/matrimonials?community_id=${setCommunityId}`));
  }, [dispatch, setCommunityId]);
  const [currentPage, setCurrentPage] = useState(1);
  const {matrimonials} = allMatrimonial;
  const { total_length } = allMatrimonial || {};
  const onChangeCurrentPage = (page) => {
    setCurrentPage(page);
  };
  const handleEdit = (id) => {
    setEditId(id);
    setIsModalVisible(true);
    setIsDefaultImageVisible(true);
    setShowUploadImage(false);
  };
  return (
    <Spin spinning={isSearchUserLoading} >
      {allMatrimonial &&  total_length > 0 ? (
        <Row gutter={[32, 32]}>
             {matrimonials?.map((val, i) => {
            return (
                <Col xl={8} lg={8} md={12} sm={24} xs={24} key={i} >
                <Card className="card-community">
                  <Row>
                    <Col xl={12} lg={10} md={10} sm={24} xs={24} >
                      <Typography className="community-Title">
                         {val.company_name}
                      </Typography>
                    </Col>
                    <Col
                      xl={12}
                      lg={8}
                      md={8}
                      sm={16}
                      xs={16}
                      style={{ textAlign: "end" }}
                    >
                       <Tooltip title="Edit">
                        <Button
                          icon={<img src={Edit}/>}
                          type="text"
                          onClick={() => handleEdit(val.id, val)}
                        />
                      </Tooltip>
                      <Tooltip title="Delete">
                          <Button icon={<img src={Delete} />} type="text" />
                      </Tooltip> 
                    </Col>
                  </Row>
                </Card>
              </Col>
            );
        })}
        </Row>
      ) : (
        <Row justify="center" style={{ paddingTop: "150px" }}>
          <Col>
            <Empty
              image={DesignationEmptyScreen}
              imageStyle={{
                height: 38,
              }}
              description={
                <span className="empty-details">
                  {/* {t("emptyScreen.3")} */}
                  No Communities yet
                </span>
              }
            ></Empty>
          </Col>
        </Row>
      )}
      {allMatrimonial && total_length > 0 && (
          <Row justify="end" gutter={[0, 24]}>
            <Col>
              <Pagination
                responsive={true}
                size="large"
                current={currentPage}
                onChange={(e) => onChangeCurrentPage(e)}
                total={total_length}
                showSizeChanger={false}
              />
            </Col>
            <Col span={24}></Col>
          </Row>
        )}
    </Spin>
  );
};

export default CommunityMatrimonial;

