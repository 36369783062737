import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Card,
  Typography,
  Avatar,
  Divider,
  Breadcrumb,
  Popconfirm,
  message,
  Spin,
  Tooltip,
} from "antd";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { history } from "../../Utils/history";
import { deleteEvents, getEventById } from "../../Actions/eventsAction";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import EventsDrawer from "./EventsDrawer";
import "../../Events/EventCard.css";
import EventsEmptyScreen from "../../assets/EventsEmptyScreen.svg";
import { ImageEnv } from "../CommonLogics/CommonMethods";

const EventDetail = () => {
  const dispatch = useDispatch();
  const { event, isDeleteEventsLoading, isEventByIdLoading } = useSelector(
    (state) => {
      const { event, isDeleteEventsLoading, isEventByIdLoading } = state.events;
      return {
        event,
        isDeleteEventsLoading,
        isEventByIdLoading,
      };
    }
  );
  useEffect(() => {
    dispatch(getEventById(history?.location?.state?.id));
  }, []);
  const [isEventsDrawerVisible, setIsEventsDrawerVisible] = useState(false);

  const EventDateTime = event?.event_datetime?.split("_");
  const handleDeleteEventMethod = () => {
    dispatch(
      deleteEvents(history?.location?.state?.id, SuccessDeleteEventMethod)
    );
  };
  const SuccessDeleteEventMethod = () => {
    history.push("/communityevents");
    setTimeout(() => {
      message.success("Event deleted successfully");
    }, 1000);
  };
  const handleEventBreadCrumbMethod = () => {
    history.push("/communityevents");
  };
  return (
    <div style={{ 
      marginTop: "40px"
       }}>
      <Spin
        spinning={isEventByIdLoading || isDeleteEventsLoading}
        size="large"
        style={{ height: "100vh" }}
      >
        {!isEventByIdLoading && (
          <>
            <Row gutter={[0, 35]}>
              <Col span={1} />
              <Col span={17}>
                <Breadcrumb 
                // style={{ margin: 10 }}
                >
                  <Breadcrumb.Item
                    onClick={() => handleEventBreadCrumbMethod()}
                    className="breadcrumCommunity"
                  >
                    Events
                  </Breadcrumb.Item>

                  <Breadcrumb.Item className="breadcrumUsers">
                    {event?.event_name}
                    {/* {industries[0].name} */}
                  </Breadcrumb.Item>
                </Breadcrumb>
              </Col>
              <Col span={24} />
            </Row>
            <Row>
              <Col span={1} />
              <Col span={17}>
                <Card
                  className="zeroPaddingCard"
                  style={{
                    boxShadow: "0px 5px 20px rgba(0, 0, 0, 0.12)",
                    borderRadius: "4px",
                  }}
                >
                  {/* <EditOutlined onClick={() => setIsEventsDrawerVisible(true)} /> */}
                  {/* <Typography>{event?.event_name}</Typography>
        <EventsDrawer
          isEventsDrawerVisible={isEventsDrawerVisible}
          setIsEventsDrawerVisible={setIsEventsDrawerVisible}
          isUpdateEvent={true}
        /> */}
                  <Row>
                    <Col span={24}>
                      <Avatar
                        shape="square"
                        style={{
                          width: "100%",
                          height: "200px",
                          backgroundColor: "#F3F3F6",
                        }}
                        src={event?.event_image_key && ImageEnv(event?.event_image_key)}
                      >
                        <img
                          src={EventsEmptyScreen}
                          style={{
                             marginTop: "54px" 
                            }}
                          alt=""
                        ></img>
                      </Avatar>
                    </Col>
                    <Col span={23}>
                      <Row justify="end" gutter={[30, 10]}>
                        <Col span={24} />
                        <Col>
                          <Tooltip title="Edit">
                            <EditOutlined
                              onClick={() => setIsEventsDrawerVisible(true)}
                            />
                          </Tooltip>
                          <EventsDrawer
                            isEventsDrawerVisible={isEventsDrawerVisible}
                            setIsEventsDrawerVisible={setIsEventsDrawerVisible}
                            isUpdateEvent={true}
                          />
                        </Col>
                        <Col>
                          <Tooltip title="Delete">
                            <Popconfirm
                              title="Are you sure?"
                              onConfirm={() => handleDeleteEventMethod()}
                              okText="Delete"
                              cancelText="Cancel"
                              placement="topLeft"
                            >
                              <DeleteOutlined />
                            </Popconfirm>
                          </Tooltip>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <div 
                  // style={{ padding: "20px" }}
                  >
                    <Row gutter={[0, 14]}>
                      <Col span={24}>
                        <Typography className="Eventtitle">
                          {event?.event_name}-{event?.city_name}
                        </Typography>
                      </Col>
                      <Col span={24}>
                        <Row>
                          <Col>
                            <Typography className="Eventlocation">
                            Location : &nbsp;
                              <Typography
                                className="Eventlocation1"
                                component="span"
                              >
                                {event?.event_location}
                              </Typography>
                            </Typography>
                          </Col>
                          <Col>
                            <Typography className="Eventlocation1">
                              ({EventDateTime?.[1]})
                            </Typography>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <Card className="eventdetailsbackground">
                          <Typography className="Eventlocation">
                          Event Details :&nbsp;
                            <Typography
                              className="Eventlocation1"
                              component="span"
                            >
                              {event?.event_detail}
                            </Typography>
                          </Typography>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                </Card>
              </Col>
            </Row>
          </>
        )}
      </Spin>
    </div>
  );
};
export default EventDetail;
