import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Spin,
  Breadcrumb,
  Button,
  message,
  Pagination,
  Tooltip,
  Popconfirm,
  Switch,
} from "antd";
import _ from "lodash";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { history } from "../../../Utils/history";
import { getCountriesAction } from "../../../Actions/ConutriesAction";
import { useForm } from "react-hook-form";
import CountriesModalForm from "./CountriesModalForm";
import { getCommunityById } from "../../../Actions/communitiesAction";
import CustomTable from "../../ReuseableComponents/CustomTable/CustomTable";
import {
  updateCountriesAction,
  deleteUserCountriesAction,
} from "../../../Actions/ConutriesAction";
import PublishedIcon from "../../../assets/PublishedIcon.svg";
import UnpublishedIcon from "../../../assets/UnpublishedIcon.svg";
import Delete from "../../../assets/delete.svg";
import Edit2 from "../../../assets/Edit2.svg";

const Countries = () => {
  const { isCountriesLoading, allCountries } = useSelector((state) => {
    const { currentUser } = state.authentication;
    const { allCountries, isCountriesLoading } = state.countries;
    return {
      isCountriesLoading,
      currentUser,
      allCountries,
    };
  });
  const { total_length } = allCountries || {};
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editId, setEditId] = useState("");
  const [countriesId, setCountriesId] = useState("");
  const [valName, setValName] = useState("");
  const [selectedCountries, setSelectedCountries] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const { reset } = useForm({});
  let UnpublishData = {};
  let PublishData = {};
  useEffect(() => {
    dispatch(getCountriesAction(`api/countries?page=${currentPage}`));
  }, [currentPage]);
  const onClickCommunity = () => {
    history.push("/communities");
    dispatch(getCommunityById(""));
    dispatch({
      type: "GET_SELECTED_COMMUNITY_ID",
      payload: "",
    });
  };
  const onClickCountries = () => {
    setSelectedCountries("");
    setValName("");
    reset();
  };
  const handleEdit = (id) => {
    setEditId(id);
    setIsModalVisible(true);
  };
  const handleDeleteCountries = (Id) => {
    dispatch(deleteUserCountriesAction(Id, successDelete, failureDelete));
  };
  const successDelete = () => {
    setTimeout(() => {
      message.success("Your Countries Deleted Successfully");
    }, 1000);
    dispatch(getCountriesAction(`api/countries?page=${currentPage}`));
  };
  const failureDelete = () => {
    setTimeout(() => {
      message.error("Something went wrong.");
    }, 1000);
  };
  const handlePublishCountriesMethod = (EditId) => {
    PublishData.id = EditId;
    PublishData.published = false;
    dispatch(
      updateCountriesAction(
        PublishData,
        successUpdatePublishMethod,
        faliureDelete
      )
    );
  };
  const handleUnpublishCountriesMethod = (EditId) => {
    UnpublishData.id = EditId;
    UnpublishData.published = true;
    dispatch(
      updateCountriesAction(
        UnpublishData,
        successUpdateUnpublishMethod,
        faliureDelete
      )
    );
  };

  const successUpdateUnpublishMethod = () => {
    setTimeout(() => {
      message.success("Your Country Published Successfully");
    }, 1000);
    dispatch(getCountriesAction(`api/countries?page=${currentPage}`));
  };

  const successUpdatePublishMethod = () => {
    setTimeout(() => {
      message.success("Your Country Unpublished Successfully");
    }, 1000);
    dispatch(getCountriesAction(`api/countries?page=${currentPage}`));
  };
  const faliureDelete = () => {
    setTimeout(() => {
      message.error("Something went wrong.");
    }, 1000);
  };
  const handlesmsCountriesMethod = (EditId) => {
    PublishData.id = EditId;
    PublishData.sms_on = false;
    dispatch(
      updateCountriesAction(PublishData, successUpdatesmsMethod, faliureDelete)
    );
  };
  const handleUnsmsCountriesMethod = (EditId) => {
    UnpublishData.id = EditId;
    UnpublishData.sms_on = true;
    dispatch(
      updateCountriesAction(
        UnpublishData,
        successUpdateUnsmsMethod,
        faliureDelete
      )
    );
  };

  const successUpdateUnsmsMethod = () => {
    setTimeout(() => {
      message.success("Your Country SMS service is on.");
    }, 1000);
    dispatch(getCountriesAction(`api/countries?page=${currentPage}`));
  };

  const successUpdatesmsMethod = () => {
    setTimeout(() => {
      message.success("Your Country SMS service is off.");
    }, 1000);
    dispatch(getCountriesAction(`api/countries?page=${currentPage}`));
  };

  const the_name = _.filter(allCountries.countries, function (o) {
    return o.id === valName;
  });
  const Columns = [
    {
      title: "Country Name",
      dataIndex: "country_name",
      width: "18%",
      sorter: (a, b) => a.country_name.localeCompare(b.country_name),
      sortDirections: ["descend", "ascend"],
    },

    {
      title: "Country Short Name",
      dataIndex: "country_sort_name",
      width: "15%",
      sorter: (a, b) => a.country_sort_name.localeCompare(b.country_sort_name),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Country Code",
      dataIndex: "id",
      width: "12%",
      render: (id, { country_code }) => {
        return <Row>+{country_code}</Row>;
      },
      sorter: (a, b) => a.country_code -b.country_code,
      // sortDirections: ["descend", "ascend"],
    },
    {
      title: "SMS Service",
      dataIndex: "id",
      width: "12%",
      render: (id, { sms_on }) => {
        return (
          <Row>
            {sms_on === true && (
              <Tooltip title="Click for SMS service off">
                <Switch
                  onClick={() => handlesmsCountriesMethod(id)}
                  checkedChildren={<CheckOutlined />}
                  defaultChecked
                ></Switch>
              </Tooltip>
            )}
            {sms_on === false && (
              <Tooltip title="Click for SMS service on">
                <Switch
                  onClick={() => handleUnsmsCountriesMethod(id)}
                  unCheckedChildren={<CloseOutlined />}
                ></Switch>
              </Tooltip>
            )}
          </Row>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "id",
      width: "18%",
      render: (id, { published }) => {
        return (
          <Row justify="space-around">
            <Col>
              <Tooltip title="Edit">
                <Button
                  icon={<img src={Edit2} />}
                  type="text"
                  onClick={() => handleEdit(id)}
                />
              </Tooltip>
            </Col>
            <Col>
              {published ? (
                <Tooltip title="Click for Unpublish">
                  <Button
                    onClick={() => handlePublishCountriesMethod(id)}
                    icon={<img src={PublishedIcon} />}
                    type="text"
                  ></Button>
                </Tooltip>
              ) : (
                <Tooltip title="Click for Publish">
                  <Button
                    onClick={() => handleUnpublishCountriesMethod(id)}
                    icon={<img src={UnpublishedIcon} />}
                    type="text"
                  ></Button>
                </Tooltip>
              )}
            </Col>
            <Col>
              <Tooltip title="Delete">
                <Popconfirm
                  title="Are you sure?"
                  onConfirm={() => handleDeleteCountries(id)}
                  okText="Ok"
                  cancelText="Cancel"
                >
                  <Button icon={<img src={Delete} />} type="text" />
                </Popconfirm>
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },
  ];
  return (
    <Spin spinning={isCountriesLoading}>
      <Row gutter={[32, 32]} 
      className="community"

      // style={{ margin: 10 }}
      >
        <Col span={24}></Col>
        <Col xl={16} lg={16} md={16} sm={20} xs={20}>
          <Breadcrumb 
          // style={{ margin: 10 }}
          >
            <Breadcrumb.Item
              onClick={() => onClickCommunity()}
              className="breadcrumCommunity"
            >
              Communities
            </Breadcrumb.Item>
            <Breadcrumb.Item
              onClick={() => onClickCountries()}
              className="breadcrumUsers"
            >
              Countries
            </Breadcrumb.Item>
            <Breadcrumb.Item className="breadcrumUsers">
              {the_name[0]?.name}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col xl={8} lg={8} md={8} sm={24} xs={24}>
          <Row justify="end" gutter={[8, 8]}>
            <Col xl={0} lg={0} md={0} sm={6} xs={6}></Col>
            <Col xl={12} lg={12} md={18} sm={24} xs={24}>
              <Button
                className="editDesignationButton"
                style={{ width: "100%" }}
                onClick={() => setIsModalVisible(true)}
              >
                Add Country
              </Button>
            </Col>
          </Row>
          <CountriesModalForm
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            editId={editId}
            setEditId={setEditId}
            countriesId={countriesId}
            setCountriesId={setCountriesId}
            setValName={setValName}
            valName={valName}
            setSelectedCountries={setSelectedCountries}
            selectedCountries={selectedCountries}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          ></CountriesModalForm>
        </Col>

        <Col span={24}>
          <Row gutter={[32, 32]}>
            <Col span={24}>
              <CustomTable columns={Columns} data={allCountries.countries} />
            </Col>
          </Row>
        </Col>
        {allCountries && total_length > 0 ? (
          <Col span={24}>
            <Row justify="center" 
            // style={{ margin: "20px 0px" }}
            >
              <Pagination
                total={allCountries?.total_length}
                onChange={(e) => setCurrentPage(e)}
                responsive={true}
                size="large"
                current={currentPage}
                showSizeChanger={false}
              />
            </Row>
          </Col>
        ) : (
          ""
        )}
      </Row>
    </Spin>
  );
};

export default Countries;
