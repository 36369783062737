import React, { useState } from 'react';
import {Table, Button, Row, Col, Spin, message, Select, Popconfirm, Typography } from 'antd';
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import "../../FindThePeoplePage/FindOtherPeople.css";
import {updatePublishCommunityMeeting} from "../../Actions/communityMeetingsAction";
import "./Admin.css"
import {getCommunityMeetings} from "../../Actions/communityMeetingsAction";
import { deleteCommunityMeeting } from "../../Actions/communityMeetingsAction";
import ViewMeetings from './ViewMeetings';


function MeetingTable (props) {

    const dispatch = useDispatch();
    const { setEditMeetingId, setIseditMeetingModalVisible, allMeetings, isCommunityMeetingsLoading, isPublishMeeting, setIsPublishMeeting } = props;
    const [ isMeetingDetailsVisible, setIsMeetingDetailsVisible ] = useState(false);
    const [ meetingDetailId, setMeetingDetailId ] = useState("");
      const columns = [
        {
          title: "Number",
          dataIndex: 'community_meeting_no',
          key: 'community_meeting_no',
          sorter: (a, b) => a.community_meeting_no - b.community_meeting_no,
          defaultSortOrder: 'descend',
          render : (record, object) => {
            return (
                object.community_meeting_no
            )
          }
        },
        {
          title: "Title",
          dataIndex: 'title',
          key: 'title',
        },
        {
          title:"Place",
          dataIndex: 'place',
          key: 'place',
        },
        {
            title: "Description",
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: "Financial Year",
            dataIndex: 'year',
            key: 'year',
            render: (year) => {
                let actualYear = year?.split(" ")
                actualYear = actualYear + "-" + (Number(actualYear)+1)
                return (
                    // actualYear?.[3]
                    actualYear
                )
            }
        },
        {
            dataIndex: "id",
            render: (record, object) => <PublishButton 
            isPublishMeeting = {isPublishMeeting}
            setIsPublishMeeting = {setIsPublishMeeting}
            record = {record}
            object = {object}
            />
        },
        {
            dataIndex:"id",
            render: (id, record, object) => <MeetingActionComponent
            id = {id}
            setEditMeetingId = {setEditMeetingId}
            setIseditMeetingModalVisible = {setIseditMeetingModalVisible}
            record = {record}
            object = {object}
            isMeetingDetailsVisible = {isMeetingDetailsVisible}
            setIsMeetingDetailsVisible = {setIsMeetingDetailsVisible}
            setMeetingDetailId = {setMeetingDetailId}
            />
            , width:"15em"
        }
      ];

    // const years = allMeetings.map((d) => d.year.split(" ")[3])

    // const [filteredData, setFilteredData] = useState(allMeetings);

    const handleYearChange = (e) => {
        // setFilteredData(allMeetings.filter((d) => d.year.split(" ")[3] === e));
        setYear(e);
        dispatch(getCommunityMeetings(process.env.REACT_APP_COMMUNITY_ID, e));
    };

    const d = new Date();
    let y = d.getFullYear();
    const earliestYear = 2000;
    const [year, setYear] = useState(y);
    const options = [];

    for(let i = earliestYear; i <= y; i++)
    {
        options.push(<option value = {i}>{i + "-" + (Number(i)+1)}</option>)
    }

    const hideMeetingDetails = () => {
        setIsMeetingDetailsVisible(false);
        setMeetingDetailId("");
    }


    const selectedMeeting = allMeetings?.filter((meetings) => {
        return meetings.id === meetingDetailId
    });

    return (
        <div>
            { isMeetingDetailsVisible ?
            <Row gutter={[0, 33]}>
                <Col xl={1} style={{ marginBottom: "100px", marginLeft: "22px" }}>
                <ArrowLeftOutlined
                    style={{ fontSize: "20px" }}
                    onClick={() => hideMeetingDetails()}
                />
                <ViewMeetings selectedMeeting = {selectedMeeting[0]}/>
                </Col>
                <Col>
                    <Typography className="newMemberSubDetails">Back to results</Typography>
                </Col>
                <Col span={24} />
            </Row>
            :
            <>
                <Row gullter = {[30, 10]}>
                    <Col span={2}>
                        <Typography className = 'content'>Select Year:</Typography>
                    </Col>
                    <Col span={2}>
                        <Select 
                            onChange={(e) => handleYearChange(e)}
                            placeholder = "Select financial year"
                            name="yearSelector"
                            defaultValue="2022-2023"
                            style={{width: "110px",}}
                        >
                            {options}
                        </Select>
                    </Col>
                </Row>
                <Spin spinning={isCommunityMeetingsLoading} size="large">
                    <Table dataSource={allMeetings} columns={columns} className="table" size="middle" style={{
                        marginTop: "10px"
                        }}/>
                </Spin>
            </>
            }
        </div>
    )
}

export default MeetingTable;

const MeetingActionComponent = (props) => {

    const dispatch = useDispatch();
    const { setIseditMeetingModalVisible, setEditMeetingId, id, record, object, setMeetingDetailId, setIsMeetingDetailsVisible } = props;
    
    const handleEditMeeting = (id) =>{
        setEditMeetingId(id)
        setIseditMeetingModalVisible(true)
    }
    
    const handleDelete = (id) => {
        dispatch(deleteCommunityMeeting(id, successDeleteMethod));
    }

    const successDeleteMethod = () => {
        const d = new Date();
        let year = d.getFullYear();
        setTimeout(() => {
            message.success("The community meeting deleted successfully");
          }, 1000)
        dispatch(getCommunityMeetings(process.env.REACT_APP_COMMUNITY_ID, year));
    }

    const handleOpenMeetingDetails = (id) => {
        setIsMeetingDetailsVisible(true);
        setMeetingDetailId(id);
    }

    return (
        <Row gutter={[10,0]}>
            { record.published ? 
            
                <>
                <Col span={10}>
                    <Button className="editButton" type="primary" onClick={() => handleOpenMeetingDetails(id)}>View meeting</Button>
                </Col>
                </> 
                
                : 

                <>
                <Col span={10}>
                    <Button className="editButton" type="primary" onClick={() => handleEditMeeting(id)}>Edit</Button>
                </Col>
                <Col span={10}>
                    <Popconfirm title="Confirm delete?" okText="Confirm" cancelText="Cancel" onConfirm={() => handleDelete(id)}>
                        <Button className="deleteButton" type="primary">Delete</Button>
                    </Popconfirm>
                </Col>
                </>
            }
        </Row>
    )
}

const PublishButton = (props) => {

    const { isPublishMeeting, setIsPublishMeeting, record, object } = props;
    const dispatch = useDispatch();

    const handlePublish = () =>{
        dispatch(updatePublishCommunityMeeting(record, successPublishMethod))
    }

    const successPublishMethod = (updatePublishDataReponse) => {
        // const date = new Date();
        // let year = date.getFullYear();
        setTimeout(() => {
            message.success("Your community meeting published successfully");
        }, 1000)
        dispatch(getCommunityMeetings(process.env.REACT_APP_COMMUNITY_ID, updatePublishDataReponse.year));
    }

    return (
        <Row gutter={[0,0]}>
            <Col>
                <Button type="primary" className="publishButton" onClick={ () => handlePublish()} disabled = {object.published ? true : false}>{record && object.published === true ? "Published" : "Publish"}</Button>
            </Col>
        </Row>
    )
}
