import React from "react";
import { Row, Table, Col, Button, message } from "antd";
import "../../FindThePeoplePage/FindOtherPeople.css";
import {
  getIndustriesAction,
  updateIndustryAction,
} from "../../Actions/industryAction";
import "./Admin.css";

import { useDispatch } from "react-redux";

const IndustryTable = (props) => {
  

  const { allIndustries, setEditIndustryId, setIsEditIndustryModalVisible } =
    props;

  const columns = [
    {
      title: `${"Industry"} (${allIndustries?.length})`,

      dataIndex: "name",
      width: "80%",

      render: (name, allIndustriesList) => {
        return allIndustriesList.published ? (
          name
        ) : (
          <div className="hidedRow">{name}</div>
        );
      },
    },
    {
      dataIndex: "id",
      render: (id, allIndustriesList) => (
        <IndustryActionComponent
          id={id}
          setEditIndustryId={setEditIndustryId}
          setIsEditIndustryModalVisible={setIsEditIndustryModalVisible}
          allIndustriesList={allIndustriesList}
        />
      ),
      width: "15em",
    },
  ];
  return (
    <div className="AdminTable">
      <Table
        columns={columns}
        dataSource={allIndustries}
        size="middle"
        className="table"
      />
    </div>
  );
};

export default IndustryTable;

const IndustryActionComponent = (props) => {
  const {
    id,
    setEditIndustryId,
    setIsEditIndustryModalVisible,
    allIndustriesList,
  } = props;

  const dispatch = useDispatch();
  let UnpublishData = {};
  let PublishData = {};
  const openEditIndustryMethod = (EditId) => {
    setEditIndustryId(EditId);

    setIsEditIndustryModalVisible(true);
  };
  const handlePublishIndustryMethod = (EditId) => {
    PublishData.id = EditId;
    PublishData.published = true;
    dispatch(updateIndustryAction(PublishData, successUpdatePublishMethod));
  };
  const handleUnpublishIndustryMethod = (EditId) => {
    UnpublishData.id = EditId;

    UnpublishData.published = false;
    dispatch(updateIndustryAction(UnpublishData, successUpdateUnpublishMethod));
  };

  const successUpdateUnpublishMethod = () => {
    setTimeout(() => {
      message.success("Your Industry Unpublished Successfully");
    }, 1000);
    dispatch(getIndustriesAction());
  };

  const successUpdatePublishMethod = () => {
    setTimeout(() => {
      message.success("Your Industry Published Successfully");
    }, 1000);
    dispatch(getIndustriesAction());
  };
  return (
    <Row>
      <Col span={10}>
        <Button
          type="primary"
          className="editDesignationButton"
          onClick={() => openEditIndustryMethod(id)}
        >
          Edit
        </Button>
      </Col>

      <Col>
        {allIndustriesList?.published ? (
          <Button
            type="primary"
            className="unPublishButton"
            onClick={() => handleUnpublishIndustryMethod(id)}
          >
            Unpublish
          </Button>
        ) : (
          <Button
            type="primary"
            className="publishButton"
            onClick={() => handlePublishIndustryMethod(id)}
          >
           Publish
          </Button>
        )}
      </Col>
    </Row>
  );
};
