import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getTicketsAction,
  getTicketByIdAction,
  updateTicketAction,
  deleteTicketAction,
  getArchiveTicketsAction,
  getArchiveTicketByIdAction,
} from "../../../Actions/ticketAction";
import {
  Row,
  Col,
  Button,
  Tabs,
  Typography,
  Pagination,
  message,
  Breadcrumb,
  Tooltip,
  Spin,
  Popconfirm,
} from "antd";
import { getSearchUser } from "../../../Actions/searchUserAction";
import CustomTable from "../../ReuseableComponents/CustomTable/CustomTable";
import "../../../FindThePeoplePage/FindOtherPeople.css";
import { history } from "../../../Utils/history";
import { getCommunityById } from "../../../Actions/communitiesAction";
import ChatDrawer from "./ChatDrawer";
import _ from "lodash";
import ArchieveData from "./ArchieveData";
import CreateTicketDrawer from "./CreateTicketDrawer";
import TicketHistory from "./TicketHistory";
import {
  MessageFilled,
  HistoryOutlined,
  CloudUploadOutlined,
} from "@ant-design/icons";
const Ticket = () => {
  const height = window.innerHeight - 64;
  const [isChatDrawerVisible, setIsChatDrawerVisible] = useState(false);
  const [createTicketSuccessData, setCreateTicketSuccessData] = useState("");
  const [ticketByIdState, setTicketByIdState] = useState({});
  const [trusteeId, setTrusteeId] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editId, setEditId] = useState("");
  const [tabKey, setTabkey] = useState("1");
  const {
    allTickets,
    isGetTicketsLoading,
    isCreateTicketsLoading,
    setCommunityId,
    allArchiveTickets,
  } = useSelector((state) => {
    const {
      allTickets,
      isGetTicketsLoading,
      isCreateTicketsLoading,
      allArchiveTickets,
    } = state.tickets;
    const { setCommunityId } = state.communities;
    return {
      setCommunityId,
      allTickets,
      isGetTicketsLoading,
      isCreateTicketsLoading,
      allArchiveTickets,
    };
  });
  let UnpublishData = {};
  let PublishData = {};
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const { TabPane } = Tabs;
  useEffect(() => {
    dispatch(
      getTicketsAction(
        `api/tickets?community_id=${setCommunityId}&page=${page}`
      )
    );
    dispatch(
      getSearchUser(
        `api/user_search?community_id=${setCommunityId}&page=${page}`
      )
    );
    dispatch(
      getArchiveTicketsAction(
        `api/archive_tickets?community_id=${setCommunityId}&page=${page}`
      )
    );
  }, [dispatch, setCommunityId, page]);

  const handleCloseTicket = (EditId) => {
    PublishData.id = EditId;
    PublishData.ticket_status = "Closed";
    dispatch(
      updateTicketAction(PublishData, successUpdateCloseMethod, faliureDelete)
    );
  };
  const handleOpenTicket = (EditId) => {
    UnpublishData.id = EditId;
    UnpublishData.ticket_status = "Open";
    dispatch(
      updateTicketAction(UnpublishData, successUpdateOoenMethod, faliureDelete)
    );
  };

  const successUpdateOoenMethod = () => {
    setTimeout(() => {
      message.success("Your Ticket Close Successfully");
    }, 1000);
    dispatch(
      getTicketsAction(
        `api/tickets?community_id=${setCommunityId}&page=${page}`
      )
    );
  };

  const successUpdateCloseMethod = () => {
    setTimeout(() => {
      message.success("Your Ticket Reopen Successfully");
    }, 1000);
    dispatch(
      getTicketsAction(
        `api/tickets?community_id=${setCommunityId}&page=${page}`
      )
    );
  };
  const faliureDelete = () => {
    setTimeout(() => {
      message.error("Something went wrong.");
    }, 1000);
  };
  const onClickCommunity = () => {
    history.push("/communities");
    dispatch(getCommunityById(""));
    dispatch({
      type: "GET_SELECTED_COMMUNITY_ID",
      payload: "",
    });
  };
  const onTabClick = (key) => {
    setTabkey(key);
  };
  const handleDeleteEducations = (Id) => {
    dispatch(deleteTicketAction(Id, successDelete, failureDelete));
  };
  const successDelete = () => {
    setTimeout(() => {
      message.success("Your Tickets Deleted Successfully");
    }, 1000);
    dispatch(
      getTicketsAction(
        `api/tickets?community_id=${setCommunityId}&page=${page}`
      )
    );
  };
  const failureDelete = () => {
    setTimeout(() => {
      message.error("Something went wrong.");
    }, 1000);
  };
  const handleOpenTicketHistory = (Id) => {
    history.push(`/ticketHistory/${Id}`, { Id: Id });
    setTrusteeId(Id);
    dispatch(getTicketByIdAction(Id));
    dispatch({
      type: "GET_SELECTED_TICKET_ID",
      payload: Id,
    });
  };
  const handleOpenTicketHistory2 = (Id) => {
    setTrusteeId(Id);
    history.push(`/archieveticketHistory/${Id}`, { Id: Id });
    dispatch(getArchiveTicketByIdAction(Id));
    dispatch({
      type: "GET_SELECTED_ARCHIVETICKET_ID",
      payload: Id,
    });
  };
  const openEditDrawer = (Id) => {
    setEditId(Id);
    history.push(`/users/${Id}`, { id: Id });
  };
  const columns = [
    {
      title: "Ticket ID",
      dataIndex: "ticket_number",
      width: "10%",
      sorter: (a, b) => a.ticket_number.localeCompare(b.ticket_number),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "User",
      dataIndex: "id",
      width: "15%",
      render: (id, { ticket_creator }) => {
        return (
          <Row>
            <Col xl={16} lg={16} md={16} sm={24} xs={24}>
              <Tooltip title="View Profile">
                <Typography onClick={() => openEditDrawer(ticket_creator.id)}>
                  {ticket_creator?.first_name}
                </Typography>
              </Tooltip>
            </Col>
          </Row>
        );
      },
      sorter: (a, b) => a.ticket_creator?.first_name.localeCompare(b.ticket_creator?.first_name),
      sortDirections: ["descend", "ascend"],
    },

    {
      title: "Issue",
      dataIndex: "name",
      width: "20%",
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Status",
      dataIndex: "id",
      width: "10%",
      sorter: (a, b) => a.id.localeCompare(b.id),
      sortDirections: ["descend", "ascend"],
      render: (id, { ticket_status }) => {
        return (
          <Row>
            <Col>
              {ticket_status === "Closed" ? (
                <Tooltip title="Click for closing the ticket">
                  <Popconfirm
                    title="Are you sure you want to Close the ticket?"
                    onConfirm={() => handleOpenTicket(id)}
                    okText="Close"
                    cancelText="Cancel"
                  >
                    <Button className="OpenStatus" type="text">
                      Reopen
                    </Button>
                  </Popconfirm>
                </Tooltip>
              ) : (
                <Tooltip title="Click for Re-opening the ticket">
                  <Popconfirm
                    title="Are you sure you want to Reopen the ticket?"
                    onConfirm={() => handleCloseTicket(id)}
                    okText="Reopen"
                    cancelText="Cancel"
                  >
                    <Button className="ClosedStatus" type="text">
                      Close
                    </Button>
                  </Popconfirm>
                </Tooltip>
              )}
            </Col>
          </Row>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "id",
      render: (id) => (
        <Row justify="space-around">
          <Col>
            <ActionComponent
              id={id}
              setIsChatDrawerVisible={setIsChatDrawerVisible}
              getTicketByIdAction={getTicketByIdAction}
              setCreateTicketSuccessData={setCreateTicketSuccessData}
            />
          </Col>

          <Col>
            <Tooltip title="View History">
              <HistoryOutlined
                onClick={() => handleOpenTicketHistory(id)}
                style={{ fontSize: 18 }}
              />
            </Tooltip>
          </Col>

          <Col>
            <Tooltip title="Archieve">
              <Popconfirm
                title="Are you sure?"
                onConfirm={() => handleDeleteEducations(id)}
                okText="Ok"
                cancelText="Cancel"
              >
                <CloudUploadOutlined style={{ fontSize: 18 }} />
              </Popconfirm>
            </Tooltip>
          </Col>
        </Row>
      ),
      width: "12%",
    },
  ];
  const backgroundColor = "#" + Math.floor(Math.random() * 1677).toString(14);
  const openIndustry = () => {
    setIsModalVisible(true);
    dispatch(getSearchUser(`api/user_search?community_id=${setCommunityId}`));
    dispatch(
      getTicketsAction(
        `api/tickets?community_id=${setCommunityId}&page=${page}`
      )
    );
  };
  const items = [
    {
      key: '1',
      label:(
        <Button
          className={tabKey === "1" ? "selectedButton" : "unselectedButton"}
        >
           Tickets
        </Button>
      ),
    },
    {
      key: '2',
      label:(
        <Button
        className={tabKey === "2" ? "selectedButton" : "unselectedButton"}
        >
           Archive tickets
        </Button>
      ),
    },
  ];
  return (
    <Spin spinning={isGetTicketsLoading}>
      {trusteeId ? (
        <Row>
          <TicketHistory trusteeId={trusteeId} setTrusteeId={setTrusteeId} />
        </Row>
      ) : (
        <Row gutter={[32, 32]} 
        className="community"
        // style={{ margin: 10 }}
        >
          <Col span={24}></Col>
          <Col xl={16} lg={16} md={16} sm={20} xs={20}>
            <Breadcrumb 
            // style={{ margin: 10 }}
            >
              <Breadcrumb.Item
                onClick={() => onClickCommunity()}
                className="breadcrumCommunity"
              >
                Communities
              </Breadcrumb.Item>
              <Breadcrumb.Item className="breadcrumUsers">
                Tickets
              </Breadcrumb.Item>
              <Breadcrumb.Item className="breadcrumUsers"></Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col xl={8} lg={8} md={8} sm={24} xs={24}>
            <Row justify="end" gutter={[8, 8]}>
              <Col xl={12} lg={12} md={18} sm={24} xs={24}>
                {" "}
                <Button
                  onClick={() => openIndustry()}
                  type="primary"
                  style={{ borderRadius: "4px", marginBottom: "27px" }}
                  className="button-search"
                >
                  Add ticket
                </Button>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row justify="space-between">
              <Col>
                <Tabs
                items={items}
                  defaultActiveKey="1"
                  onTabClick={onTabClick}
                  animated={false}
                  renderTabBar={(props, DefaultTabBar) => (
                    <DefaultTabBar {...props} className="cMTabsBckground">
                      {(node) => (
                        <div key={node.key} index={node.key}>
                          {node}
                        </div>
                      )}
                    </DefaultTabBar>
                  )}
                >
                </Tabs>
              </Col>
              <Col>
                <Button type="primary"></Button>
              </Col>
            </Row>
          </Col>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <div
              style={{
                height: height,
                overflow: "hidden",
                overflowY: "scroll",
                backgroundColor: "#FFFFFF",
              }}
              className="customLayout"
            >
              <Row justify="space-around">
                <Col span={23}>
                  <Row justify="end">
                    <Col></Col>
                  </Row>
                </Col>
                <CreateTicketDrawer
                  isModalVisible={isModalVisible}
                  setIsModalVisible={setIsModalVisible}
                  editId={editId}
                  setEditId={setEditId}
                  page={page}
                  setIsChatDrawerVisible={setIsChatDrawerVisible}
                  setCreateTicketSuccessData={setCreateTicketSuccessData}
                  isCreateTicketsLoading={isCreateTicketsLoading}
                  setTicketByIdState={setTicketByIdState}
                />
                <ChatDrawer
                  isChatDrawerVisible={isChatDrawerVisible}
                  createTicketSuccessData={createTicketSuccessData}
                  setIsChatDrawerVisible={setIsChatDrawerVisible}
                  page={page}
                  setTicketByIdState={setTicketByIdState}
                  ticketByIdState={ticketByIdState}
                  backgroundColor={backgroundColor}
                />
                <Col span={23}>
                  {tabKey === "1" ? (
                    <CustomTable data={allTickets?.tickets} columns={columns} />
                  ) : (
                    <ArchieveData />
                  )}
                </Col>
                <Col span={23}>
                  <Row justify="center" 
                  // style={{ margin: "10px 0px" }}
                  >
                    {tabKey === "1" ? (
                      <Pagination
                        total={allTickets?.total_length}
                        onChange={(e) => setPage(e)}
                        showSizeChanger={false}
                      />
                    ) : (
                      <Pagination
                        total={allArchiveTickets?.total_length}
                        onChange={(e) => setPage(e)}
                        showSizeChanger={false}
                      />
                    )}
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      )}
    </Spin>
  );
};
export default Ticket;

const ActionComponent = (props) => {
  const {
    id,
    setIsChatDrawerVisible,
    getTicketByIdAction,
    getArchiveTicketByIdAction,
    setCreateTicketSuccessData,
  } = props;

  const dispatch = useDispatch();
  const viewDiscussionMethod = (Id) => {
    dispatch(getTicketByIdAction(Id));
    setIsChatDrawerVisible(true);
    setCreateTicketSuccessData("");
  };

  return (
    <div>
      <Tooltip title="View Chat">
        <MessageFilled
          onClick={() => viewDiscussionMethod(id)}
          style={{ fontSize: 18 }}
        />
      </Tooltip>
    </div>
  );
};
