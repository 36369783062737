import { Button, Popconfirm } from "antd";
import { useDispatch } from "react-redux";
import {
  Tooltip,
} from "antd";
import {
  // EditOutlined,
  // DeleteOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import PublishedIcon from "../../../assets/PublishedIcon.svg";
import Edit2 from "../../../assets/Edit2.svg";
import Delete from "../../../assets/delete.svg";
export const DeleteColumnAction = (props) => {
  const { id, actionArray, customMethod } = props;
  const dispatch = useDispatch();
  const handleDeleteMethod = (Id) => {
    dispatch(actionArray[0](Id, handleSuccessDeleteMethod));
  };
  const handleSuccessDeleteMethod = () => {
    customMethod();
    // dispatch(action[1]());
  };
  return (
    <Popconfirm
      title="Are you sure?"
      onConfirm={() => handleDeleteMethod(id)}
      okText="Delete"
      cancelText="Cancel"
      placement="topLeft"
    >
       <Tooltip title="Delete">
      <Button type="link" icon={<img src={Delete} alt=""/>}>
        {/* <DeleteOutlined /> */}
      </Button>
      </Tooltip>
    </Popconfirm>
  );
};

export const ChangeStatusColumnAction = (props) => {
  const { id, status, actionArray, customMethod, type } = props;
  const dispatch = useDispatch();

  let StatusData = {};

  const handleChangeStatusMethod = (Id) => {
    StatusData.id = Id;
    if (type === "PublishUnpublish") {
      StatusData.published = true;
    }
    if (type === "ProfileStatus") {
      StatusData.profile_status = status === "Active" ? "in-active" : "active";
    }
    dispatch(
      actionArray[0](
        type === "PublishUnpublish" ? StatusData?.id : StatusData,
        handleSuccessChangeStatusMethod
      )
    );
  };
  const handleSuccessChangeStatusMethod = () => {
    customMethod();
  };
  return (
    <Popconfirm
      title={`${
        status === "Active"
          ? "Inactive this profile ?"
          : status === "InActive"
          ? "Active this profile ?"
          : type === "PublishUnpublish" && status === false
          ? "Do you want to publish it?"
          : null
      }`}
      onConfirm={() => handleChangeStatusMethod(id)}
      okText="Okay"
      cancelText="Cancel"
      placement="topLeft"
    >
      <Button type="text">
        {status === "Active" ? (
      <Tooltip title="Active">

          <EyeInvisibleOutlined style={{fontSize:20,color:'gray'}}/>
          </Tooltip>
        ) : status === "InActive" ? (
          <Tooltip title="InActive">
          <EyeOutlined style={{fontSize:20,color:'gray'}}/>
          </Tooltip>
        ) : type === "PublishUnpublish" && status === false ? (
         <Tooltip title="Click for Unpublish">
          <img src={PublishedIcon} alt=""/>
          </Tooltip>
        ) : null}
      </Button>
      
    </Popconfirm>
  );
};

export const EditColumnAction = (props) => {
  const { id, customMethod } = props;
  // const dispatch = useDispatch();
  const handleEditMethod = (Id) => {
    customMethod(Id);
    // isDispatch && dispatch(actionArray[0])
  };

  return (
    <Tooltip title="Edit"> <Button type="link" onClick={() => handleEditMethod(id)} icon={<img src={Edit2} alt=""/>}>
      {/* <EditOutlined /> */}
    </Button>
    </Tooltip>
  );
};

