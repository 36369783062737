import React, { useState, useEffect } from "react";
import {
  Input,
  Button,
  Row,
  Col,
  Typography,
  Modal,
  Alert,
  Spin,
  message,
  Empty,
  Select,
} from "antd";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import "./Addfamily.css";
import { useDispatch, useSelector } from "react-redux";
import {
  createFamily,
  getFamilies,
  updateFamily,
} from "../../Actions/familyAction";
import { getCommunitiesNative } from "../../Actions/communitiesNativeAction";
import "./Addfamily.css";
import { userSchema } from "../../Utils/Schema";
import _ from "lodash";
import svgImage from "../../assets/familyImage.svg";
import FamilyCard from "./FamilyCard";
import ViewMemberDetails from "./familyDetails";
import { ArrowLeftOutlined } from "@ant-design/icons";
import "./../../FindThePeoplePage/FindOtherPeople.css";
function Familybtn(props) {
  const {
    allFamilies,
    isFamiliesLoading,
    isCreateFamilyLoading,
    isUpdateFamilyLoading,
    allCommunitiesNative,
  } = useSelector((state) => {
    const {
      allFamilies,
      isFamiliesLoading,
      isCreateFamilyLoading,
      isUpdateFamilyLoading,
    } = state.family;
    const { setCommunityId } = state.communities;
    const { allCommunitiesNative, setSelectedCommunityNativeId } =
      state.communitiesNative;
    return {
      allFamilies,
      isFamiliesLoading,
      isCreateFamilyLoading,
      isUpdateFamilyLoading,
      setCommunityId,
      allCommunitiesNative,
      setSelectedCommunityNativeId,
    };
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [successCreate, setSuccessCreate] = useState(false);
  const [isViewMemberDetailsVisible, setIsViewMemberDetails] = useState(false);
  const [selectedFamilyId, setSelectedFamilyId] = useState("");
  const [selectedMemberId, setSelectedMemberId] = useState("");
  const [editFamilyId, setEditFamilyId] = useState("");
  const [isEditFamilyModalVisible, setIsEditFamilyModalVisible] =
    useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getFamilies());
    dispatch(getCommunitiesNative(process.env.REACT_APP_COMMUNITY_ID));
    const createAlert = () => {
      setSuccessCreate(false);
    };
    const set = setInterval(createAlert, 3000);
    clearInterval(set);
  }, [dispatch]);

  const the_family = allFamilies?.filter((o) => {
    return o.id === editFamilyId;
  });

  const {
    handleSubmit,

    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm({
    // defaultValues: the_family[0],
    resolver: yupResolver(userSchema),
  });
  const { Option } = Select;
  const height = window.innerHeight - 64;
  const showModal = (familyId) => {
    setIsModalVisible(true);
  };
  const createFam = (data) => {
    dispatch(createFamily(data, callback));
  };
  const callback = () => {
    setValue("last_name", "");
    setValue("community_native_id", "");
    reset();
    setIsModalVisible(false);
    setTimeout(() => {
      message.success("Your family created successfully");
    }, 1000);
    dispatch(getFamilies());
  };
  const handleCancel = () => {
    setIsModalVisible(false);
    setEditFamilyId("");
    setIsEditFamilyModalVisible(false);
    reset();
  };
  const containsNumber = (str) => {
    return /\d/.test(str);
  };

  const UpdateFamily = (data) => {
    const { community_native } = the_family[0];
    data.id = editFamilyId;
    data.community_native_id = containsNumber(data.community_native_id)
      ? data.community_native_id
      : community_native?.id;
    dispatch(updateFamily(data, UpdateCallBack));
  };

  const UpdateCallBack = () => {
    reset();
    setIsEditFamilyModalVisible(false);
    dispatch(getFamilies());
    setTimeout(() => {
      message.success("Your family updated successfully");
    }, 1000);
  };

  const hideViewMemberDetails = () => {
    setIsViewMemberDetails(false);
  };

  const membersArray = _.map(allFamilies, _.property("members"));
  // const the_member = _.filter(membersArray[0], (member) => { return  member.id === selectedMemberId } )
  const secondColor = "#" + Math.floor(Math.random() * 1677).toString(14);
  const publishedNatives = allCommunitiesNative?.filter((Natives) => {
    return Natives.published === true;
  });
  return (
    <div
      style={{
        backgroundColor: "#FFFFFF",
        // margin: "10px",
        height: "100%",
      }}
      className="customLayout"
    >
      <>
        {isViewMemberDetailsVisible ? (
          <Row justify="space-around">
            <Col span={23}>
              <Row gutter={[0, 44]}>
                <Col span={24} />
                <ArrowLeftOutlined
                  style={{ fontSize: "20px" }}
                  onClick={() => hideViewMemberDetails()}
                />
              </Row>
            </Col>
            <Col span={23}>
              <ViewMemberDetails
                hideViewMemberDetails={hideViewMemberDetails}
                selectedMemberId={selectedMemberId}
                setSelectedMemberId={setSelectedMemberId}
                setIsViewMemberDetails={setIsViewMemberDetails}
                membersArray={membersArray}
                selectedFamilyId={selectedFamilyId}
                secondColor={secondColor}
              />
            </Col>
          </Row>
        ) : (
          <>
            <Spin spinning={isFamiliesLoading} size="large">
              <div
                style={{
                  height: height,
                  overflow: "hidden",
                  overflowY: "scroll",
                }}
                className="customLayout"
              >
                <Row justify="center" gutter={[0, 20]}>
                  <Col span={24}>
                    <Row gutter={[0, 10]}>
                      <Col span={24} />
                    </Row>
                  </Col>
                  <Col xl={23} lg={21} md={23} xs={23} sm={23}>
                    <Row
                      justify="space-between"
                      gutter={[0, { xs: 20, lg: 20, md: 20, sm: 20 }]}
                    >
                      <Col xs={14} xl={21} lg={18} md={15} sm={15}>
                        <Typography className="FamilyFound">
                        Total&nbsp;
                          {allFamilies && allFamilies.length}&nbsp;
                          Family
                        </Typography>
                      </Col>
                      {successCreate ? (
                        <Alert
                          message="Family Created Successfully"
                          showIcon
                          type="success"
                        />
                      ) : (
                        <Col xs={10} xl={3} lg={3} md={5} sm={6}>
                          <Button
                            type="primary"
                            onClick={() => showModal()}
                            style={{ borderRadius: "4px" }}
                            className="button-search"
                          >
                            Create Family
                          </Button>
                        </Col>
                      )}
                    </Row>

                    <Modal
                      visible={isModalVisible || isEditFamilyModalVisible}
                      title={
                        isEditFamilyModalVisible ? (
                          <Typography className="title">
                            Update Family
                          </Typography>
                        ) : (
                          <Typography className="title">
                            Create Family
                          </Typography>
                        )
                      }
                      footer={null}
                      onCancel={handleCancel}
                      width={500}
                    >
                      <>
                        <Spin
                          spinning={
                            isEditFamilyModalVisible
                              ? isUpdateFamilyLoading
                              : isCreateFamilyLoading
                          }
                          size="large"
                        >
                          <form
                            onSubmit={handleSubmit(
                              isEditFamilyModalVisible
                                ? UpdateFamily
                                : createFam
                            )}
                          >
                            <Row gutter={[10, { xs: 10 }]} align="bottom">
                              <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                                <Typography
                                  className="label"
                                  style={{ paddingBottom: 9 }}
                                >
                                  Family Name
                                </Typography>
                                <Controller
                                  as={
                                    <Input
                                      style={{ borderRadius: "4px" }}
                                      placeholder="Enter Surname"
                                    />
                                  }
                                  name="last_name"
                                  control={control}
                                />
                                {errors.last_name && (
                                  <p style={{ color: "red" }}>
                                   Family Name is required
                                  </p>
                                )}
                              </Col>
                              <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                                <Typography
                                  className="label"
                                  style={{ paddingBottom: 9 }}
                                >
                                  Family Native
                                </Typography>
                                <Controller
                                  as={
                                    <Select
                                      style={{ width: "100%" }}
                                      placeholder="Select Native"
                                      className="inputLabel"
                                    >
                                      {publishedNatives?.map((val, i) => {
                                        return (
                                          <Option value={val.id}>
                                            {val.name}
                                          </Option>
                                        );
                                      })}
                                    </Select>
                                  }
                                  name="community_native_id"
                                  control={control}
                                />
                                {errors.community_native_id && (
                                  <p style={{ color: "red" }}>
                                    Family Native is required
                                  </p>
                                )}
                              </Col>
                            </Row>

                            <Col span={24}>
                              <Row
                                gutter={[10, 10]}
                                justify="end"
                                style={{ 
                                  marginTop: "13px" 
                                }}
                              >
                                {isEditFamilyModalVisible ? (
                                  <Col>
                                    <Button
                                      onClick={handleCancel}
                                      className="allButtonsCancel"
                                    >
                                     Cancel
                                    </Button>
                                  </Col>
                                ) : (
                                  <Col>
                                    <Button
                                      onClick={handleCancel}
                                      className="allButtonsCancel"
                                    >
                                      Cancel
                                    </Button>
                                  </Col>
                                )}
                                {isEditFamilyModalVisible ? (
                                  <Col>
                                    <Button
                                      htmlType="submit"
                                      className="allButtons"
                                    >
                                      Update
                                    </Button>
                                  </Col>
                                ) : (
                                  <Col>
                                    <Button
                                      htmlType="submit"
                                      className="allButtons"
                                    >
                                      Create
                                    </Button>
                                  </Col>
                                )}
                              </Row>
                            </Col>
                          </form>
                        </Spin>
                      </>
                    </Modal>
                    <Row gutter={[0, 24]}>
                      <Col span={24} />
                      <Col span={24} />
                      <Col span={24} />

                      {allFamilies?.length <= 0 ? (
                        <>
                          <Col span={24} />
                          <Col span={24} />
                          <Col span={24} />

                          <Col span={24}>
                            <Row justify="center">
                              <Col>
                                <Empty
                                  description={
                                    <Typography className="empty-details">
                                     No Event Created yet
                                    </Typography>
                                  }
                                  imageStyle={{ height: 38 }}
                                  image={svgImage}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </>
                      ) : (
                        !isViewMemberDetailsVisible &&
                        allFamilies?.map((family) => {
                          return (
                            <FamilyCard
                              family={family}
                              selectedFamilyId={selectedFamilyId}
                              setSelectedFamilyId={setSelectedFamilyId}
                              selectedMemberId={selectedMemberId}
                              setSelectedMemberId={setSelectedMemberId}
                              setIsViewMemberDetails={setIsViewMemberDetails}
                              editFamilyId={editFamilyId}
                              setEditFamilyId={setEditFamilyId}
                              setIsModalVisible={setIsModalVisible}
                              setIsEditFamilyModalVisible={
                                setIsEditFamilyModalVisible
                              }
                              setValue={setValue}
                              secondColor={secondColor}
                            />
                          );
                        })
                      )}
                    </Row>
                  </Col>
                </Row>
              </div>
            </Spin>
          </>
        )}
      </>
    </div>
  );
}

export default Familybtn;
