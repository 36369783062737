import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Spin,
  Typography,
  Modal,
  Input,
  message,
  Select,
  DatePicker,
  Checkbox,
  Upload,
  Drawer
} from "antd";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
// import "../AddFamilymember/Addfamily.css";
// import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import _ from "lodash";
import moment from "moment";
import {
  createCommunityCardsAction,
  updateCommunityCardsAction,
  getCommunityCardsAction,
} from "../../../Actions/communityCardsAction";
import { PlusOutlined } from "@ant-design/icons";
import {
  communityCardSchema,
  communityCardSchemaNew,
} from "../../../Utils/Schema";
import CommunityTextCard from "./CommunityTextCard";
import CommunityLinkCard from "./CommunityLinkCard";
import CommunityImageCard from "./CommunityImageCard";
import CommunityVideoCard from "./CommunityVideoCard";
import CommunityTempleteCard from "./CommunityTempleteCard";
import CongratulationsCard from "./CongratulationsCard";
import TemplateCardModal from "./TemplateCardModal";
import {
  getTemplatesElementsAction,
  createTemplatesElementsAction,
updateEducationAction,
} from "../../../Actions/TemplateElementsAction";
// import ReactDevicePreview from "react-device-preview";

const CommunityCardsModal = (props) => {
  const [preloadedCommunityCardsValues, setPreloadedCommunityCardsValues] =
    useState("");

  const {
    isCreateCommunityCardVisible,
    setIsCreateCommunityCardVisible,
    isEditCommunityCardVisible,
    setIsEditCommunityCardVisible,
    selectedCommunityCard,
    editCommunityCardId,
    setEditCommunityCardId,
    communityCardPage,
    selectedTemplates,
    // isUpdateCommunityCardsLoading,
    // isCreateCommunityCardsLoading,
    isCommunityCardPublished,
  } = props;
  const {
    allCommunityCards,
    isCreateCommunityCardsLoading,
    isUpdateCommunityCardsLoading,
    setCommunityId
  } = useSelector((state) => {
    const { currentUser } = state.authentication;
    const { setCommunityId } = state.communities;
    const {
        allCommunityCards,
        isCreateCommunityCardsLoading,
        isUpdateCommunityCardsLoading,
      } = state.communityCards;
    return {
        setCommunityId,
        allCommunityCards,
        isCreateCommunityCardsLoading,
        isUpdateCommunityCardsLoading,
    };
  });
  const newCommunityExpiryDate = moment(
    selectedCommunityCard[0]?.expiry_date,
    "YYYY-MM-DD"
  );
  const newArray = selectedCommunityCard.map((obj) => {
    return {
      ...obj,
      expiry_date: newCommunityExpiryDate,
      // year: Year,
    };
  });
  const [isDefaultImageVisible, setIsDefaultImageVisible] = useState(true);
  const [OptionSelected, setOptionSelected] = useState(
    selectedCommunityCard[0]?.card_type
  );
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    clearErrors,
    watch,
  } = useForm({
    resolver: yupResolver(
      OptionSelected === "Video" ? communityCardSchemaNew : communityCardSchema
    ),
    defaultValues: preloadedCommunityCardsValues,
  });
  const watching = watch();
  useEffect(() => {
    setPreloadedCommunityCardsValues(newArray[0]);
    reset(newArray[0]);
    // setisPublishedChecked(newArray[0]?.published);
    setOptionSelected(selectedCommunityCard[0]?.card_type);
    setIsDefaultImageVisible(true);
    setImgUrl(selectedCommunityCard[0]?.card_file_key);
  }, [selectedCommunityCard[0], reset]);
  // const [isPublishedChecked, setisPublishedChecked] = useState(
  //   selectedCommunityCard[0]?.published
  // );
  const [showUploadImage, setShowUploadImage] = useState(false);
  const [newFiles, setNewFiles] = useState([]);

  const [imageRequired, setImageRequired] = useState("");
  const [videoLinkRequired, setVideoLinkRequired] = useState("");
  const [isPreview, setIsPreview] = useState(false);
  const [TypedData, setTypedData] = useState({});

  const [imgUrl, setImgUrl] = useState(selectedCommunityCard[0]?.card_file_key);

  const [isValidUrl, setIsValidUrl] = useState("");
  const [validUrlErrorMessage, setValidUrlErrorMessage] = useState("");
  const dispatch = useDispatch();
  const IsEmpty = _.isEmpty(newFiles);
  const isValidHttpUrl = (string) => {
    let url;
    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
  };

  // const handlePreviewCommunityCardsMethod = (data) => {
  //   setIsValidUrl(isValidHttpUrl(TypedData?.url));

  //   const Date = moment(data.expiry_date);
  //   if (OptionSelected === "Image" && IsEmpty) {
  //     setImageRequired("Image is required");
  //   } else if (
  //     (OptionSelected === "Video" || OptionSelected === "Link") &&
  //     _.isEmpty(data.url)
  //   ) {
  //     setVideoLinkRequired("Url is required");
  //   } else {
  //     data.expiry_date = moment(Date._d).format("yyyy/MM/DD");
  //     data.community_card_file = newFiles;
  //     setTypedData(data);
  //     setIsPreview(true);
  //   }
  // };
  useEffect(() => {
    // dispatch(
    //   getSearchUser(
    //     `api/user_search?community_id=${setCommunityId}&page=${currentPage}`
    //   )
    // );
    // dispatch(
    //     getCommunityCardsAction(
    //     `api/community_cards?community_id=${setCommunityId}`
    //   )
    // );
  
  }, [dispatch, setCommunityId]);

  const handleCreateCommunityCardsMethod = (data) => {
    data.community_id = setCommunityId;
    setIsValidUrl(isValidHttpUrl(data?.url));
    const Date = moment(data?.expiry_date);
    if (OptionSelected === "Image" && IsEmpty) {
      setImageRequired("Image is required");
    } else if (
      (OptionSelected === "Video" || OptionSelected === "Link") &&
      data?.url === ""
    ) {
      setVideoLinkRequired("Url is required");
    } else if (
      (OptionSelected === "Video" || OptionSelected === "Link") &&
      isValidHttpUrl(data?.url) === false
    ) {
      setValidUrlErrorMessage("Link is not valid");
    } else {
      data.expiry_date = moment(Date._d).format("yyyy/MM/DD");
      data.community_card_file = newFiles;
      setTypedData(data);
      dispatch(
        createCommunityCardsAction(
          data,
          createCommunitySuccessCallBackMethod,
          createCommunityFailureCallBackMethod,
          IsEmpty
        )
      );
    }
  };
  const createCommunitySuccessCallBackMethod = () => {
    setValue("name", "");
    setValue("url", "");
    setValue("card_type", "");
    setValue("expiry_date", "");
    setValue("description", "");
    setIsCreateCommunityCardVisible(false);
    dispatch(
      getCommunityCardsAction(`api/community_cards?community_id=${setCommunityId}`)
    );
    setShowUploadImage(false);
    // setisPublishedChecked(false);
    clearErrors();
    // reset();
    setIsPreview(false);
    setOptionSelected("");
    setTimeout(() => {
      message.success("Your community card added successfully");
    }, 1000);
  };
  const createCommunityFailureCallBackMethod = () => {};
  const cardArray = [
    { label: "Link", value: "Link" },
    { label: "Text", value: "Text" },
    { label: "Image", value: "Image" },
    { label: "Video", value: "Video" },
    { label: "Templete", value: "Templete" },
  ];
  // const templeteArray = [
  //   { label: "Congratulations", value: "Congratulations" },
  //   { label: "Marriage", value: "Marriage" },
  //   { label: "Achievements", value: "Achievements" },
  //   { label: "New Births", value: "New Births" },
  //   { label: "Death", value: "Death" },
  // ];
  const closeCreateCommunityCardMethod = () => {
    setIsCreateCommunityCardVisible(false);
    setValue("name", "");
    setValue("url", "");
    setValue("card_type", "");
    setValue("expiry_date", "");
    setValue("description", "");
    setEditCommunityCardId("");
    // reset();
    clearErrors();
    setShowUploadImage(false);
    // setisPublishedChecked(false);
    setIsPreview(false);
    setOptionSelected("");
    setIsValidUrl("");
  };
  const closeEditCommunityCardMethod = () => {
    setIsEditCommunityCardVisible(false);
    setNewFiles([]);
    setShowUploadImage(false);
    setIsDefaultImageVisible(true);
    setValue("name", "");
    setValue("url", "");
    setValue("card_type", "");
    setValue("expiry_date", "");
    setValue("description", "");
    setEditCommunityCardId("");
    clearErrors();
    setIsPreview(false);
    setIsValidUrl("");
    reset();
  };

  const propsForUpload = {
    action: handleCreateCommunityCardsMethod || handleEditCommunityCardsMethod,
    onRemove: (file) => {},
    beforeUpload: (file) => {
      setNewFiles(file);
      return false;
    },
    multiple: false,
  };
  const handleUploadMethod = (e) => {
    setShowUploadImage(true);
    setIsDefaultImageVisible(false);
    // setImgUrl(e?.fileList[0]?.thumbUrl);
    setImageRequired("");
  };
  // const handleEditPreviewCommunityCardsMethod = (data) => {
  //   const Date = moment(data.expiry_date);
  //   data.community_card_file = IsEmpty ? null : newFiles;
  //   data.expiry_date = moment(Date._d).format("yyyy/MM/DD");
  //   data.id = editCommunityCardId;
  //   setTypedData(data);
  //   setIsPreview(true);
  // };
  const handleEditCommunityCardsMethod = (data) => {
    setIsValidUrl(isValidHttpUrl(data?.url));
    if (
      (OptionSelected === "Video" || OptionSelected === "Link") &&
      data?.url === ""
    ) {
      setVideoLinkRequired("Url is required");
    } else if (
      (OptionSelected === "Video" || OptionSelected === "Link") &&
      isValidHttpUrl(data?.url) === false
    ) {
      setValidUrlErrorMessage("Link is not valid");
    } else {
      const Date = moment(data.expiry_date);
      data.community_card_file = IsEmpty ? null : newFiles;
      data.expiry_date = moment(Date._d).format("yyyy/MM/DD");
      data.id = editCommunityCardId;
      data.description = data.description
        ? data.description
        : selectedCommunityCard[0]?.description;
      dispatch(
        updateCommunityCardsAction(
          data,
          updateCommunityCardsSuccessCallback,
          IsEmpty
        )
      );
    }
  };
  const updateCommunityCardsSuccessCallback = () => {
    setNewFiles([]);
    setShowUploadImage(false);
    setIsDefaultImageVisible(true);
    setValue("name", "");
    setValue("url", "");
    setValue("card_type", "");
    setValue("expiry_date", "");
    setValue("description", "");
    setIsEditCommunityCardVisible(false);
    setEditCommunityCardId("");
    dispatch(
        getCommunityCardsAction(`api/community_cards?community_id=${setCommunityId}`)
    );
    // reset();
    setIsPreview(false);
    setIsValidUrl("");
    setTimeout(() => {
      message.success("Your community card updated successfully");
    }, 1000);
    clearErrors();
  };
  useEffect(() => {
    if (!editCommunityCardId) {
      // setValue("user_id","");
      setValue("name", "");
      setValue("url", "");
      setValue("card_type", "");
      setValue("expiry_date", "");
      setValue("description", "");
      setEditCommunityCardId("");
    }
  }, [setValue, !editCommunityCardId]);
  const date = new Date("2022/09/30");
  const handleClearErrorMethod = () => {
    setVideoLinkRequired("");
    setIsValidUrl("");
  };
  const [width, setWidth] = useState(window.innerWidth);
  const getDimensions = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", getDimensions);
  }, [width]);

  return (
    // <Drawer
    //   open={
    //     isEditCommunityCardVisible
    //       ? isEditCommunityCardVisible
    //       : isCreateCommunityCardVisible
    //   }
    //   onCancel={
    //     isEditCommunityCardVisible
    //       ? closeEditCommunityCardMethod
    //       : closeCreateCommunityCardMethod
    //   }
    //   footer={false}
    //   title={
    //     isEditCommunityCardVisible
    //       ? "Edit Community Card"
    //       : "Add Community Card"
    //   }
    //   centered={true}
    //   // style={{ height: window.screen.availHeight, padding: "200px 0px" }}
    //   width={800}
    // >
          <Drawer
    maskClosable={false}
    open={
        isEditCommunityCardVisible
          ? isEditCommunityCardVisible
          : isCreateCommunityCardVisible
      }
    onClose={isEditCommunityCardVisible
        ? closeEditCommunityCardMethod
        : closeCreateCommunityCardMethod}
    width={width > 570 ? "570px" : "100%"}
    className="drawer"
    title={isEditCommunityCardVisible ? "Edit Community Card" : "Add Community Card"}
    >
       {/* { OptionSelected === "Templete" ? (  
      <TemplateCardModal 
      selectedTemplates={selectedTemplates}/>  */}
       {/* ):( */}
      <Row gutter={[10, 0]} align="top">
     
        <Col span={11}>
        
            {/* {isPreview && OptionSelected === "Text" ? (
              <CommunityTextCard
                mappedData={TypedData}
                isCommunityCardModal={true}
              />
            ) : isPreview && OptionSelected === "Link" ? (
              <CommunityLinkCard
                mappedData={TypedData}
                isCommunityCardModal={true}
              />
            ) : isPreview && OptionSelected === "Image" ? (
              <CommunityImageCard
                mappedData={TypedData}
                isCommunityCardModal={true}
                imgUrl={imgUrl}
              />
            ) : isPreview && OptionSelected === "Video" ? (
              <CommunityVideoCard
                mappedData={TypedData}
                isCommunityCardModal={true}
              />
             ) : isPreview && OptionSelected === "Templete" ? (
                <CommunityTempleteCard
                  mappedData={TypedData}
                  isCommunityCardModal={true}
                />
              ) :  */}
              {/* // isPreview && OptionSelected === "Congratulations" ? ( */}
              {/* //   <CongratulationsCard */}
              {/* //     mappedData={TypedData} */}
              {/* //     isCommunityCardModal={true} */}
              {/* //   /> */}
              {/* // ): */}
              {/* //  ( */}
                <Spin
                spinning={
                  isEditCommunityCardVisible
                    ? isUpdateCommunityCardsLoading
                    : isCreateCommunityCardsLoading
                }
              >
              
              <form
                onSubmit={handleSubmit(
                  isEditCommunityCardVisible
                    ? handleEditCommunityCardsMethod
                    : handleCreateCommunityCardsMethod
                )}
              >
                <Row gutter={[0, 10]}>
                  <Col span={24}>
                    <Row justify="center">
                      <Col>
                        {isDefaultImageVisible &&
                          newArray[0]?.card_file &&
                          OptionSelected === "Image" && (
                            <Upload
                              listType="picture-card"
                              openFileDialogOnClick={false}
                            >
                              <img
                                src={newArray[0]?.card_file}
                                width={102}
                                alt=""
                              ></img>
                            </Upload>
                          )}
                      </Col>

                      {OptionSelected === "Image" && (
                        <Col>
                          <Upload
                            {...propsForUpload}
                            listType="picture-card"
                            maxCount={1}
                            showUploadList={showUploadImage}
                            onChange={(e) => handleUploadMethod(e)}
                            isImageUrl={(Image) => {
                              setImgUrl(Image?.thumbUrl);
                              return <img width={102} src={Image?.thumbUrl} />;
                            }}
                          >
                            <PlusOutlined size={44} />
                            {newArray[0]?.card_file
                              ? "Edit Image"
                              : "Upload Image"}
                          </Upload>
                        </Col>
                      )}
                      {imageRequired && (
                        <Col span={24}>
                          <Row justify="center">
                            <p style={{ color: "red" }}>{imageRequired}</p>
                          </Row>
                        </Col>
                      )}
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Typography
                      className="label"
                      style={{ paddingBottom: "4px" }}
                    >
                      Card Type
                    </Typography>
                    <Controller
                      as={
                        <Select
                          className="inputLabel"
                          options={cardArray}
                          onSelect={(e) => setOptionSelected(e)}
                        />
                      }
                      name="card_type"
                      control={control}
                      style={{ width: "100%" }}
                    />
                    {errors.card_type && (
                      <p style={{ color: "red" }}>{errors.card_type.message}</p>
                    )}
                  </Col>
                  {/* {(OptionSelected === "Templete" || OptionSelected === "Congratulations")&& (
   <Col span={24}>
   <Typography
     className="label"
     style={{ paddingBottom: "4px" }}
   >
     Templete Type
   </Typography>
   <Controller
     as={
       <Select
         className="inputLabel"
         options={templeteArray}
         onSelect={(e) => setOptionSelected(e)}
       />
     }
     name="card_type"
     control={control}
     style={{ width: "100%" }}
   />
   {errors.card_type && (
     <p style={{ color: "red" }}>{errors.card_type.message}</p>
   )}
 </Col>
                  )} */}


                  <Col span={24}>
                    <Row gutter={[10, 0]}>
                      <Col
                        span={
                          OptionSelected === "Video" ||
                          OptionSelected === "Link"
                            ? 12
                            : 24
                        }
                      >
                        <Typography
                          className="label"
                          style={{ paddingBottom: "4px" }}
                        >
                          Card Name
                        </Typography>
                        <Controller
                          as={<Input className="inputLabel" />}
                          name="name"
                          control={control}
                          // onChange={(e) => console.log({ e })}
                        />
                        {errors.name && (
                          <p style={{ color: "red" }}>{errors.name.message}</p>
                        )}
                      </Col>
                      {(OptionSelected === "Video" ||
                        OptionSelected === "Link") && (
                        <Col span={12}>
                          <Typography
                            className="label"
                            style={{ paddingBottom: "4px" }}
                          >
                            Card Url
                          </Typography>
                          <Controller
                            as={
                              <Input
                                className="inputLabel"
                                onClick={() => handleClearErrorMethod()}
                              />
                            }
                            name="url"
                            control={control}
                          />
                          {videoLinkRequired && (
                            <p style={{ color: "red" }}>{videoLinkRequired}</p>
                          )}
                          {(OptionSelected === "Video" ||
                            OptionSelected === "Link") &&
                            isValidUrl === false && (
                              <p style={{ color: "red" }}>Link is not valid</p>
                            )}
                        </Col>
                      )}
                    </Row>
                  </Col>

                  <Col span={24}>
                    <Typography
                      className="label"
                      style={{ paddingBottom: "4px" }}
                    >
                      Card Expiry Date
                    </Typography>
                    <Controller
                      as={
                        <DatePicker
                          placeholder="Select Date"
                          className="inputLabel"
                          disabledDate={(current) => {
                            let customDate = moment().format("YYYY-MM-DD");
                            return (
                              current &&
                              current < moment(customDate, "YYYY-MM-DD")
                            );
                          }}
                        />
                      }
                      name="expiry_date"
                      control={control}
                      style={{ width: "100%" }}
                    />
                    {errors.expiry_date && (
                      <p style={{ color: "red" }}>
                        {errors.expiry_date.message}
                      </p>
                    )}
                  </Col>
                  {/* {!(OptionSelected === "Video") && ( */}
                    <Col span={24}>
                      <Typography
                        className="label"
                        style={{ paddingBottom: "4px" }}
                      >
                        Community Card Description
                      </Typography>
                      <Controller
                        as={<Input.TextArea className="inputLabel" />}
                        name="description"
                        control={control}
                      />
                      {errors.description && (
                        <p style={{ color: "red" }}>
                          {errors.description.message}
                        </p>
                      )}
                    </Col>
                  {/* )} */}

                  <Col span={24}>
                    <Row justify="end" gutter={[10, 0]}>
                      <Col>
                        <Button
                          onClick={
                            isEditCommunityCardVisible
                              ? closeEditCommunityCardMethod
                              : closeCreateCommunityCardMethod
                          }
                          className="allButtonsCancel"
                        >
                          Cancel
                        </Button>
                      </Col>
                      <Col>
                        <Button htmlType="submit" className="allButtons">
                          {isEditCommunityCardVisible ? "Edit" : "Save"}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </form>
          </Spin>

            {/* )} */}
        </Col>
        <Col span={3} />
        
        <Col span={10}>
          {/* <h2>Preview of community card </h2> */}
          {OptionSelected === "Text" ? (
            <Row>
                <h2>Preview of community card </h2>
            <CommunityTextCard
              mappedData={watching}
              isCommunityCardModal={true}
            />
            </Row>
          ) : OptionSelected === "Link" ? (
            <Row>
                <h2>Preview of community card </h2>
            <CommunityLinkCard
              mappedData={watching}
              isCommunityCardModal={true}
            />
            </Row>
          ) : OptionSelected === "Image" ? (
            <Row>
                <h2>Preview of community card </h2>
            <CommunityImageCard
              mappedData={watching}
              isCommunityCardModal={true}
              imgUrl={imgUrl}
            />
            </Row>
          ) : OptionSelected === "Video" ? (
            
            <Row>
                <h2>Preview of community card </h2>
                <CommunityVideoCard
              mappedData={watching}
              isCommunityCardModal={true}
            />
            </Row>
          ) 
          // : OptionSelected === "Templete" ? (
          //   <Row>
          //   <h2>
          // Preview of Templete card
          //   </h2>
          //   <CommunityTempleteCard
          //     mappedData={watching}
          //     isCommunityCardModal={true}
          //   />
          //   </Row>
          //  )
          //  : OptionSelected === "Congratulations" ? (
          //   <Row>
          //   <h2>
          // Preview of Templete card
          //   </h2>
          //   <CongratulationsCard
          //     mappedData={watching}
          //     isCommunityCardModal={true}
          //   />
          //   </Row>
          //  )
           : null}
          </Col>
          
   <Col span={24}>
        </Col>
            
      </Row>
       {/* )} */}
    </Drawer>
  );
};
export default CommunityCardsModal;
