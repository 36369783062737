import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Spin,
  Table,
  message,
  Typography,
  AutoComplete,
} from "antd";
import "../../FindThePeoplePage/FindOtherPeople.css";
import "./Admin.css";

import _ from "lodash";

import { useDispatch, useSelector } from "react-redux";
import { getSearchUser } from "../../Actions/searchUserAction";
import { updateUserAction } from "../../Actions/authenticationAction";

import PhoneInput from "react-phone-input-international";
import "react-phone-input-international/lib/style.css";

const HandleUsers = () => {
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [number, setNumber] = useState("");
  const searchDependancy = (firstName || lastName || number) === "";

  const { searchUsers, isSearchUserLoading } = useSelector((state) => {
    const { searchUsers, isSearchUserLoading } = state.searchUser;
    return {
      searchUsers,
      isSearchUserLoading,
    };
  });

  useEffect(() => {
    dispatch(getSearchUser(`api/user_search`));
  }, [searchDependancy]);
  const columns = [
    {
      title: "First Name",
      dataIndex: "first_name",
      width: "20em",
      render: (first_name, allUsersList) => {
        return allUsersList?.is_poused ? (
          <div className="hidedRow">{first_name}</div>
        ) : (
          first_name
        );
      },
    },

    {
      title: "Last Name",
      dataIndex: "last_name",
      width: "20em",
      render: (last_name, allUsersList) => {
        return allUsersList?.is_poused ? (
          <div className="hidedRow">{last_name}</div>
        ) : (
          last_name
        );
      },
    },
    {
      title:"Contact Number",
      dataIndex: "phone_with_country_code",

      render: (phone_with_country_code, allUsersList) => {
        return allUsersList?.is_poused ? (
          <div className="hidedRow">{phone_with_country_code}</div>
        ) : (
          phone_with_country_code
        );
      },
    },

    {
      dataIndex: "id",
      render: (id, allUsersList) => (
        <ActionComponent
          id={id}
          searchUsers={searchUsers}
          allUsersList={allUsersList}
          setFirstName={setFirstName}
          setLastName={setLastName}
          setPhoneNumber={setPhoneNumber}
        />
      ),

      // width: "15em"
    },
  ];
  const HandleNameMethod = (Name) => {
    const Text = Name.split(" ");
    setFirstName(Text[0]);

    setLastName(Text[1]);
  };
  const Firstname = _.capitalize(firstName && firstName);
  const Lastname = _.capitalize(lastName && lastName);
  const searchUserMethod = () => {
    let url = `api/user_search`;
    const Text = number;
    const TextText = Text.replace(`+${countryCode}`, "");
    if (firstName && lastName && number) {
      url = `api/user_search?first_name=${Firstname}&last_name=${Lastname}&phone=${TextText}`;
    } else if (firstName && lastName) {
      url = `api/user_search?first_name=${Firstname}&last_name=${Lastname}`;
    } else if (firstName) {
      url = `api/user_search?first_name=${Firstname}`;
    } else if (lastName) {
      url = `api/user_search?last_name=${Lastname}`;
    } else if (number) {
      url = `api/user_search?phone=${TextText}`;
    } else {
      url = `api/user_search`;
    }
    dispatch(getSearchUser(url));
  };
  const SearchUsersByFirstName = _.map(searchUsers, (users) => {
    return { value: users.first_name + " " + users.last_name };
  });
  const SearchUsersByPhoneNumber = _.map(searchUsers, (users) => {
    return { value: users.phone };
  });
  const phoneNumberMethod = (value, country, e, formattedValue) => {
    setCountryCode(country.dialCode);
    setNumber(formattedValue);
  };
  return (
    <>
      <Row justify="space-around" align="middle" gutter={[0, 10]}>
        {/* <Col span={24} />
        <Col xl={6} lg={6} md={6} sm={22} xs={22}>
          <Row gutter={[8, 8]}>
            
            <Col span={24}> 
              <Typography className="firstname">
              Search By Name
              
              </Typography>
            </Col>
            <Col span={24}>
              <AutoComplete
                style={{ width: "100%" }}
               
                // value={SearchUsersByFirstName}
                options={SearchUsersByFirstName}
                filterOption={(inputValue, option) =>
                  option.value
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
                // filterOption={SearchUsersByFirstName}
                // onChange={(event) => setFirstName(event)}
                
                onChange={(e) => HandleNameMethod(e)}
                //  onSelect={() => searchUserMethod()}
              />
            </Col>
          </Row>
        </Col>
        <Col xl={6} lg={6} md={6} sm={22} xs={22}>
          <Row gutter={[8, 8]}>
            
            <Col span={24}>  
              <Typography className="firstname">
                Search By Contact Number
              </Typography>
            </Col>
            
            

            <Col span={24}>
              
              <Input
                style={{ width: "100%" }}
                
              
                type="number"
                onChange={(e) => setPhoneNumber(e.target.value)}
             />
            </Col>
          </Row>
        </Col>

        <Col xl={6} lg={6} md={6} sm={22} xs={22}>
          <Row gutter={[10, 18]}>
            <Col span={24}></Col>
            
            
            
            <Col span={24}></Col>
            <Col span={24}>  
             
              <Button
                type="primary"
                className="button-search"
                onClick={() => searchUserMethod()}
              >
                {t("btn.35")}
              </Button>
            </Col>
          </Row>
        </Col>
        <Col xl={4} lg={4} md={4} sm={24} xs={24}></Col> */}
      </Row>
      <Row justify="space-around">
        <Col span={23}>
          <Col span={24} />
          <Row gutter={[10, 0]}>
            <Col xl={6} lg={8} md={12} sm={22} xs={22}>
              <Row gutter={[8, 8]}>
                <Col span={24}>
                  <Typography className="firstname">Search By Name</Typography>
                </Col>
                <Col span={24}>
                  <AutoComplete
                    style={{ width: "100%", borderRadius: "5px" }}
                    // value={SearchUsersByFirstName}
                    options={SearchUsersByFirstName}
                    filterOption={(inputValue, option) =>
                      option.value
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                    // filterOption={SearchUsersByFirstName}
                    // onChange={(event) => setFirstName(event)}
                    onChange={(e) => HandleNameMethod(e)}
                    //  onSelect={() => searchUserMethod()}
                  />
                </Col>
              </Row>
            </Col>
            <Col xl={6} lg={8} md={12} sm={22} xs={22}>
              <Row gutter={[8, 8]}>
                <Col span={24}>
                  <Typography className="firstname">
                    Search By Contact Number
                  </Typography>
                </Col>
                <Col span={24}>
                  <PhoneInput
                    country={"in"}
                    onChange={(value, country, e, formattedValue) =>
                      phoneNumberMethod(value, country, e, formattedValue)
                    }
                    placeholder="Enter Your Contact Number"
                    inputStyle={{ width: "100%", height: "32px" }}
                    autoFormat={false}
                    onlyCountries={["in", "ca", "us", "au", "nz"]}
                  />
                </Col>
              </Row>
            </Col>

            <Col xl={6} lg={6} md={6} sm={22} xs={22}>
              <Row gutter={[10, 27]}>
                <Col span={24}></Col>
                <Col span={24}>
                  <Button
                    type="primary"
                    className="button-search"
                    onClick={() => searchUserMethod()}
                  >
                    Search
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <Spin spinning={isSearchUserLoading} size="large">
            <div className="AdminTable">
              <Table
                columns={columns}
                dataSource={searchUsers}
                className="table"
                size="middle"
              />
            </div>
          </Spin>
        </Col>
      </Row>
    </>
  );
};

export default HandleUsers;

const ActionComponent = (props) => {
  const {
    id,
    searchUsers,
    allUsersList,
    setFirstName,
    setLastName,
    setPhoneNumber,
  } = props;

  const dispatch = useDispatch();
  const unblockUserMethod = (Id) => {
    const selectedUser = searchUsers.filter((Users) => {
      return Users.id === Id;
    });
    selectedUser[0].is_poused = false;
    selectedUser[0].education_id = selectedUser[0]?.education?.id;

    selectedUser[0].industry_id = selectedUser[0]?.industry?.id;

    dispatch(updateUserAction(selectedUser[0], true, successUnblockMethod));
  };

  const successUnblockMethod = () => {
    dispatch(getSearchUser("api/user_search"));
    setFirstName("");
    setLastName("");

    setPhoneNumber("");
    setTimeout(() => {
      message.success("User Unblocked Successfully");
    }, 1000);
  };

  const blockUserMethod = (Id) => {
    const selectedUser = searchUsers.filter((Users) => {
      return Users.id === Id;
    });
    selectedUser[0].is_poused = true;
    selectedUser[0].education_id = selectedUser[0]?.education?.id;
    selectedUser[0].industry_id = selectedUser[0]?.industry?.id;
    dispatch(updateUserAction(selectedUser[0], true, successBlockMethod));
  };

  const successBlockMethod = () => {
    dispatch(getSearchUser("api/user_search"));
    setFirstName("");

    setLastName("");
    setPhoneNumber("");
    setTimeout(() => {
      message.success("User Blocked Successfully");
    }, 1000);
  };
  return (
    <Row justify="space-around">
      {allUsersList.is_poused ? (
        <Col span={10}>
          <Button
            onClick={() => unblockUserMethod(id)}
            className="editDesignationButton"
            type="primary"
          >
            Active
          </Button>
        </Col>
      ) : (
        <Col span={10}>
          <Button
            onClick={() => blockUserMethod(id)}
            className="unPublishButton"
            type="primary"
          >
            Inactive
          </Button>
        </Col>
      )}
    </Row>
  );
};
