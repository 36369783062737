import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Pagination,
  Avatar,
  Typography,
  message,
  Spin,
  Tooltip,
  Popconfirm
} from "antd";
import _ from "lodash";
import JourneyDrawer from "./JourneyDrawer";
import Edit2 from "../../../assets/Edit2.svg";
import { useDispatch, useSelector } from "react-redux";
import JourneyDetails from "./JourneyDetails";
import CustomTable from "../../ReuseableComponents/CustomTable/CustomTable";
import {
  deleteJourney,
  getJourney,
  updateJourneyAction,
  getJourneyById
} from "../../../Actions/JourneyAction";
import Delete from "../../../assets/delete.svg";
import PublishedIcon from "../../../assets/PublishedIcon.svg";
import UnpublishedIcon from "../../../assets/UnpublishedIcon.svg";
import { ImageEnv } from "../../CommonLogics/CommonMethods";
import {
  getJourneyRoute,
  getJourneyRouteById,
} from "../../../Actions/JourneyRouteAction";
import { history } from "../../../Utils/history";

const Journey = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editId, setEditId] = useState("");
  const [journeyDetailId, setJourneyDetailId] = useState("");
  const [selectedJourney, setSelectedJourney] = useState(false);
  const [valName, setValName] = useState("");
  const [isDefaultImageVisible, setIsDefaultImageVisible] = useState(true);
  const [showUploadImage, setShowUploadImage] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const dispatch = useDispatch();
  let UnpublishData = {};
  let PublishData = {};
  const { allJourney, isjourneyloading,setjourneyId,allJourneyRoute ,setjourneyRouteId} = useSelector((state) => {
    const { allJourney, isjourneyloading,setjourneyId } = state.journey;
    const { allJourneyRoute ,journeyRoute,setjourneyRouteId} = state.journeyRoute;

    return {
      allJourneyRoute,
      setjourneyId,
      allJourney,
      isjourneyloading,
      setjourneyRouteId
    };
  });
  const { total_length } = allJourney || {};
  useEffect(() => {
    dispatch(getJourney(`api/journeys?page=${currentPage}`));
    dispatch(getJourneyRoute(`api/journey_routes?journey_id=${setjourneyRouteId}`));

  }, [currentPage,setjourneyRouteId]);
  const handlePublish = (id) => {
    PublishData.id = id;
    PublishData.published = false;
    dispatch(updateJourneyAction(PublishData, successPublishMethod));
  };
  const successPublishMethod = () => {
    setTimeout(() => {
      message.success("Your Blog Unpublished successfully");
    }, 1000);
    // dispatch(getCommunities(`api/communities?page=${currentPage}`));
  };
  const handleUnPublish = (id) => {
    UnpublishData.id = id;
    UnpublishData.published = true;
    dispatch(updateJourneyAction (UnpublishData, successUnPublishMethod));
  };
  const successUnPublishMethod = () => {
    setTimeout(() => {
      message.success("Your Blog Published successfully");
    }, 1000);
    // dispatch(getCommunities(`api/communities?page=${currentPage}`));
  };

  const handleOpenJouney = (Id) => {
    setJourneyDetailId(Id);
    history.push(`/journeyroute/${Id}`, { id: Id });
    // dispatch(getJourneyRoute(`api/journey_routes?journey_id=${setjourneyId}`));

    dispatch(getJourneyRouteById(Id));
    dispatch({
      type: "GET_SELECTED_JOURNEY_ROUTE_ID",
      payload: Id,
    });
  };
  const handleDeleteCountries = (Id) => {
    dispatch(deleteJourney(Id, successDelete, failureDelete));
  };
  const successDelete = () => {
    setTimeout(() => {
      message.success("Your Blog Deleted Successfully");
    }, 1000);
    dispatch(getJourney(`api/journeys?page=${currentPage}`));
  };
  const failureDelete = () => {
    setTimeout(() => {
      message.error("Something went wrong.");
    }, 1000);
  };
  const handleEdit = (id) => {
    setEditId(id);
    setIsModalVisible(true);
    setIsDefaultImageVisible(true);
    setShowUploadImage(false);
  };
  const Columns = [
    {
        title: "Blog Image",
        dataIndex: "journey_image_key",
        width: "10%",
        render: (id, { journey_image_key, title }) => {
          return (
            <Row>
              <Col xl={4} lg={4} md={4} sm={4} xs={4}>
                <Avatar
                  shape="square"
                  size={45}
                  className="avtar"
                  src={journey_image_key &&  ImageEnv(journey_image_key)}
                >
                  <Typography style={{ fontSize: "20px", color: "white" }}>
                    {title?.[0]}
                  </Typography>
                </Avatar>
              </Col>
            </Row>
          );
        },
      },
    {
        title: "Title",
        dataIndex: "title",
        width: "10%",
        render: (Id, { id, title }) => {
          return (
            <Row>
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <Tooltip title="View blog Details">
                  <Typography
                    className="community-Title"
                    onClick={() => handleOpenJouney(id)}
                  >
                    {title}
                  </Typography>
                </Tooltip>
              </Col>
            </Row>
          );
        },
        sorter: (a, b) => a.title.localeCompare(b.title),
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Description",
        dataIndex: "description",
        width: "25%",
        sorter: (a, b) => a.description.localeCompare(b.description),
        sortDirections: ["descend", "ascend"],
      },
    {
      title: "Route",
      dataIndex: "url",
      width: "15%",
    },
    {
      title: "Action",
      dataIndex: "id",
      width: "10%",
      render: (id, { published }) => {
        return (
        //   <Row justify="space-between">
        //     <Col>
        //       {published ? (
        //         <Tooltip title="Click for Unpublish">
        //           <Button
        //             onClick={() => handlePublish(id)}
        //             icon={<img src={PublishedIcon} />}
        //             type="text"
        //           ></Button>
        //         </Tooltip>
        //       ) : (
        //         <Tooltip title="Click for Publish">
        //           <Button
        //             onClick={() => handleUnPublish(id)}
        //             icon={<img src={UnpublishedIcon} />}
        //             type="text"
        //           ></Button>
        //         </Tooltip>
        //       )}
        //     </Col>
        //   </Row>
        <Row justify="space-around"> 
            <Col>
              <Tooltip title="Edit">
                <Button
                  icon={<img src={Edit2} />}
                  type="text"
                  onClick={() => handleEdit(id)}
                />
              </Tooltip>
            </Col>
          <Col>
        <Tooltip title="Delete">
          <Popconfirm
            title="Are you sure?"
            onConfirm={() => handleDeleteCountries(id)}
            okText="Ok"
            cancelText="Cancel"
          >
            <Button icon={<img src={Delete} />} type="text" />
          </Popconfirm>
        </Tooltip>
      </Col></Row>
        );
      },
    },
  ];
  return (
    <Spin spinning={isjourneyloading}>
      <Row gutter={[32, 32]}
      className="community" >
     
        <Col span={24}></Col>
       
        <JourneyDrawer
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          editId={editId}
          setEditId={setEditId}
          journeyDetailId={journeyDetailId}
          setJourneyDetailId={setJourneyDetailId}
          setValName={setValName}
          valName={valName}
          setSelectedJourney={setSelectedJourney}
          selectedJourney={selectedJourney}
          setIsDefaultImageVisible={setIsDefaultImageVisible}
          isDefaultImageVisible={isDefaultImageVisible}
          setShowUploadImage={setShowUploadImage}
          showUploadImage={showUploadImage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        ></JourneyDrawer>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          {journeyDetailId ? (
            <JourneyDetails
              isModalVisible={isModalVisible}
              allJourney={allJourney}
              setIsModalVisible={setIsModalVisible}
              editId={editId}
              setEditId={setEditId}
              journeyDetailId={journeyDetailId}
              setJourneyDetailId={setJourneyDetailId}
              setValName={setValName}
              valName={valName}
              setIsDefaultImageVisible={setIsDefaultImageVisible}
              setSelectedJourney={setSelectedJourney}
              selectedJourney={selectedJourney}
              isDefaultImageVisible={isDefaultImageVisible}
              setShowUploadImage={setShowUploadImage}
            />
          ) : (
            <>
              <Row>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                  <Row justify="end" gutter={[8, 8]}>
                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                        {allJourney?.journeys?.journey_type}
                    </Col>

                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                      <Button
                        className="editDesignationButton"
                        style={{ width: "100%" }}
                        onClick={() => setIsModalVisible(true)}
                      >
                        Add Title
                      </Button>
                    </Col>
                    <Col span={24}></Col>
                    <Col span={24}></Col>
                    <Col span={24}></Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row gutter={[32, 32]}>
                    <Col span={24}>
                      <CustomTable
                        columns={Columns}
                        data={allJourney?.journeys}
                      />
                    </Col>
                  </Row>
                </Col>
                {allJourney && total_length > 0 ? (
                <Col span={24}>
                  <Row justify="center" style={{ 
                    // margin: "20px 0px" 
                    }}>
                    <Pagination
                      total={allJourney?.total_length}
                      onChange={(e) => setCurrentPage(e)}
                      responsive={true}
                      size="large"
                      current={currentPage}
                      showSizeChanger={false}
                    />
                  </Row>
                </Col>
                ):""}
              </Row>
            </>
           )} 
        </Col>
      </Row>
     </Spin>
  );
};

export default Journey;
