import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Spin,
  Typography,
  message,
  Pagination,
  Tooltip,
  Breadcrumb,
  Tabs,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import CustomTable from "../ReuseableComponents/CustomTable/CustomTable";
import { getBusiness, deleteBusiness,businessProfileById } from "../../Actions/businessAction";
import BusinessDrawer from "./BusinessDrawer";
import { history } from "../../Utils/history";
import { getCommunityById } from "../../Actions/communitiesAction";
import {
  DeleteColumnAction,
  EditColumnAction,
} from "../ReuseableComponents/CustomTable/CustomTableColumnActions";
import { ProfileOutlined } from "@ant-design/icons";

import _ from "lodash";
import BusinessUserDetails from "./BusinessUserDetails";
const Business = () => {
  const dispatch = useDispatch();

  const {  setCommunityId, allBusiness, isBusinessLoading } =
    useSelector((state) => {
      const { searchUsers } = state.searchUser;
      const { setCommunityId } = state.communities;
      const {
        allBusiness,
        isBusinessLoading,
      } = state.business;

      return {
        searchUsers,
        setCommunityId,
        allBusiness,
        isBusinessLoading,
      };
    });
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    dispatch(
      getBusiness(
        `api/business_profiles?community_id=${setCommunityId}&page=${currentPage}`
      )
    );
  }, [setCommunityId,currentPage]);
  const [isBusinessDrawerVisible, setIsBusinessDrawerVisible] = useState(false);
  const [isEditBusinessId, setIsEditBusinessId] = useState("");
  const [tabKey, setTabkey] = useState("1");
  const [businessId, setBusinessId] = useState("");
  const [isUsersDrawerVisible, setIsUsersDrawerVisible] = useState(false);
  const [editId, setEditId] = useState("");

  const { TabPane } = Tabs;
  const openEditDrawer = (Id) => {
    setIsUsersDrawerVisible(true);
    setEditId(Id);
    history.push(`/users/${Id}`, { id: Id });
  };
  const successDeleteBusinessMethod = () => {
    setTimeout(() => {
      message.success("Your Business Profile Deleted Successfully");
    }, 1000);
    dispatch(
      getBusiness(
        `api/business_profiles?community_id=${setCommunityId}&page=${currentPage}`
      )
    );
  };
  const openEditBusinessDrawer = (Id) => {
    setIsBusinessDrawerVisible(true);
    setIsEditBusinessId(Id);
  };
  const onTabClick = (key) => {
    setTabkey(key);
  };
  const handleOpenCommunity = (Id) => {
    history.push(`/communitybusiness/${Id}`, { id: Id });

    setBusinessId(Id);
    dispatch(businessProfileById(Id));
    dispatch({
      type: " GET_SELECTED_BUSINESS_ID",
      payload: Id,
    });
  };
  const { total_length } = allBusiness || {};
  const businessColumns = [
    {
      title: "Name",
      dataIndex: "id",
      width: "15%",
      render: (id, { user }) => {
        return(<Row>
          <Col xl={16} lg={16} md={16} sm={24} xs={24}>
          <Tooltip title="View Profile">
            <Typography
              onClick={() => openEditDrawer(user.id)}
            >
              {user?.first_name}
            </Typography>
            </Tooltip>
          </Col>
          </Row>)
      },
      sorter: (a, b) => (a.user.first_name.localeCompare(b.user.first_name)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: "Company Name",
      dataIndex: "company_name",
      width: "15%",
     
      sorter: (a, b) => (a.company_name.localeCompare(b.company_name)),
      sortDirections: ['descend', 'ascend'],
    },

    {
      title: "Business Location",
      dataIndex: "business_location",
      width: "15%",
      sorter: (a, b) => (a.business_location.localeCompare(b.business_location)),
      sortDirections: ['descend', 'ascend'],
    },

    {
      title: "Service Provide",
      dataIndex: "service_provide",
      width: "15%",
      sorter: (a, b) => (a.service_provide.localeCompare(b.service_provide)),
      sortDirections: ['descend', 'ascend'],
    },
    {
     title: "Action",
     dataIndex: "id",
     width: "10%",
     render: (id) => {
       return (
         <Row justify='space-around'>
           <Col>
              <Tooltip title="View Profile">
                <Button  type="text" >
                  <ProfileOutlined style={{fontSize:20,color:"gray"}} onClick={() => handleOpenCommunity(id)}/>
                </Button>
              </Tooltip>
            </Col>
           <Col>
             <EditColumnAction id={id} customMethod={openEditBusinessDrawer} />
           </Col>

           <Col>
             <DeleteColumnAction
               id={id}
               actionArray={[deleteBusiness]}
               customMethod={successDeleteBusinessMethod}
             />
           </Col>
         </Row>
       );
     },
   },
  ];

  const jobProfileColumns = [
    {
      title: "Name",
      dataIndex: "id",
      width: "15%",
      render: (id, { user }) => {
        return(<Row>
          <Col xl={16} lg={16} md={16} sm={24} xs={24}>
          <Tooltip title="View Profile">
            <Typography
              onClick={() => openEditDrawer(user.id)}
            >
              {user?.first_name}
            </Typography>
            </Tooltip>
          </Col>
          </Row>)
      },
      sorter: (a, b) => (a.user.first_name.localeCompare(b.user.first_name)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: "Company Name",
      dataIndex: "company_name",
      width: "15%",
      sorter: (a, b) => (a.company_name.localeCompare(b.company_name)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: "Job Title",
      dataIndex: "job_title",
      width: "15%",
      sorter: (a, b) => (a.job_title.localeCompare(b.job_title)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: "Experience",
      dataIndex: "experiance",
      width: "15%",
      sorter: (a, b) => (a.experiance.localeCompare(b.experiance)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: "Job Location",
      dataIndex: "business_location",
      width: "15%",
      sorter: (a, b) => (a.business_location.localeCompare(b.business_location)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: "Action",
      dataIndex: "id",
      width: "15%",
      render: (id) => {
        return (
          <Row>
             <Col>
              <Tooltip title="View Profile">
                <Button type="text"  onClick={() => handleOpenCommunity(id)}>
                  <ProfileOutlined style={{fontSize:20,color:"gray"}}/>
                </Button>
              </Tooltip>
            </Col>
            <Col>
              <EditColumnAction id={id} customMethod={openEditBusinessDrawer} />
            </Col>

            <Col>
              <DeleteColumnAction
                id={id}
                actionArray={[deleteBusiness]}
                customMethod={successDeleteBusinessMethod}
              />
            </Col>
          </Row>
        );
      },
    },
  ];

  const filteredAllBusinessRecords = _.filter(
    allBusiness?.business_profiles,
    (val) => {
      return val.is_business === (tabKey === "1" ? true : false);
    }
  );
  const onClickCommunity = () => {
    history.push("/communities");
    dispatch(getCommunityById(""));
    dispatch({
      type: "GET_SELECTED_COMMUNITY_ID",
      payload: "",
    });
  };
  const items = [
    {
      key: '1',
      label:(
        <Button
          className={tabKey === "1" ? "selectedButton" : "unselectedButton"}
        >
          Business Profile
        </Button>
      ),
    },
    {
      key: '2',
      label:(
        <Button
        className={tabKey === "2" ? "selectedButton" : "unselectedButton"}
        >
           Job Profile
        </Button>
      ),
    },
  ];
  return (
    <Row>
    
    <Row gutter={[32, 32]} 
        className="community"

    style={{
      //  margin: 10
        }}>
            <Col span={24}></Col>
            
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <Breadcrumb style={{ 
                // margin: 10
                 }}>
                <Breadcrumb.Item
                  onClick={() => onClickCommunity()}
                  className="breadcrumCommunity"
                >
                  Communities
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  className="breadcrumUsers"
                >
                  {tabKey === "1" ? " Business Profile" : " Job Profile"}
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            {businessId ? 
      (<BusinessUserDetails
          setEditId={setEditId}
          businessId={businessId}
          setBusinessId={setBusinessId}
        />):(
            <Row>
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <Tabs
              items={items}
                defaultActiveKey="1"
                onTabClick={onTabClick}
                animated={false}
                renderTabBar={(props, DefaultTabBar) => (
                  <DefaultTabBar {...props} className="cMTabsBckground">
                    {(node) => (
                      <div key={node.key} index={node.key}>
                        {node}
                      </div>
                    )}
                  </DefaultTabBar>
                )}
              >
               
              </Tabs>
            </Col>
            <Col>
              <Button
                type="primary"
                onClick={() => setIsBusinessDrawerVisible(true)}
              >
                {tabKey === "1" ? "Add Business Profile" : "Add Job Profile"}
              </Button>
            </Col>
           
            <BusinessDrawer
              isBusinessDrawerVisible={isBusinessDrawerVisible}
              setIsBusinessDrawerVisible={setIsBusinessDrawerVisible}
              currentPage={currentPage}
              isEditBusinessId={isEditBusinessId}
              setIsEditBusinessId={setIsEditBusinessId}
              tabKey={tabKey}
            />
          </Row>
        </Col>
        <Col span={24}>
          <Spin spinning={isBusinessLoading} size="large">
            <CustomTable
              data={filteredAllBusinessRecords}
              columns={tabKey === "1" ? businessColumns : jobProfileColumns}
            />
          </Spin>
        </Col>
        {allBusiness &&  total_length > 0 ? (
        <Col span={23}>
          <Row justify="center" style={{ 
            // margin: "20px 0px" 
            }}>
            <Pagination
              total={allBusiness?.total_length}
              onChange={(e) => setCurrentPage(e)}
              showSizeChanger={false}
            />
          </Row>
        </Col>
        ):""}
        </Row>
         )}
      </Row>
     
      </Row>
  );
};

export default Business;




