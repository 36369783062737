// import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
// import moment from "moment";

export const loginSchema = yup.object().shape({
  phone: yup.number(10).required("Enter phone number"),
});
export const signupSchema = yup.object().shape({
  // user_image:yup.object().required('User image is required'),
  first_name: yup.string().required("First Name is required"),
  email: yup
    .string()
    .email("Email must contain @")
    .required("Email must be filled"),
  last_name: yup.string().required("Last Name is required"),
  // phone: yup.number().required("Phone number is required"),
  // user_type:yup.string().required('User type is required')
});
export const createUserSchema = yup.object().shape({
  first_name: yup
    .string()
    .required("First Name is required")
    .trim()
    .when({
      is: (val) => val?.length === 0,
      then: yup
        .string()
        .matches(
          /^[a-zA-Z0-9]+$/,
          "This field cannot contain white space and special character"
        ),
    }),
  // date_of_birth: yup.date().required("Birth Date is required"),
  user_type: yup
    .string()
    .required("User type is required")
    .trim()
    .when({
      is: (val) => val?.length === 0,
      then: yup
        .string()
        .matches(
          /^[a-zA-Z0-9]+$/,
          "This field cannot contain white space and special character"
        ),
    }),
  last_name: yup
    .string()
    .required("Last Name is required")
    .trim()
    .when({
      is: (val) => val?.length === 0,
      then: yup
        .string()
        .matches(
          /^[a-zA-Z0-9]+$/,
          "This field cannot contain white space and special character"
        ),
    }),
});

export const editUserSchema = yup.object().shape({
  first_name: yup
    .string()
    .required("First Name is required")
    .trim()
    .when({
      is: (val) => val?.length === 0,
      then: yup
        .string()
        .matches(
          /^[a-zA-Z0-9]+$/,
          "This field cannot contain white space and special character"
        ),
    }),
  // date_of_birth: yup.date().required("Birth Date is required"),
  last_name: yup
    .string()
    .required("Last Name is required")
    .trim()
    .when({
      is: (val) => val?.length === 0,
      then: yup
        .string()
        .matches(
          /^[a-zA-Z0-9]+$/,
          "This field cannot contain white space and special character"
        ),
    }),
});
export const userSchema = yup.object().shape({
  last_name: yup
    .string()
    .required("Enter family name")
    .matches("^[a-z A-Z]+$", "Enter proper family name"),
  community_native_id: yup.string().required("Enter family native"),
  // familyname:yup.string().required('Family Name is required').matches('^[a-z A-Z]+$','Enter proper family name'),
  // firstname:yup.string().required('First Name is required').matches('^[a-z A-Z]+$','Enter proper first name'),
  // relationship:yup.string().required('Relationship with you is required').matches('^[a-z A-Z]+$','Enter proper relationship with you'),
  // contactnumber:yup.string().required('Contact Number is required').matches('^[7-9][0-9]{9}$','Enter proper contact number')
});

export const addFamilyMemberSchema = yup.object().shape({
  first_name: yup
    .string()
    .required("Enter your name")
    .trim()
    .when({
      is: (val) => val?.length === 0,
      then: yup
        .string()
        .matches(
          /^[a-zA-Z0-9]+$/,
          "This field cannot contain white space and special character"
        ),
    }),
  date_of_birth: yup.date().required("Enter birth date"),
  user_type: yup
    .string()
    .required("User type is required")
    .trim()
    .when({
      is: (val) => val?.length === 0,
      then: yup
        .string()
        .matches(
          /^[a-zA-Z0-9]+$/,
          "This field cannot contain white space and special character"
        ),
    }),
  // phone: yup
  //   .number()
  //   .required("Enter phone number")
  //   .typeError("It does not look like a phone number"),
});
export const updateFamilyMemberSchema = yup.object().shape({
  first_name: yup
    .string()
    .required("Enter your name")
    .trim()
    .when({
      is: (val) => val?.length === 0,
      then: yup
        .string()
        .matches(
          /^[a-zA-Z0-9]+$/,
          "This field cannot contain white space and special character"
        ),
    }),
  date_of_birth: yup.date().required("Enter birth date"),
});
export const MatrimonialuserSchema = yup.object().shape({
  birth_place: yup
    .string()
    .required("Birth Place is required")
    .trim()
    .when({
      is: (val) => val?.length === 0,
      then: yup
        .string()
        .matches(
          /^[a-zA-Z0-9]+$/,
          "This field cannot contain white space and special character"
        ),
    }),
  height: yup
    .string()
    .required("Height is required")
    .trim()
    .when({
      is: (val) => val?.length === 0,
      then: yup
        .string()
        .matches(
          /^[a-zA-Z0-9]+$/,
          "This field cannot contain white space and special character"
        ),
    }),
  weight: yup.number().required("Weight is required"),
  description: yup
    .string()
    .required("Description is required")
    .trim()
    .when({
      is: (val) => val?.length === 0,
      then: yup
        .string()
        .matches(
          /^[a-zA-Z0-9]+$/,
          "This field cannot contain white space and special character"
        ),
    }),
  user_id: yup.string().required("Name is required"),
  current_city: yup
    .string()
    .required("Current city is required")
    .trim()
    .when({
      is: (val) => val?.length === 0,
      then: yup
        .string()
        .matches(
          /^[a-zA-Z0-9]+$/,
          "This field cannot contain white space and special character"
        ),
    }),
  marital_status: yup.string().required("Marital status is required"),
  birth_time: yup
    .date()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr))
    .required("Birth Time is required"),
});

export const UpdateMatrimonialuserSchema = yup.object().shape({
  birth_place: yup
    .string()
    .required("Birth Place is required")
    .trim()
    .when({
      is: (val) => val?.length === 0,
      then: yup
        .string()
        .matches(
          /^[a-zA-Z0-9]+$/,
          "This field cannot contain white space and special character"
        ),
    }),
  height: yup
    .string()
    .required("Height is required")
    .trim()
    .when({
      is: (val) => val?.length === 0,
      then: yup
        .string()
        .matches(
          /^[a-zA-Z0-9]+$/,
          "This field cannot contain white space and special character"
        ),
    }),
  weight: yup.number().required("Weight is required"),
  description: yup
    .string()
    .required("Description is required")
    .trim()
    .when({
      is: (val) => val?.length === 0,
      then: yup
        .string()
        .matches(
          /^[a-zA-Z0-9]+$/,
          "This field cannot contain white space and special character"
        ),
    }),
  // user_id: yup.string().required("Name is required"),
  current_city: yup
    .string()
    .required("Current city is required")
    .trim()
    .when({
      is: (val) => val?.length === 0,
      then: yup
        .string()
        .matches(
          /^[a-zA-Z0-9]+$/,
          "This field cannot contain white space and special character"
        ),
    }),
  marital_status: yup.string().required("Marital status is required"),
  birth_time: yup
    .date()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr))
    .required("Birth Time is required"),
});

export const BusinessuserSchema = yup.object().shape({
  // user_id: yup.string().required("User is required"),
  company_name: yup
    .string()
    .required("Company Name is required")
    .trim()
    .when({
      is: (val) => val?.length === 0,
      then: yup
        .string()
        .matches(
          /^[a-zA-Z0-9]+$/,
          "This field cannot contain white space and special character"
        ),
    }),
  service_provide: yup
    .string()
    .required("Service Provide is required")
    .trim()
    .when({
      is: (val) => val?.length === 0,
      then: yup
        .string()
        .matches(
          /^[a-zA-Z0-9]+$/,
          "This field cannot contain white space and special character"
        ),
    }),
  business_details: yup
    .string()
    .required("Business Details is required")
    .trim()
    .when({
      is: (val) => val?.length === 0,
      then: yup
        .string()
        .matches(
          /^[a-zA-Z0-9]+$/,
          "This field cannot contain white space and special character"
        ),
    }),
  business_location: yup
    .string()
    .required("Business Location is required")
    .trim()
    .when({
      is: (val) => val?.length === 0,
      then: yup
        .string()
        .matches(
          /^[a-zA-Z0-9]+$/,
          "This field cannot contain white space and special character"
        ),
    }),
  number_of_staff: yup.number().required("Number of staff is required"),
  // working_here_since: yup.string().required("Established Year is required"),
});

export const BusinessuserUpdateSchema = yup.object().shape({
  company_name: yup
    .string()
    .required("Company Name is required")
    .trim()
    .when({
      is: (val) => val?.length === 0,
      then: yup
        .string()
        .matches(
          /^[a-zA-Z0-9]+$/,
          "This field cannot contain white space and special character"
        ),
    }),
  service_provide: yup
    .string()
    .required("Service Provide is required")
    .trim()
    .when({
      is: (val) => val?.length === 0,
      then: yup
        .string()
        .matches(
          /^[a-zA-Z0-9]+$/,
          "This field cannot contain white space and special character"
        ),
    }),
  business_details: yup
    .string()
    .required("Business Details is required")
    .trim()
    .when({
      is: (val) => val?.length === 0,
      then: yup
        .string()
        .matches(
          /^[a-zA-Z0-9]+$/,
          "This field cannot contain white space and special character"
        ),
    }),

  business_location: yup
    .string()
    .required("Business Location is required")
    .trim()
    .when({
      is: (val) => val?.length === 0,
      then: yup
        .string()
        .matches(
          /^[a-zA-Z0-9]+$/,
          "This field cannot contain white space and special character"
        ),
    }),
  number_of_staff: yup.number().required("Number of staff is required"),
});

export const JobProfileSchema = yup.object().shape({
  // user_id: yup.string().required("User is required"),
  job_title: yup
    .string()
    .required("Job title is required")
    .trim()
    .when({
      is: (val) => val?.length === 0,
      then: yup
        .string()
        .matches(
          /^[a-zA-Z0-9]+$/,
          "This field cannot contain white space and special character"
        ),
    }),
  working_here_since: yup
    .string()
    .required("Working here since is required")
    .trim()
    .when({
      is: (val) => val?.length === 0,
      then: yup
        .string()
        .matches(
          /^[a-zA-Z0-9]+$/,
          "This field cannot contain white space and special character"
        ),
    }),
  business_location: yup
    .string()
    .required("Company Location is required")
    .trim()
    .when({
      is: (val) => val?.length === 0,
      then: yup
        .string()
        .matches(
          /^[a-zA-Z0-9]+$/,
          "This field cannot contain white space and special character"
        ),
    }),
  experiance: yup
    .string()
    .required("Experience is required")
    .trim()
    .when({
      is: (val) => val?.length === 0,
      then: yup
        .string()
        .matches(
          /^[a-zA-Z0-9]+$/,
          "This field cannot contain white space and special character"
        ),
    }),
  business_details: yup
    .string()
    .required("Job Details is required")
    .trim()
    .when({
      is: (val) => val?.length === 0,
      then: yup
        .string()
        .matches(
          /^[a-zA-Z0-9]+$/,
          "This field cannot contain white space and special character"
        ),
    }),
  company_name: yup
    .string()
    .required("Company Name is required")
    .trim()
    .when({
      is: (val) => val?.length === 0,
      then: yup
        .string()
        .matches(
          /^[a-zA-Z0-9]+$/,
          "This field cannot contain white space and special character"
        ),
    }),
});

export const UpdateJobProfileSchema = yup.object().shape({
  // user_id: yup.string().required("First Name is required"),
  job_title: yup
    .string()
    .required("Job title is required")
    .trim()
    .when({
      is: (val) => val?.length === 0,
      then: yup
        .string()
        .matches(
          /^[a-zA-Z0-9]+$/,
          "This field cannot contain white space and special character"
        ),
    }),
  working_here_since: yup
    .string()
    .required("Working here since is required")
    .trim()
    .when({
      is: (val) => val?.length === 0,
      then: yup
        .string()
        .matches(
          /^[a-zA-Z0-9]+$/,
          "This field cannot contain white space and special character"
        ),
    }),
  business_location: yup
    .string()
    .required("Company Location is required")
    .trim()
    .when({
      is: (val) => val?.length === 0,
      then: yup
        .string()
        .matches(
          /^[a-zA-Z0-9]+$/,
          "This field cannot contain white space and special character"
        ),
    }),
  experiance: yup
    .string()
    .required("Experiance is required")
    .trim()
    .when({
      is: (val) => val?.length === 0,
      then: yup
        .string()
        .matches(
          /^[a-zA-Z0-9]+$/,
          "This field cannot contain white space and special character"
        ),
    }),
  business_details: yup
    .string()
    .required("Job Details is required")
    .trim()
    .when({
      is: (val) => val?.length === 0,
      then: yup
        .string()
        .matches(
          /^[a-zA-Z0-9]+$/,
          "This field cannot contain white space and special character"
        ),
    }),
  company_name: yup
    .string()
    .required("Company Name is required")
    .trim()
    .when({
      is: (val) => val?.length === 0,
      then: yup
        .string()
        .matches(
          /^[a-zA-Z0-9]+$/,
          "This field cannot contain white space and special character"
        ),
    }),
});

export const eventSchema = yup.object().shape({
  event_name: yup
    .string()
    .required("Event Name is required")
    .trim()
    .when({
      is: (val) => val?.length === 0,
      then: yup
        .string()
        .matches(
          /^[a-zA-Z0-9]+$/,
          "This field cannot contain white space and special character"
        ),
    }),
  city_name: yup
    .string()
    .required("Event City is required")
    .trim()
    .when({
      is: (val) => val?.length === 0,
      then: yup
        .string()
        .matches(
          /^[a-zA-Z0-9]+$/,
          "This field cannot contain white space and special character"
        ),
    }),
  event_location: yup
    .string()
    .required("Event Location is required")
    .trim()
    .when({
      is: (val) => val?.length === 0,
      then: yup
        .string()
        .matches(
          /^[a-zA-Z0-9]+$/,
          "This field cannot contain white space and special character"
        ),
    }),
  event_detail: yup
    .string()
    .required("Event Detail is required")
    .trim()
    .when({
      is: (val) => val?.length === 0,
      then: yup
        .string()
        .matches(
          /^[a-zA-Z0-9]+$/,
          "This field cannot contain white space and special character"
        ),
    }),
  dummy_date: yup.date().required("Event Date is required"),
  dummy_time: yup.date().required("Event Time is required"),
});

export const trusteeSchema = yup.object().shape({
  // user_id: yup.string().required("User must be selected"),

  designation_id: yup.string().required("Designation is required"),
});
export const trusteeEditSchema = yup.object().shape({
  designation_id: yup.string().required("Designation is required"),
});
export const familyAddressSchema = yup
  .object()
  .shape({
    // home_number:yup.number().required('Home number is required'),
    landmark: yup.string().required("Landmark is required"),
    city: yup.string().required("City is required"),
    state: yup.string().required("State is required"),
    zip: yup.number().required("Zipcode is required"),
  })
  .required();

export const otpAuthenticationSchema = yup.object().shape({
  code: yup.number().required("OTP is required"),
});

export const updateUserSchema = yup.object().shape({
  first_name: yup.string().required("First Name is required"),
  last_name: yup.string().required("Last Name is required"),
  // phone: yup.string().required("Phone is required"),

  email: yup.string().required("email is required"),
  date_of_birth: yup.date().required("Enter birth date"),
  education_id: yup.string().required("Enter your education"),
  industry_id: yup.string().required("Enter your industry"),
});

export const nativeSchema = yup.object().shape({
  name: yup.string().required("Native is required")
  .matches(
    /[A-Z]/,
    // /^(?![0-9])(?!.*[0-9]$)(?!.*\d_)(?!.*_\d)[a-zA-Z0-9]+$/,
    "This field cannot contain lowercase,number,special character and white space."
  ),
});

export const communityMeetingSchema = yup.object().shape({
  title: yup.string().required("Title is required"),
  place: yup.string().required("Place is required"),
  description: yup.string().required("Description is required"),
  year: yup.string().required("Year is required").nullable(),
});

export const designationSchema = yup.object().shape({
  designation_title: yup.string().required("Designation title is required")
  .matches(
    /^(?![0-9])(?!.*[0-9]$)(?!.*\d_)(?!.*_\d)[a-zA-Z0-9]+$/,
    "This field cannot contain white space and special character"
  ),

  // designation_parent:yup.string().required('Designation Parent is required'),
  // level:yup.string().required('Level is required')
});
export const educationSchema = yup.object().shape({
  name: yup.string().required("Education name is required")
  .matches(
    /^(?![0-9])(?!.*[0-9]$)(?!.*\d_)(?!.*_\d)[a-zA-Z0-9]+$/,
    "This field cannot contain white space and special character"
  ),
});

export const industrySchema = yup.object().shape({
  name: yup.string().required("Industry name is required"),
});

export const settingsSchema = yup.object().shape({
  name: yup.string().required("Community name is required"),
  description: yup
    .string()
    .required("Community description is required")
    .min(70, "Description must be atleast of 70 letters")
    .nullable("Hello"),
});

export const CommunitySchema = yup.object().shape({
  name: yup.string().required("Community name is required")
  .matches(
    /[A-Z]/,
    // /^(?![0-9])(?!.*[0-9]$)(?!.*\d_)(?!.*_\d)[a-zA-Z0-9]+$/,
    "This field cannot contain lowercase,number,special character and white space."
  ),
  slug: yup.string().required("Slug is required"),
  description: yup.string().required("Community description is required"),
});
export const UsersSchema = yup.object().shape({
  first_name: yup.string().required("Users name is required"),
  // description:yup.string().required('Community description is required').min(70,"Description must be atleast of 70 letters").nullable("Hello")
});
export const FamilySchema = yup.object().shape({

  last_name: yup
    .string()
    .required("Family name is required")
    .matches(
      /[A-Z]/,
      "This field cannot contain lowercase,number,special character and white space."
    ),
  community_native_id: yup.string().required("Community Native is required"),
  // description:yup.string().required('Community description is required').min(70,"Description must be atleast of 70 letters").nullable("Hello")

});
export const DocumentsSchema = yup.object().shape({
  file_name: yup.string().required("File name is required")
  .matches(
    /[A-Z]/,
    // /^(?![0-9])(?!.*[0-9]$)(?!.*\d_)(?!.*_\d)[a-zA-Z0-9]+$/,
    "This field cannot contain lowercase,number,special character and white space."
  ),
  // year: yup.string().required("Select year"),
  // user_id: yup.string().required("Select user name"),
});
export const EventSchema = yup.object().shape({
  event_name: yup.string().required("Event name is required"),
  city_name: yup.string().required("Enter your city"),
  event_location: yup.string().required("Enter your event location"),
  event_detail: yup.string().required("Enter your event detail"),
  event_datetime: yup.string().required("Enter Event Date"),
  // description:yup.string().required('Community description is required').min(70,"Description must be atleast of 70 letters").nullable("Hello")
});
export const MeetingSchema = yup.object().shape({
  title: yup
    .string()
    .required("Title is required")
    .trim()
    .when({
      is: (val) => val?.length === 0,
      then: yup
        .string()
        .matches(
          /^[a-zA-Z0-9]+$/,
          "This field cannot contain white space and special character"
        ),
    }),
  // .matches(
  //   /^[a-zA-Z0-9]+$/,
  //   "This field cannot contain white space and special character"
  // ),
  description: yup
    .string()
    .required("Description is required")
    .min(20, "Description must be atleast of 20 letters")
    .nullable("Hello")
    .trim()
    .when({
      is: (val) => val?.length === 0,
      then: yup
        .string()
        .matches(
          /^[a-zA-Z0-9]+$/,
          "This field cannot contain white space and special character"
        ),
    }),
  // .matches(
  //   /^[a-zA-Z0-9]+$/,
  //   "This field cannot contain white space and special character"
  // ),
  number: yup
    .string()
    .required("Number is required")
    .trim()
    .when({
      is: (val) => val?.length === 0,
      then: yup
        .string()
        .matches(
          /^[a-zA-Z0-9]+$/,
          "This field cannot contain white space and special character"
        ),
    }),
  place: yup
    .string()
    .required("Place is required")
    .trim()
    .when({
      is: (val) => val?.length === 0,
      then: yup
        .string()
        .matches(
          /^[a-zA-Z0-9]+$/,
          "This field cannot contain white space and special character"
        ),
    }),
  // .matches(
  //   /^[a-zA-Z0-9]+$/,
  //   "This field cannot contain white space and special character"
  // ),
  meeting_date: yup.date().required("Meeting date is required"),
});
export const DesignationsSchema = yup.object().shape({
  designation_title: yup.string().required("Designation is required")
  .matches(
    /[A-Z]/,
    // /^(?![0-9])(?!.*[0-9]$)(?!.*\d_)(?!.*_\d)[a-zA-Z0-9]+$/,
    "This field cannot contain lowercase,number,special character and white space."
  ),
  // description:yup.string().required('Community description is required').min(70,"Description must be atleast of 70 letters").nullable("Hello")
});
export const EducationSchema = yup.object().shape({
  name: yup.string().required("Education name is required")
      .matches(
        /[A-Z]/,
        // /^(?![0-9])(?!.*[0-9]$)(?!.*\d_)(?!.*_\d)[a-zA-Z0-9]+$/,
        "This field cannot contain lowercase,number,special character and white space."
      ),
   
});
export const IndustrySchema = yup.object().shape({
  name: yup.string().required("Industry name is required")
  .matches(
    /[A-Z]/,
    // /^(?![0-9])(?!.*[0-9]$)(?!.*\d_)(?!.*_\d)[a-zA-Z0-9]+$/,
    "This field cannot contain lowercase,number,special character and white space."
  ),
});
export const MaintenanceSchema = yup.object().shape({
  name: yup.string().required("Maintenance name is required")
      .matches(
        /[A-Z]/,
        // /^(?![0-9])(?!.*[0-9]$)(?!.*\d_)(?!.*_\d)[a-zA-Z0-9]+$/,
        "This field cannot contain lowercase,number,special character and white space."
      ),
      description: yup
      .string()
      .required("Description is required")
      .min(20, "Description must be atleast of 20 letters")
      .nullable("Hello")
      .trim()
      .when({
        is: (val) => val?.length === 0,
        then: yup
          .string()
          .matches(
            /^[a-zA-Z0-9]+$/,
            "This field cannot contain white space and special character"
          ),
      }),
      // start_time: yup.string().required("Start time is required").nullable(),
      // end_time: yup.string().required("End time is required").nullable(),
    //   start_time: yup.string().test(
    //     "start_time_test",
    //     "Start time must be lower than end time",
    //     value => {
    //       console.log("hell", value, this);
    //       return false;
    //     }
    //   ),
    //   end_time: yup.string()
    // });
    // start_time: yup.string().required("start time cannot be empty"),
    // end_time: yup
    //   .string()
    //   .required("end time cannot be empty")
    //   .test("is-greater", "end time should be greater", function(value) {
    //     const { start } = this.parent;
    //     return moment(value, "HH:mm").isSameOrAfter(moment(start, "HH:mm"));
    //   })
    
});
export const userRoleSchema = yup.object().shape({
  user_type: yup
    .string()
    .required("User role is required")
    .trim()
    .when({
      is: (val) => val?.length === 0,
      then: yup
        .string()
        .matches(
          /^[a-zA-Z0-9]+$/,
          "This field cannot contain white space and special character"
        ),
    }),
});

export const changeUserCommunitySchema = yup.object().shape({
  community_id: yup
    .string()
    .required("Community is required")
    .trim()
    .when({
      is: (val) => val?.length === 0,
      then: yup
        .string()
        .matches(
          /^[a-zA-Z0-9]+$/,
          "This field cannot contain white space and special character"
        ),
    }),
});
export const communityCardSchemaNew = yup.object().shape({
  name: yup.string().required("Card name is required"),
  expiry_date: yup.string().required("Expiry date is required").nullable(),
  card_type: yup.string().required("Card type is required"),
});

export const communityCardSchema = yup.object().shape({
  name: yup.string().required("Card name is required"),
  expiry_date: yup.string().required("Expiry date is required").nullable(),
  card_type: yup.string().required("Card type is required"),
  description: yup.string().required("Description is required"),
});
export const ticketSchema = yup.object().shape({
  name: yup.string().required("Ticket name is required"),
});
export const JourneySchema = yup.object().shape({
  title: yup.string().required("Title is required")
  .matches(
    /[A-Z]/,
    "This field cannot contain lowercase,number,special character and white space."
  ),
 
  url: yup.string().required("Route is required"),

});
export const JourneyRouteSchema = yup.object().shape({
  title: yup.string().required("Title is required")
  .matches(
    /[A-Z]/,
    "This field cannot contain lowercase,number,special character and white space."
  ),
 

});
export const CountrySchema = yup.object().shape({
  country_name: yup.string().required("Country name is required")
      .matches(
        /[A-Z]/,
        "This field cannot contain lowercase,number,special character and white space."
      ),
     
      country_sort_name: yup.string().required("Country short name is required")
      .matches(
        /[A-Z]/,
        "This field cannot contain lowercase,number,special character and white space."
      ), 
  country_code:yup.string()
      .matches(
        /^[0-9\b]+$/,
        "This field can contain only numbers."
      ),

});
export const deviceSchema = yup.object().shape({
  platform: yup.string().required("Platform is required"),
});
