import React from "react";
import { Row, Spin, Table, Button, Col, message } from "antd";
import "../../FindThePeoplePage/FindOtherPeople.css";
// import {EditOutlined} from "@ant-design/icons";
import "./Admin.css";

import { useDispatch } from "react-redux";
import {
  getCommunitiesNative,
  updateCommunitiesNativeAction,
} from "../../Actions/communitiesNativeAction";


const Nativetable = (props) => {
  const {
    setEditNativeId,
    setIsEditNativeModalVisible,
    allCommunitiesNative,
    isCommunitiesNativeLoading,
  } = props;


  const columns = [
    {
      title: `$ { Native (${allCommunitiesNative?.length})}`,
      dataIndex: "name",
      width: "80%",
      render: (name, allNativesList) => {
        return allNativesList.published ? (
          name
        ) : (
          <div className="hidedRow">{name}</div>
        );
      },
    },
    {
      dataIndex: "id",
      render: (id, allNatives) => (
        <NativeActionComponent
          id={id}
          setEditNativeId={setEditNativeId}
          setIsEditNativeModalVisible={setIsEditNativeModalVisible}
          allNatives={allNatives}
        />
     
        ),
      width: "15em",
    },
  ];
  return (
    <div>
      <Spin spinning={isCommunitiesNativeLoading} size="large">
        <div className="AdminTable">
          <Table
            columns={columns}
            dataSource={allCommunitiesNative}
            size="middle"
            className="table"
          />
        </div>
      </Spin>
    </div>
  );
};

export default Nativetable;

const NativeActionComponent = (props) => {
  const { id, setEditNativeId, setIsEditNativeModalVisible, allNatives } =
    props;
  const dispatch = useDispatch();

  let UnpublishData = {};
  let PublishData = {};
  const handleEditCommunityNative = (Id) => {
    setEditNativeId(Id);
    setIsEditNativeModalVisible(true);
  };
  const handlePublishEducationMethod = (EditId) => {
    PublishData.id = EditId;
    PublishData.published = true;
    dispatch(
      updateCommunitiesNativeAction(PublishData, successUpdatePublishMethod)
    );
  };
  const handleUnpublishEducationMethod = (EditId) => {
    UnpublishData.id = EditId;

    UnpublishData.published = false;
    dispatch(
      updateCommunitiesNativeAction(UnpublishData, successUpdateUnpublishMethod)
    );
  };

  const successUpdateUnpublishMethod = () => {
    setTimeout(() => {
      message.success("Your Native Unpublished Successfully");
    }, 1000);
    dispatch(getCommunitiesNative(process.env.REACT_APP_COMMUNITY_ID));
  };
  const successUpdatePublishMethod = () => {
    setTimeout(() => {
      message.success("Your Native Published Successfully");
    }, 1000);
    dispatch(getCommunitiesNative(process.env.REACT_APP_COMMUNITY_ID));
  };
  return (
    <Row>
      <Col span={10}>
        <Button
          onClick={() => handleEditCommunityNative(id)}
          className="editDesignationButton"
          type="primary"
        >
          Edit
        </Button>
      </Col>
      <Col span={10}>
        {allNatives?.published ? (
          <Button
            type="primary"
            className="unPublishButton"
            onClick={() => handleUnpublishEducationMethod(id)}
          >
            Unpublish
          </Button>
        ) : (
          <Button
            type="primary"
            className="publishButton"
            onClick={() => handlePublishEducationMethod(id)}
          >
            Publish
          </Button>
        )}
      </Col>
    </Row>
  );
};
