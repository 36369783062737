import { Row, Col, Button } from "antd";
import React, { useState } from "react";
// import { useForm } from "react-hook-form";
// import { yupResolver } from "@hookform/resolvers/yup";
// import * as yup from "yup";
import { Tabs } from "antd";
// import { useBreakpoints } from "react-responsive-hooks";
import {useBreakpoints} from "../../Screens/CommonLogics/ResponsiveBreakPoints"

import "../../Screens/Admin/Admin.css";
import BusinessProfileModalForm from "./BusinessProfileModalForm";
import BusinessProfileDetails from "./BusinessProfileDetails";
import ViewBusinessProfile from "../BusinessProfiles/ViewBusinessProfile";
import JobProfileDetails from "../JobProfile/JobProfileDetails";
import ViewJobProfile from "../JobProfile/ViewJobProfile";
import JobProfileModalForm from "../JobProfile/JobProfileModalForm";
// const schema = yup.object().shape({});
const BusinessProfile = () => {
  // const { IsMD, IsSM, IsXS, IsLG, IsXL, IsXXL } = useBreakpoints();

  const [visible, setVisible] = useState(false);
  const [viewBusinessProfileId, setViewBusinessProfileId] = useState("");
  const [editId, setEditId] = useState("");
  const [selectedUserId, setSelectedUserId] = useState("");
  // const {} = useForm({
  //   resolver: yupResolver(schema),
  //   mode: "onSubmit",
  // });
  const { TabPane } = Tabs;
  const [tabKey, setTabkey] = useState("1");
  // const { IsXS } = useBreakpoints();
  const onTabClick = (key) => {
    setTabkey(key);
  };
  // const handleCancel = () => {
  //   setVisible(false);
  //   setEditId("");
  //   setSelectedUserId("")

  // };
  const height = window.innerHeight - 64;
  return (
    // <div className="div">
    //   <Row justify="end" style={{ padding: "10px 20px" }}>
    //     <Col span={24}></Col>
    //     <Col>
    //       {!viewBusinessProfileId && (
    //         <Button
    //           type="primary"
    //           onClick={() => setVisible(true)}
    //           className="button-matrimonialProfile"
    //         >
    //           {t("btn.33")}
    //         </Button>
    //       )}
    //       <BusinessProfileModalForm
    //         visible={visible}
    //         setVisible={setVisible}
    //         editId={editId}
    //         setEditId={setEditId}
    //         handleCancel={handleCancel}
    //       />
    //     </Col>
    //   </Row>
    //   <Row style={{ padding: 20 }}>
    //     <Col span={24}>
    //       {viewBusinessProfileId ? (
    //         <BusinessProfileDetails
    //           editId={editId}
    //           setEditId={setEditId}
    //           visible={visible}
    //           setVisible={setVisible}
    //           viewBusinessProfileId={viewBusinessProfileId}
    //           setViewBusinessProfileId={setViewBusinessProfileId}
    //         />
    //       ) : (
    //         <ViewBusinessProfile
    //           editId={editId}
    //           setEditId={setEditId}
    //           visible={visible}
    //           setVisible={setVisible}
    //           viewBusinessProfileId={viewBusinessProfileId}
    //           setViewBusinessProfileId={setViewBusinessProfileId}
    //         />
    //       )}
    //     </Col>
    //   </Row>
    // </div>

    <div
      style={{
        height: height,
        overflow: "hidden",
        overflowY: "scroll",
        backgroundColor: "#FFFFFF",
        // margin: "10px",
      }}
     
     
     
      className="customLayout"
    >
      <Row justify="space-between" 
      // style={{ padding: "24px" }}
      >
        <Col>
          {!viewBusinessProfileId && (
            <div className="BusinessTabs">
              <Tabs
                defaultActiveKey="1"
                onTabClick={onTabClick}
                animated={false}
                renderTabBar={(props, DefaultTabBar) => (
                  <DefaultTabBar {...props} className="cMTabsBckground">
                    {(node) => (
                      <div key={node.key} index={node.key}>
                        {node}
                      </div>
                    )}
                  </DefaultTabBar>
                )}
              >
                <TabPane
                  tab={
                    <Button
                      className={
                        tabKey === "1" ? "selectedButton" : "unselectedButton"
                      }
                    >
                      Business Profile
                    </Button>
                  }
                  key="1"
                ></TabPane>

                <TabPane
                  tab={
                    <Button
                      className={
                        tabKey === "2" ? "selectedButton" : "unselectedButton"
                      }
                    >
                      Job Profile
                    </Button>
                  }
                  key="2"
                ></TabPane>
              </Tabs>
            </div>
          )}
        </Col>
        <Col style={{ textAlign: "end" }}>
          {tabKey === "1" ? (
            <>
              {!viewBusinessProfileId && (
                <Button
                  onClick={() => setVisible(true)}
                  className="button-search"
                  type="primary"
                  style={{ width: "100%" }}
                >
                 Add Business Profile
                </Button>
              )}
              <BusinessProfileModalForm
                visible={visible}
                setVisible={setVisible}
                editId={editId}
                setEditId={setEditId}
                // handleCancel={handleCancel}
                selectedUserId={selectedUserId}
                setSelectedUserId={setSelectedUserId}
              />
            </>
          ) : (
            <>
              {!viewBusinessProfileId && (
                <Button
                  type="primary"
                  onClick={() => setVisible(true)}
                  className="button-matrimonialProfile"
                >
                 Add Job Profile
                </Button>
              )}
              <JobProfileModalForm
                visible={visible}
                setVisible={setVisible}
                editId={editId}
                setEditId={setEditId}
                // handleCancel={handleCancel}
                selectedUserId={selectedUserId}
                setSelectedUserId={setSelectedUserId}
              />
            </>
          )}
        </Col>
      </Row>
      <Row 
      // style={{ padding: 20 }}
      >
        <Col span={24}>
          {tabKey === "1" ? (
            viewBusinessProfileId ? (
              <BusinessProfileDetails
                editId={editId}
                setEditId={setEditId}
                visible={visible}
                setVisible={setVisible}
                viewBusinessProfileId={viewBusinessProfileId}
                setViewBusinessProfileId={setViewBusinessProfileId}
              />
            ) : (
              <ViewBusinessProfile
                editId={editId}
                setEditId={setEditId}
                visible={visible}
                setVisible={setVisible}
                viewBusinessProfileId={viewBusinessProfileId}
                setViewBusinessProfileId={setViewBusinessProfileId}
              />
            )
          ) : null}
          {tabKey === "2" ? (
            viewBusinessProfileId ? (
              <JobProfileDetails
                editId={editId}
                setEditId={setEditId}
                visible={visible}
                setVisible={setVisible}
                viewBusinessProfileId={viewBusinessProfileId}
                setViewBusinessProfileId={setViewBusinessProfileId}
              />
            ) : (
              <ViewJobProfile
                editId={editId}
                setEditId={setEditId}
                visible={visible}
                setVisible={setVisible}
                viewBusinessProfileId={viewBusinessProfileId}
                setViewBusinessProfileId={setViewBusinessProfileId}
              />
            )
          ) : null}
        </Col>
      </Row>
    </div>
  );
};
export default BusinessProfile;
