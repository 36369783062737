import React from "react";
import { Row, Col, Typography, Avatar } from "antd";
import moment from "moment";
const MemberCard = (props) => {
  const { member, familyId, onClickMethod } = props;
  const { id: memberId, user } = member;

  const backgroundColor = "#" + Math.floor(Math.random() * 1677).toString(14);

  return (
    <Col
      xl={12}
      xs={24}
      md={12}
      lg={24}
      sm={12}
      onClick={() => onClickMethod(memberId, familyId)}
    >
      <Row gutter={[8, 0]} style={{ cursor: "pointer", 
      marginTop: "10px" 
      }}>
        <Col>
          <Avatar
            size={40}
            style={{
              cursor: "pointer",
              backgroundColor: !user?.image && backgroundColor,
            }}
            src={user?.image}
          >
            {user.first_name?.[0]}
          </Avatar>
        </Col>
        <Col xs={18} xl={18}>
          <Row gutter={[0, 5]}>
            <Col xs={24}>
              <Typography className="memberName">
                {user.first_name}
                {user.gender === null ? "" : "("}
                {user.gender === null ? "" : user.gender === "Male" ? "M" : "F"}
                {user.gender === null ? "" : ")"}
              </Typography>
            </Col>
            <Col xs={24}>
              <Typography className="memberStyle">
                {user?.birth_year === null





? "Edit your data"
                  : moment(user.birth_year).fromNow("years old")}{" "}
                {user?.birth_year !== null && "old"}
              </Typography>
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};
export default React.memo(MemberCard);
