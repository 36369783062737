import React, { useEffect, useState } from "react";
import { Button, Row, Col, Typography, Spin, Empty } from "antd";
import { getEvents } from "../Actions/eventsAction";
import { useDispatch, useSelector } from "react-redux";
import EventsEmptyScreen from "../assets/EventsEmptyScreen.svg";
import "./Events.css";
import moment from "moment";
import EventsModal from "./EventsModal";
import EventCard from "./EventCard";
import "../Screens/TrusteeView/Trusteecard.css";
import "../Screens/AddFamilymember/Addfamily.css";
import "./../FindThePeoplePage/FindOtherPeople.css";

import { history } from "../Utils/history";
import { getCommunityById } from "../Actions/communitiesAction";

const Event = () => {
  const dispatch = useDispatch();
  const {
    allEvents,
    isCreateEventsLoading,
    isGetEventsLoading,
    isUpdateEventsLoading,
    setCommunityId,
    currentUser,
    community,
  } = useSelector((state) => {
    const {
      allEvents,
      isCreateEventsLoading,
      isGetEventsLoading,
      isUpdateEventsLoading,
    } = state.events;
    const { setCommunityId } = state.communities;
    const { currentUser } = state.authentication;
    const { community } = state.communities;

    return {
      allEvents,
      isCreateEventsLoading,
      isGetEventsLoading,
      isUpdateEventsLoading,
      setCommunityId,
      currentUser,
  
      community,
    };
  });

  const isEventsVisibleByAdmin = community?.show_event;
  const redirectMethod = () => {
    history.push("/addfamily");
  };
  useEffect(() => {
    dispatch(getEvents(process.env.REACT_APP_COMMUNITY_ID));
    dispatch(getCommunityById(process.env.REACT_APP_COMMUNITY_ID));
    if (isEventsVisibleByAdmin === false) {
      redirectMethod();
    }
  }, [dispatch, isEventsVisibleByAdmin]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isUploadImageVisible, setIsUploadImageVisible] = useState(true);
  const openCreateEventModal = () => {
    setIsModalVisible(true);
  };
 
 
  const height = window.innerHeight - 64;
  
  const { users_role , users_types} = currentUser || {};
  const isUserTrustee = users_types.includes("trustee")
  const isUserAdmin = users_types.includes("admin")
  return (
    <>
      <Spin spinning={isGetEventsLoading} size="large">
        <div
          style={{
            height: height,
            overflow: "hidden",
            overflowY: "scroll",
            backgroundColor: "#FFFFFF",
            // margin: "10px",
          }}
          className="customLayout"
        >
          <Row 
          // style={{ padding: "20px" }}
          >
            <Col span={24}>
              <Row gutter={[0, { xs: 20 }]} justify="space-between">
                <Col>
                  <Typography className="firstrowevents">
                  Events&nbsp;({allEvents?.length})
                  </Typography>
                </Col>
                {/* <Col xl={2} xs={0} lg={4} md={18}></Col> */}
                <Col
                // xl={3}
                >
                  {(isUserTrustee ||
                    isUserAdmin) && (
                    <Button
                      type="primary"
                      onClick={() => openCreateEventModal()}
                      style={{ borderRadius: "4px", width: "100%" }}
                      className="button-search"
                    >
                     Create New Event
                    </Button>
                  )}
                  <EventsModal
                    isModalVisible={isModalVisible}
                    setIsModalVisible={setIsModalVisible}
                    isCreateEventsLoading={isCreateEventsLoading}
                    setCommunityId={setCommunityId}
                    isUploadImageVisible={isUploadImageVisible}
                    setIsUploadImageVisible={setIsUploadImageVisible}
                  ></EventsModal>
                </Col>
              </Row>
            </Col>
            {allEvents <= 0 ? (
              <Col span={24}>
                <Row
                  justify="center"
                  gutter={[0, 44]}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "absolute",
                    width: "100%",
                    height: "70vh",
                    // margin: "auto",
                  }}
                >
                  <Empty
                    description={
                      <Typography className="empty-details">
                       No trustees found yet
                      </Typography>
                    }
                    image={EventsEmptyScreen}
                    imageStyle={{
                      height: 38,
                    }}
                  />
                </Row>
              </Col>
            ) : (
              <Col xl={23} md={20}>
                <Row>
                  {allEvents?.map((val, i) => {
                    const { event_datetime } = val;
                    const eventId = val.id;
                    const date = event_datetime?.toString().slice(0, 10);
                    const time = event_datetime?.toString().slice(10, 15);
                    const day = moment(date).format("dddd");

                    const dateCustomFormat =
                      moment(date).format("Do MMMM YYYY");

                    const inNextDays = moment(date).fromNow(
                      new Date().toTimeString()
                    );
                    const currentInNextDays =
                      inNextDays === new Date().toLocaleDateString();
                    const amPm = event_datetime?.toString().slice(15, 18);
                    const updateDateTime = moment(date).format("l");
                    const currentDate =
                      dateCustomFormat === moment().format("Do MMMM YYYY");
                    const startDate = moment(date).format("YYYY-MM-DD");
                    const endDate = moment().format("YYYY-MM-DD");
                    const difference = moment(startDate).diff(endDate, "days");
                    const example = updateDateTime + time + amPm;
                    const dateExample1 = date + time + amPm;

                    return (
                      // difference >= 0 &&
                     
                     <Col xl={24} key={i}>
                        <EventCard
                          key={i}
                          eventdate={date}
                          eventname={val.event_name}
                          eventlocation={val.event_location}
                          eventdetails={val.event_detail}
                          cityname={val.city_name}
                          dateCustomFormat={dateCustomFormat}
                          time={time}
                          inNextDays={inNextDays}
                          currentInNextDays={currentInNextDays}
                          eventId={eventId}
                          amPm={amPm}
                          isCreateEventsLoading={isCreateEventsLoading}
                          isUpdateEventsLoading={isUpdateEventsLoading}
                          day={day}
                          event={val}
                          updateDateTime={example}
                          currentDate={currentDate}
                          setCommunityId={setCommunityId}
                          difference={difference}
                          dateExample1={dateExample1}
                          users_role={users_role}
                          users_types={users_types}
                       ></EventCard>
                      </Col>
                    );
                  })}
                </Row>
              </Col>
            )}
          </Row>
        </div>
      </Spin>
    </>
  );
};
export default Event;
