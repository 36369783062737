import React, { useEffect, useState } from "react";
import { Row, Col, Spin, message } from "antd";
import _ from "lodash";
import {
  getCommunityMeetings,
  updateCommunityMeeting,
  deleteCommunityMeeting,
  updatePublishCommunityMeeting,
} from "../../../Actions/communityMeetingsAction";
import { useDispatch, useSelector } from "react-redux";
import CustomTable from "../../ReuseableComponents/CustomTable/CustomTable";
import {
  ChangeStatusColumnAction,
  DeleteColumnAction,
  EditColumnAction,
} from "../../ReuseableComponents/CustomTable/CustomTableColumnActions";
const MeetingTable = (props) => {
  const { setEditId, setIsModalVisible, currentPage } = props;

  const dispatch = useDispatch();
  let UnpublishData = {};
  let PublishData = {};
  const {
    setCommunityId,
    isSearchUserLoading,
    allMeetings,
    isCreateCommunityMeetingLoading,
    isEditCommunityMeetingLoading,
    isCommunityMeetingsLoading,
  } = useSelector((state) => {
    const { currentUser } = state.authentication;
    const { community, allCommunities, setCommunityId } = state.communities;
    const { searchUsers, isSearchUserLoading } = state.searchUser;
    const {
      allMeetings,
      isCreateCommunityMeetingLoading,
      isEditCommunityMeetingLoading,
      isCommunityMeetingsLoading,
    } = state.communityMeetings;
    return {
      isSearchUserLoading,
      currentUser,
      community,
      allCommunities,
      setCommunityId,
      allMeetings,
      searchUsers,
      isCreateCommunityMeetingLoading,
      isEditCommunityMeetingLoading,
      isCommunityMeetingsLoading,
    };
  });

  const { community_meetings } = allMeetings;

  const handleEdit = (id) => {
    setEditId(id);
    setIsModalVisible(true);
  };

  const handleDeleteMeeting = (meetingId) => {
    dispatch(
      deleteCommunityMeeting(
        meetingId,
        successDelete
        // failureDelete
      )
    );
  };
  const successDelete = () => {
    dispatch(
      getCommunityMeetings(
        `api/community_meetings?community_id=${setCommunityId}&page=${currentPage}`
      )
    );
    setTimeout(() => {
      message.success("Your Meeting Deleted Successfully");
    }, 1000);
  };
  const failureDelete = () => {
    setTimeout(() => {
      message.error("Something went wrong.");
    }, 1000);
  };

  const handlePublishCommunityMethod = (EditId) => {
    PublishData.id = EditId;
    PublishData.published = false;
    dispatch(
      updateCommunityMeeting(
        PublishData,
        successUpdatePublishMethod
        // faliureDelete
      )
    );
  };
  const handleUnpublishCommunityMethod = (EditId) => {
    UnpublishData.id = EditId;
    UnpublishData.published = true;
    dispatch(
      updateCommunityMeeting(
        UnpublishData,
        successUpdateUnpublishMethod
        // faliureDelete
      )
    );
  };
  const successUpdateUnpublishMethod = () => {
    setTimeout(() => {
      message.success("Your Community Published Successfully");
    }, 1000);
    dispatch(
      getCommunityMeetings(
        `api/community_meetings?community_id=${setCommunityId}&page=${currentPage}`
      )
    );
    // dispatch(getCommunities());
  };

  const successUpdatePublishMethod = () => {
    setTimeout(() => {
      message.success("Your Community Unpublished Successfully");
    }, 1000);
    dispatch(
      getCommunityMeetings(
        `api/community_meetings?community_id=${setCommunityId}&page=${currentPage}`
      )
    );
    // dispatch(getCommunities());
  };
  const faliureDelete = () => {
    setTimeout(() => {
      message.error("Something went wrong.");
    }, 1000);
  };

  const handlePublish = (id) => {
    dispatch(updatePublishCommunityMeeting(id, successPublishMethod));
  };
  const successPublishMethod = () => {
    setTimeout(() => {
      message.success("Your community meeting published successfully");
    }, 1000);
    dispatch(
      getCommunityMeetings(
        `api/community_meetings?community_id=${setCommunityId}&page=${currentPage}`
      )
    );
  };

  const meetingColumns = [
    {
      title: "Title",
      dataIndex: "title",
      width: "20%",
      sorter: (a, b) => a.title.localeCompare(b.title),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Description",
      dataIndex: "description",
      width: "20%",
      sorter: (a, b) => a.description.localeCompare(b.description),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Place",
      dataIndex: "place",
      width: "20%",
      sorter: (a, b) => a.place.localeCompare(b.place),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Meeting Date",
      dataIndex: "meeting_date",
      width: "20%",
      sorter: (a, b) => a.meeting_date.localeCompare(b.meeting_date),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Action",
      dataIndex: "id",
      width: "20%",
      render: (id, { published }) => {
        return (
          <Row>
            <Col>
              <EditColumnAction id={id} customMethod={handleEdit} />
            </Col>
            {!published && (
              <Col>
                <ChangeStatusColumnAction
                  id={id}
                  status={published}
                  type="PublishUnpublish"
                  actionArray={[updatePublishCommunityMeeting]}
                  customMethod={successPublishMethod}
                />
              </Col>
            )}
            <Col>
              <DeleteColumnAction
                id={id}
                actionArray={[deleteCommunityMeeting]}
                customMethod={successDelete}
              />
            </Col>
          </Row>
        );
      },
    },
  ];
  return (
    <Spin spinning={isCommunityMeetingsLoading}>
      <Row gutter={[32, 32]}>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          {/* <div>
            {allMeetings && total_length > 0 ? (
              <Row gutter={[32, 32]}>
                {community_meetings?.map((val, i) => {
                  return (
                    <Col xl={8} lg={8} md={12} sm={24} xs={24} key={i}>
                      <Card className="card-community">
                        <Row>
                          <Col xl={14} lg={14} md={14} sm={24} xs={24}>
                            <Typography
                              className="community-Title"
                              onClick={() => handleOpenCommunity(val)}
                            >
                              {val.title}
                            </Typography>
                          </Col>
                          <Col
                            xl={10}
                            lg={10}
                            md={10}
                            sm={24}
                            xs={24}
                            style={{ textAlign: "end" }}
                          >
                            <Tooltip title={t("btn.27")}>
                              <Button
                                icon={<img src={Edit} />}
                                type="text"
                                onClick={() => handleEdit(val.id, val)}
                              />
                            </Tooltip>
                            <Tooltip title={t("btn.28")}>
                              <Popconfirm
                                title={t("btn.29")}
                                onConfirm={() => handleDeleteMeeting(val.id)}
                                okText={t("btn.28")}
                                cancelText={t("btn.19")}
                              >
                                <Button
                                  icon={<img src={Delete} />}
                                  type="text"
                                />
                              </Popconfirm>
                            </Tooltip>

                            {record && val.published === true ? (
                              <Button
                                className="publishButton"
                                icon={<img src={PublishedIcon} />}
                                disabled={val.published ? true : false}
                              ></Button>
                            ) : (
                              <Button
                                className="UnpublishButton"
                                icon={<img src={PublishedIcon} />}
                                onClick={() => handlePublish(val.id)}
                                disabled={val.published ? true : false}
                              ></Button>
                            )}
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  );
                })}
              </Row>
            ) : (
              <Row justify="center" style={{ paddingTop: "150px" }}>
                <Col>
                  <Empty
                    image={DesignationEmptyScreen}
                    imageStyle={{
                      height: 38,
                    }}
                    description={
                      <span className="empty-details">No Communities yet</span>
                    }
                  ></Empty>
                </Col>
              </Row>
            )}
            {allMeetings && total_length > 10 && (
              <Row justify="end" gutter={[0, 24]}>
                <Col>
                  <Pagination
                    responsive={true}
                    size="large"
                    current={currentPage}
                    onChange={(e) => onChangeCurrentPage(e)}
                    total={total_length}
                  />
                </Col>
                <Col span={24}></Col>
              </Row>
            )}
          </div> */}
          <CustomTable data={community_meetings} columns={meetingColumns} />
        </Col>
      </Row>
    </Spin>
  );
};

export default MeetingTable;
