import React from "react";
import { Row, Spin, Table, Col, Button, message } from "antd";
import "../../FindThePeoplePage/FindOtherPeople.css";
import {
  getEducationsAction,
  updateEducationAction,
} from "../../Actions/educationAction";
import "./Admin.css";

import { useDispatch } from "react-redux";

const EducationTable = (props) => {

  const {
    allEducations,
    isEducationsLoading,
    setIsEditEducationModalVisible,
    setEditEducationId,
  } = props;
  const columns = [
    {
      title: `${"Education"} (${allEducations?.length})`,

      dataIndex: "name",
      width: "80%",

      render: (name, allEducationsList) => {
        return allEducationsList.published ? (
          name
        ) : (
          <div className="hidedRow">{name}</div>
        );
      },
    },

    {
      dataIndex: "id",
      render: (id, allEducationList) => (
        <ActionComponent
          id={id}
          setEditEducationId={setEditEducationId}
          setIsEditEducationModalVisible={setIsEditEducationModalVisible}
          allEducationList={allEducationList}
        />
    
        ),
      width: "15em",
    },
  ];

  return (
    <Spin spinning={isEducationsLoading} size="large">
      <div className="AdminTable">
        <Table
          columns={columns}
          dataSource={allEducations}
          size="middle"
          className="table"
        />
      </div>
    </Spin>
  );
};

export default EducationTable;

const ActionComponent = (props) => {
  const {
    id,
    setEditEducationId,
    setIsEditEducationModalVisible,
    allEducationList,
  } = props;
  const dispatch = useDispatch();
  let UnpublishData = {};
  let PublishData = {};
  const openEditEducationMethod = (EditId) => {
    setEditEducationId(EditId);
    setIsEditEducationModalVisible(true);
  };
  const handlePublishEducationMethod = (EditId) => {
    PublishData.id = EditId;
    PublishData.published = true;
    dispatch(updateEducationAction(PublishData, successUpdatePublishMethod));
  };
  const handleUnpublishEducationMethod = (EditId) => {
    UnpublishData.id = EditId;
    UnpublishData.published = false;
    dispatch(
      updateEducationAction(UnpublishData, successUpdateUnpublishMethod)
    );
  };

  const successUpdateUnpublishMethod = () => {
    setTimeout(() => {
      message.success("Your Education Unpublished Successfully");
    }, 1000);
    dispatch(getEducationsAction());
  };
  const successUpdatePublishMethod = () => {
    setTimeout(() => {
      message.success("Your Education Published Successfully");
    }, 1000);
    dispatch(getEducationsAction());
  };
  return (
    <Row>
      <Col span={10}>
        <Button
          type="primary"
          className="editDesignationButton"
          onClick={() => openEditEducationMethod(id)}
        >
          Edit
        </Button>
      </Col>
      <Col>
        {allEducationList?.published ? (
          <Button
            type="primary"
            className="unPublishButton"
            onClick={() => handleUnpublishEducationMethod(id)}
          >
           Unpublish
          </Button>
        ) : (
          <Button
            type="primary"
            className="publishButton"
            onClick={() => handlePublishEducationMethod(id)}
          >
            Publish
          </Button>
        )}
      </Col>
    </Row>
  );
};
