export default {
  GET_COMMUNITIES_REQUEST: "GET_COMMUNITIES_REQUEST",
  GET_COMMUNITIES_SUCCESS: "GET_COMMUNITIES_SUCCESS",
  GET_COMMUNITIES_FAILURE: "GET_COMMUNITIES_FAILURE",

  UPDATE_PUBLISH_COMMUNITY_REQUEST: "UPDATE_PUBLISH_COMMUNITY_REQUEST",
  UPDATE_PUBLISH_COMMUNITY_SUCCESS: "UPDATE_PUBLISH_COMMUNITY_SUCCESS",
  UPDATE_PUBLISH_COMMUNITY_FAILURE: "UPDATE_PUBLISH_COMMUNITY_FAILURE",

  GET_COMMUNITY_MEETINGS_REQUEST: "GET_COMMUNITY_MEETINGS_REQUEST",
  GET_COMMUNITY_MEETINGS_SUCCESS: "GET_COMMUNITY_MEETINGS_SUCCESS",
  GET_COMMUNITY_MEETINGS_FAILURE: "GET_COMMUNITY_MEETINGS_FAILURE",

  CREATE_COMMUNITY_MEETING_REQUEST: "CREATE_COMMUNITY_MEETING_REQUEST",
  CREATE_COMMUNITY_MEETING_SUCCESS: "CREATE_COMMUNITY_MEETING_SUCCESS",
  CREATE_COMMUNITY_MEETING_FAILURE: "CREATE_COMMUNITY_MEETING_FAILURE",

  UPDATE_PUBLISH_COMMUNITY_MEETING_REQUEST:
    "UPDATE_PUBLISH_COMMUNITY_MEETING_REQUEST",
  UPDATE_PUBLISH_COMMUNITY_MEETING_SUCCESS:
    "UPDATE_PUBLISH_COMMUNITY_MEETING_SUCCESS",
  UPDATE_PUBLISH_COMMUNITY_MEETING_FAILURE:
    "UPDATE_PUBLISH_COMMUNITY_MEETING_FAILURE",

  UPDATE_COMMUNITY_MEETING_REQUEST: "UPDATE_COMMUNITY_MEETING_REQUEST",
  UPDATE_COMMUNITY_MEETING_SUCCESS: "UPDATE_COMMUNITY_MEETING_SUCCESS",
  UPDATE_COMMUNITY_MEETING_FAILURE: "UPDATE_COMMUNITY_MEETING_FAILURE",

  DELETE_COMMUNITY_MEETING_REQUEST: "DELETE_COMMUNITY_MEETING_REQUEST",
  DELETE_COMMUNITY_MEETING_SUCCESS: "DELETE_COMMUNITY_MEETING_SUCCESS",
  DELETE_COMMUNITY_MEETING_FAILURE: "DELETE_COMMUNITY_MEETING_FAILURE",

  GET_DOCUMENT_REQUEST: "GET_DOCUMENT_REQUEST",
  GET_DOCUMENT_FAILURE: "GET_DOCUMENT_FAILURE",
  GET_DOCUMENT_SUCCESS: "GET_DOCUMENT_SUCCESS",

  CREATE_DOCUMENT_REQUEST: "CREATE_DOCUMENT_REQUEST",
  CREATE_DOCUMENT_SUCCESS: "CREATE_DOCUMENT_SUCCESS",
  CREATE_DOCUMENT_FAILURE: "CREATE_DOCUMENT_FAILURE",

  DELETE_DOCUMENT_REQUEST: "DELETE_DOCUMENT_REQUEST",
  DELETE_DOCUMENT_SUCCESS: "DELETE_DOCUMENT_SUCCESS",
  DELETE_DOCUMENT_FAILURE: "DELETE_DOCUMENT_FAILURE",

  UPDATE_DOCUMENT_REQUEST: "UPDATE_DOCUMENT_REQUEST",
  UPDATE_DOCUMENT_SUCCESS: "UPDATE_DOCUMENT_SUCCESS",
  UPDATE_DOCUMENT_FAILURE: "UPDATE_DOCUMENT_FAILURE",

  CREATE_FAMILY_REQUEST: "CREATE_FAMILY_REQUEST",
  CREATE_FAMILY_SUCCESS: "CREATE_FAMILY_SUCCESS",
  CREATE_FAMILY_FAILURE: "CREATE_FAMILY_FAILURE",

  GET_FAMILIES_REQUEST: "GET_FAMILIES_REQUEST",
  GET_FAMILIES_SUCCESS: "GET_FAMILIES_SUCCESS",
  GET_FAMILIES_FAILURE: "GET_FAMILIES_FAILURE",

  DELETE_FAMILIES_REQUEST: "DELETE_FAMILIES_REQUEST",
  DELETE_FAMILIES_SUCCESS: "DELETE_FAMILIES_SUCCESS",
  DELETE_FAMILIES_FAILURE: "DELETE_FAMILIES_FAILURE",

  UPDATE_FAMILIES_REQUEST: "UPDATE_FAMILIES_REQUEST",
  UPDATE_FAMILIES_SUCCESS: "UPDATE_FAMILIES_SUCCESS",
  UPDATE_FAMILIES_FAILURE: "UPDATE_FAMILIES_FAILURE",

  CREATE_FAMILY_MEMBER_REQUEST: "CREATE_FAMILY_MEMBER_REQUEST",
  CREATE_FAMILY_MEMBER_SUCCESS: "CREATE_FAMILY_MEMBER_SUCCESS",
  CREATE_FAMILY_MEMBER_FAILURE: "CREATE_FAMILY_MEMBER_FAILURE",

  DELETE_FAMILY_MEMBER_REQUEST: "DELETE_FAMILY_MEMBER_REQUEST",
  DELETE_FAMILY_MEMBER_SUCCESS: "DELETE_FAMILY_MEMBER_SUCCESS",
  DELETE_FAMILY_MEMBER_FAILURE: "DELETE_FAMILY_MEMBER_FAILURE",

  UPDATE_FAMILY_MEMBER_REQUEST: "UPDATE_FAMILY_MEMBER_REQUEST",
  UPDATE_FAMILY_MEMBER_SUCCESS: "UPDATE_FAMILY_MEMBER_SUCCESS",
  UPDATE_FAMILY_MEMBER_FAILURE: "UPDATE_FAMILY_MEMBER_FAILURE",

  GET_MATRIMONIAL_REQUEST: "GET_MATRIMONIAL_REQUEST",
  GET_MATRIMONIAL_SUCCESS: "GET_MATRIMONIAL_SUCCESS",
  GET_MATRIMONIAL_FAILURE: "GET_MATRIMONIAL_FAILURE",

  GET_MATRIMONIAL_SUPER_ADMIN_REQUEST: "GET_MATRIMONIAL_SUPER_ADMIN_REQUEST",
  GET_MATRIMONIAL_SUPER_ADMIN_SUCCESS: "GET_MATRIMONIAL_SUPER_ADMIN_SUCCESS",
  GET_MATRIMONIAL_SUPER_ADMIN_FAILURE: "GET_MATRIMONIAL_SUPER_ADMIN_FAILURE",

  MATRIMONIAL_BY_ID_REQUEST: "MATRIMONIAL_BY_ID_REQUEST",
  MATRIMONIAL_BY_ID_SUCCESS: "MATRIMONIAL_BY_ID_SUCCESS",
  MATRIMONIAL_BY_ID_FAILURE: "MATRIMONIAL_BY_ID_FAILURE",

  CREATE_MATRIMONIAL_REQUEST: "CREATE_MATRIMONIAL_REQUEST",
  CREATE_MATRIMONIAL_SUCCESS: "CREATE_MATRIMONIAL_SUCCESS",
  CREATE_MATRIMONIAL_FAILURE: "CREATE_MATRIMONIAL_FAILURE",

  DELETE_MATRIMONIAL_REQUEST: "DELETE_MATRIMONIAL_REQUEST",
  DELETE_MATRIMONIAL_SUCCESS: "DELETE_MATRIMONIAL_SUCCESS",
  DELETE_MATRIMONIAL_FAILURE: "DELETE_MATRIMONIAL_FAILURE",

  UPDATE_MATRIMONIAL_REQUEST: "UPDATE_MATRIMONIAL_REQUEST",
  UPDATE_MATRIMONIAL_SUCCESS: "UPDATE_MATRIMONIAL_SUCCESS",
  UPDATE_MATRIMONIAL_FAILURE: "UPDATE_MATRIMONIAL_FAILURE",

  GET_BUSINESS_REQUEST: "GET_BUSINESS_REQUEST",
  GET_BUSINESS_SUCCESS: "GET_BUSINESS_SUCCESS",
  GET_BUSINESS_FAILURE: "GET_BUSINESS_FAILURE",

  BUSINESS_BY_ID_REQUEST: "BUSINESS_BY_ID_REQUEST",
  BUSINESS_BY_ID_SUCCESS: "BUSINESS_BY_ID_SUCCESS",
  BUSINESS_BY_ID_FAILURE: "BUSINESS_BY_ID_FAILURE",

  CREATE_BUSINESS_REQUEST: "CREATE_BUSINESS_REQUEST",
  CREATE_BUSINESS_SUCCESS: "CREATE_BUSINESS_SUCCESS",
  CREATE_BUSINESS_FAILURE: "CREATE_BUSINESS_FAILURE",

  DELETE_BUSINESS_REQUEST: "DELETE_BUSINESS_REQUEST",
  DELETE_BUSINESS_SUCCESS: "DELETE_BUSINESS_SUCCESS",
  DELETE_BUSINESS_FAILURE: "DELETE_BUSINESS_FAILURE",

  UPDATE_BUSINESS_REQUEST: "UPDATE_BUSINESS_REQUEST",
  UPDATE_BUSINESS_SUCCESS: "UPDATE_BUSINESS_SUCCESS",
  UPDATE_BUSINESS_FAILURE: "UPDATE_BUSINESS_FAILURE",

  GET_EVENTS_REQUEST: "GET_EVENTS_REQUEST",
  GET_EVENTS_SUCCESS: "GET_EVENTS_SUCCESS",
  GET_EVENTS_FAILURE: "GET_EVENTS_FAILURE",

  CREATE_EVENTS_REQUEST: "CREATE_EVENTS_REQUEST",
  CREATE_EVENTS_SUCCESS: "CREATE_EVENTS_SUCCESS",
  CREATE_EVENTS_FAILURE: "CREATE_EVENTS_FAILURE",

  DELETE_EVENTS_REQUEST: "DELETE_EVENTS_REQUEST",
  DELETE_EVENTS_SUCCESS: "DELETE_EVENTS_SUCCESS",
  DELETE_EVENTS_FAILURE: "DELETE_EVENTS_FAILURE",

  UPDATE_EVENTS_REQUEST: "UPDATE_EVENTS_REQUEST",
  UPDATE_EVENTS_SUCCESS: "UPDATE_EVENTS_SUCCESS",
  UPDATE_EVENTS_FAILURE: "UPDATE_EVENTS_FAILURE",

  GET_EVENTS_BY_ID_REQUEST: "GET_EVENTS_BY_ID_REQUEST",
  GET_EVENTS_BY_ID_SUCCESS: "GET_EVENTS_BY_ID_SUCCESS",
  GET_EVENTS_BY_ID_FAILURE: "GET_EVENTS_BY_ID_FAILURE",

  GET_SELECTED_EVENTS_ID: "GET_SELECTED_EVENTS_ID",

  GET_TRUSTEES_REQUEST: "GET_TRUSTEES_REQUEST",
  GET_TRUSTEES_SUCCESS: "GET_TRUSTEES_SUCCESS",
  GET_TRUSTEES_FAILURE: "GET_TRUSTEES_FAILURE",

  CREATE_TRUSTEES_REQUEST: "CREATE_TRUSTEES_REQUEST",
  CREATE_TRUSTEES_SUCCESS: "CREATE_TRUSTEES_SUCCESS",
  CREATE_TRUSTEES_FAILURE: "CREATE_TRUSTEES_FAILURE",

  GET_USERS_REQUEST: "GET_USERS_REQUEST",
  GET_USERS_SUCCESS: "GET_USERS_SUCCESS",
  GET_USERS_FAILURE: "GET_USERS_FAILURE",

  DELETE_TRUSTEES_REQUEST: "DELETE_TRUSTEES_REQUEST",
  DELETE_TRUSTEES_SUCCESS: "DELETE_TRUSTEES_SUCCESS",
  DELETE_TRUSTEES_FAILURE: "DELETE_TRUSTEES_FAILURE",

  UPDATE_TRUSTEES_REQUEST: "UPDATE_TRUSTEES_REQUEST",
  UPDATE_TRUSTEES_SUCCESS: "UPDATE_TRUSTEES_SUCCESS",
  UPDATE_TRUSTEES_FAILURE: "UPDATE_TRUSTEES_FAILURE",

  GET_TRUSTEES_BY_ID_REQUEST: "GET_TRUSTEES_BY_ID_REQUEST",
  GET_TRUSTEES_BY_ID_SUCCESS: "GET_TRUSTEES_BY_ID_SUCCESS",
  GET_TRUSTEES_BY_ID_FAILURE: "GET_TRUSTEES_BY_ID_FAILURE",

  GET_SELECTED_TRUSTEES_ID: "GET_SELECTED_TRUSTEES_ID",

  GET_SEARCH_USER_REQUEST: "GET_SEARCH_USER_REQUEST",
  GET_SEARCH_USER_SUCCESS: "GET_SEARCH_USER_SUCCESS",
  GET_SEARCH_USER_FAILURE: "GET_SEARCH_USER_FAILURE",

  GET_SELECTED_COMMUNITY_ID: "GET_SELECTED_COMMUNITY_ID",

  GET_SELECTED_USERS_ID: "GET_SELECTED_USERS_ID",

  GET_SELECTED_EDUCATION_ID: "GET_SELECTED_EDUCATION_ID",

  GET_EDUCATION_BY_ID_REQUEST: "GET_EDUCATION_BY_ID_REQUEST",
  GET_EDUCATION_BY_ID_SUCCESS: "GET_EDUCATION_BY_ID_SUCCESS",
  GET_EDUCATION_BY_ID_FAILURE: "GET_EDUCATION_BY_ID_FAILURE",

  GET_SELECTED_INDUSTRY_ID: "GET_SELECTED_INDUSTRY_ID",

  GET_INDUSTRY_BY_ID_REQUEST: "GET_INDUSTRY_BY_ID_REQUEST",
  GET_INDUSTRY_BY_ID_SUCCESS: "GET_INDUSTRY_BY_ID_SUCCESS",
  GET_INDUSTRY_BY_ID_FAILURE: "GET_INDUSTRY_BY_ID_FAILURE",

  GET_SELECTED_FAMILY_ID: "GET_SELECTED_FAMILY_ID",

  GET_FAMILY_BY_ID_REQUEST: "GET_FAMILY_BY_ID_REQUEST",
  GET_FAMILY_BY_ID_SUCCESS: "GET_FAMILY_BY_ID_SUCCESS",
  GET_FAMILY_BY_ID_FAILURE: "GET_FAMILY_BY_ID_FAILURE",

  GET_COMMUNITIES_NATIVE_REQUEST: "GET_COMMUNITIES_NATIVE_REQUEST",
  GET_COMMUNITIES_NATIVE_SUCCESS: "GET_COMMUNITIES_NATIVE_SUCCESS",
  GET_COMMUNITIES_NATIVE_FAILURE: "GET_COMMUNITIES_NATIVE_FAILURE",

  DELETE_COMMUNITIES_NATIVE_REQUEST: "DELETE_COMMUNITIES_NATIVE_REQUEST",
  DELETE_COMMUNITIES_NATIVE_SUCCESS: "DELETE_COMMUNITIES_NATIVE_SUCCESS",
  DELETE_COMMUNITIES_NATIVE_FAILURE: "DELETE_COMMUNITIES_NATIVE_FAILURE",

  CREATE_FAMILY_ADDRESS_REQUEST: "CREATE_FAMILY_ADDRESS_REQUEST",
  CREATE_FAMILY_ADDRESS_SUCCESS: "CREATE_FAMILY_ADDRESS_SUCCESS",
  CREATE_FAMILY_ADDRESS_FAILURE: "CREATE_FAMILY_ADDRESS_FAILURE",

  GET_FAMILY_ADDRESS_REQUEST: "GET_FAMILY_ADDRESS_REQUEST",
  GET_FAMILY_ADDRESS_SUCCESS: "GET_FAMILY_ADDRESS_SUCCESS",
  GET_FAMILY_ADDRESS_FAILURE: "GET_FAMILY_ADDRESS_FAILURE",

  UPDATE_FAMILY_ADDRESS_REQUEST: "UPDATE_FAMILY_ADDRESS_REQUEST",
  UPDATE_FAMILY_ADDRESS_SUCCESS: "UPDATE_FAMILY_ADDRESS_SUCCESS",
  UPDATE_FAMILY_ADDRESS_FAILURE: "UPDATE_FAMILY_ADDRESS_FAILURE",

  DELETE_FAMILY_ADDRESS_REQUEST: "DELETE_FAMILY_ADDRESS_REQUEST",
  DELETE_FAMILY_ADDRESS_SUCCESS: "DELETE_FAMILY_ADDRESS_SUCCESS",
  DELETE_FAMILY_ADDRESS_FAILURE: "DELETE_FAMILY_ADDRESS_FAILURE",

  LOGIN_USER_REQUEST: "LOGIN_USER_REQUEST",
  LOGIN_USER_SUCCESS: "LOGIN_USER_SUCCESS",
  LOGIN_USER_FAILURE: "LOGIN_USER_FAILURE",

  VERIFY_OTP_REQUEST: "VERIFY_OTP_REQUEST",
  VERIFY_OTP_SUCCESS: "VERIFY_OTP_SUCCESS",
  VERIFY_OTP_FAILURE: "VERIFY_OTP_FAILURE",

  CREATE_USER_REQUEST: "CREATE_USER_REQUEST",
  CREATE_USER_SUCCESS: "CREATE_USER_SUCCESS",
  CREATE_USER_FAILURE: "CREATE_USER_FAILURE",

  SET_CURRENT_USER: "SET_CURRENT_USER",

  REMOVE_CURRENT_USER: "REMOVE_CURRENT_USER",

  UPDATE_USER_REQUEST: "UPDATE_USER_REQUEST",
  UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
  UPDATE_USER_FAILURE: "UPDATE_USER_FAILURE",

  CREATE_COMMUNITIES_NATIVE_REQUEST: "CREATE_COMMUNITIES_NATIVE_REQUEST",
  CREATE_COMMUNITIES_NATIVE_SUCCESS: "CREATE_COMMUNITIES_NATIVE_SUCCESS",
  CREATE_COMMUNITIES_NATIVE_FAILURE: "CREATE_COMMUNITIES_NATIVE_FAILURE",

  GET_DESIGNATIONS_REQUEST: "GET_DESIGNATIONS_REQUEST",
  GET_DESIGNATIONS_SUCCESS: "GET_DESIGNATIONS_SUCCESS",
  GET_DESIGNATIONS_FAILURE: "GET_DESIGNATIONS_FAILURE",

  CREATE_DESIGNATIONS_REQUEST: "CREATE_DESIGNATIONS_REQUEST",
  CREATE_DESIGNATIONS_SUCCESS: "CREATE_DESIGNATIONS_SUCCESS",
  CREATE_DESIGNATIONS_FAILURE: "CREATE_DESIGNATIONS_FAILURE",

  UPDATE_DESIGNATIONS_REQUEST: "UPDATE_DESIGNATIONS_REQUEST",
  UPDATE_DESIGNATIONS_SUCCESS: "UPDATE_DESIGNATIONS_SUCCESS",
  UPDATE_DESIGNATIONS_FAILURE: "UPDATE_DESIGNATIONS_FAILURE",

  GET_DESIGNATIONS_BY_ID_REQUEST: "GET_DESIGNATIONS_BY_ID_REQUEST",
  GET_DESIGNATIONS_BY_ID_SUCCESS: "GET_DESIGNATIONS_BY_ID_SUCCESS",
  GET_DESIGNATIONS_BY_ID_FAILURE: "GET_DESIGNATIONS_BY_ID_FAILURE",

  GET_SELECTED_DESIGNATIONS_ID: "GET_SELECTED_DESIGNATIONS_ID",

  DELETE_USER_FAMILY_REQUEST: "DELETE_USER_FAMILY_REQUEST",
  DELETE_USER_FAMILY_SUCCESS: "DELETE_USER_FAMILY_SUCCESS",
  DELETE_USER_FAMILY_FAILURE: "DELETE_USER_FAMILY_FAILURE",

  GET_SELECTED_COMMUNITY_NATIVE_ID: "GET_SELECTED_COMMUNITY_NATIVE_ID",

  GET_COMMUNITY_BY_ID_REQUEST: "GET_COMMUNITY_BY_ID_REQUEST",
  GET_COMMUNITY_BY_ID_SUCCESS: "GET_COMMUNITY_BY_ID_SUCCESS",
  GET_COMMUNITY_BY_ID_FAILURE: "GET_COMMUNITY_BY_ID_FAILURE",

  EMPTY_COMMUNITY_BY_ID_DATA: "EMPTY_COMMUNITY_BY_ID_DATA",

  UPDATE_COMMUNITIES_NATIVE_REQUEST: "UPDATE_COMMUNITIES_NATIVE_REQUEST",
  UPDATE_COMMUNITIES_NATIVE_SUCCESS: "UPDATE_COMMUNITIES_NATIVE_SUCCESS",
  UPDATE_COMMUNITIES_NATIVE_FAILURE: "UPDATE_COMMUNITIES_NATIVE_FAILURE",

  GET_EDUCATIONS_REQUEST: "GET_EDUCATIONS_REQUEST",
  GET_EDUCATIONS_SUCCESS: "GET_EDUCATIONS_SUCCESS",
  GET_EDUCATIONS_FAILURE: "GET_EDUCATIONS_FAILURE",

  CREATE_EDUCATIONS_REQUEST: "CREATE_EDUCATIONS_REQUEST",
  CREATE_EDUCATIONS_SUCCESS: "CREATE_EDUCATIONS_SUCCESS",
  CREATE_EDUCATIONS_FAILURE: "CREATE_EDUCATIONS_FAILURE",

  UPDATE_EDUCATIONS_REQUEST: "UPDATE_EDUCATIONS_REQUEST",
  UPDATE_EDUCATIONS_SUCCESS: "UPDATE_EDUCATIONS_SUCCESS",
  UPDATE_EDUCATIONS_FAILURE: "UPDATE_EDUCATIONS_FAILURE",

  GET_INDUSTRIES_REQUEST: "GET_INDUSTRIES_REQUEST",
  GET_INDUSTRIES_SUCCESS: "GET_INDUSTRIES_SUCCESS",
  GET_INDUSTRIES_FAILURE: "GET_INDUSTRIES_FAILURE",

  CREATE_INDUSTRIES_REQUEST: "CREATE_INDUSTRIES_REQUEST",
  CREATE_INDUSTRIES_SUCCESS: "CREATE_INDUSTRIES_SUCCESS",
  CREATE_INDUSTRIES_FAILURE: "CREATE_INDUSTRIES_FAILURE",

  DELETE_INDUSTRIES_REQUEST: "DELETE_INDUSTRIES_REQUEST",
  DELETE_INDUSTRIES_SUCCESS: "DELETE_INDUSTRIES_SUCCESS",
  DELETE_INDUSTRIES_FAILURE: "DELETE_INDUSTRIES_FAILURE",

  UPDATE_INDUSTRIES_REQUEST: "UPDATE_INDUSTRIES_REQUEST",
  UPDATE_INDUSTRIES_SUCCESS: "UPDATE_INDUSTRIES_SUCCESS",
  UPDATE_INDUSTRIES_FAILURE: "UPDATE_INDUSTRIES_FAILURE",

  UPDATE_COMMUNITIES_REQUEST: "UPDATE_COMMUNITIES_REQUEST",
  UPDATE_COMMUNITIES_SUCCESS: "UPDATE_COMMUNITIES_SUCCESS",
  UPDATE_COMMUNITIES_FAILURE: "UPDATE_COMMUNITIES_FAILURE",

  DELETE_USER_EDUCATIONS_REQUEST: "DELETE_USER_EDUCATIONS_REQUEST",
  DELETE_USER_EDUCATIONS_SUCCESS: "DELETE_USER_EDUCATIONS_SUCCESS",
  DELETE_USER_EDUCATIONS_FAILURE: "DELETE_USER_EDUCATIONS_FAILURE",

  CREATE_NATIVE_VOLUNTEER_REQUEST: "CREATE_NATIVE_VOLUNTEER_REQUEST",
  CREATE_NATIVE_VOLUNTEER_SUCCESS: "CREATE_NATIVE_VOLUNTEER_SUCCESS",
  CREATE_NATIVE_VOLUNTEER_FAILURE: "CREATE_NATIVE_VOLUNTEER_FAILURE",

  GET_NATIVE_VOLUNTEER_REQUEST: "GET_NATIVE_VOLUNTEER_REQUEST",
  GET_NATIVE_VOLUNTEER_SUCCESS: "GET_NATIVE_VOLUNTEER_SUCCESS",
  GET_NATIVE_VOLUNTEER_FAILURE: "GET_NATIVE_VOLUNTEER_FAILURE",

  DELETE_NATIVE_VOLUNTEER_REQUEST: "DELETE_NATIVE_VOLUNTEER_REQUEST",
  DELETE_NATIVE_VOLUNTEER_SUCCESS: "DELETE_NATIVE_VOLUNTEER_SUCCESS",
  DELETE_NATIVE_VOLUNTEER_FAILURE: "DELETE_NATIVE_VOLUNTEER_FAILURE",

  UPDATE_VERIFIED_BY_VOLUNTEER_REQUEST: "UPDATE_VERIFIED_BY_VOLUNTEER_REQUEST",
  UPDATE_VERIFIED_BY_VOLUNTEER_SUCCESS: "UPDATE_VERIFIED_BY_VOLUNTEER_SUCCESS",
  UPDATE_VERIFIED_BY_VOLUNTEER_FAILURE: "UPDATE_VERIFIED_BY_VOLUNTEER_FAILURE",

  GET_PUBLIC_ACTIVITY_REQUEST: "GET_PUBLIC_ACTIVITY_REQUEST",
  GET_PUBLIC_ACTIVITY_SUCCESS: "GET_PUBLIC_ACTIVITY_SUCCESS",
  GET_PUBLIC_ACTIVITY_FAILURE: "GET_PUBLIC_ACTIVITY_FAILURE",

  CREATE_COMMUNITIES_REQUEST: "CREATE_COMMUNITIES_REQUEST",
  CREATE_COMMUNITIES_SUCCESS: "CREATE_COMMUNITIES_SUCCESS",
  CREATE_COMMUNITIES_FAILURE: "CREATE_COMMUNITIES_FAILURE",

  // UPDATE_COMMUNITIES_REQUEST: "UPDATE_COMMUNITIES_REQUEST",
  // UPDATE_COMMUNITIES_SUCCESS: "UPDATE_COMMUNITIES_SUCCESS",
  // UPDATE_COMMUNITIES_FAILURE: "UPDATE_COMMUNITIES_FAILURE",

  DELETE_COMMUNITIES_REQUEST: "DELETE_COMMUNITIES_REQUEST",
  DELETE_COMMUNITIES_SUCCESS: "DELETE_COMMUNITIES_SUCCESS",
  DELETE_COMMUNITIES_FAILURE: "DELETE_COMMUNITIES_FAILURE",

  GET_FAMILIES_BY_NATIVE_FOR_VOLUNTEER_REQUEST:
    "GET_FAMILIES_BY_NATIVE_FOR_VOLUNTEER_REQUEST",
  GET_FAMILIES_BY_NATIVE_FOR_VOLUNTEER_SUCCESS:
    "GET_FAMILIES_BY_NATIVE_FOR_VOLUNTEER_SUCCESS",
  GET_FAMILIES_BY_NATIVE_FOR_VOLUNTEER_FAILURE:
    "GET_FAMILIES_BY_NATIVE_FOR_VOLUNTEER_FAILURE",

  RESET_APP_DATA: "RESET_APP_DATA",

  CREATE_USERS_REQUEST: "CREATE_USERS_REQUEST",
  CREATE_USERS_SUCCESS: "CREATE_USERS_SUCCESS",
  CREATE_USERS_FAILURE: "CREATE_USERS_FAILURE",

  DELETE_USERS_REQUEST: "DELETE_USERS_REQUEST",
  DELETE_USERS_SUCCESS: "DELETE_USERS_SUCCESS",
  DELETE_USERS_FAILURE: "DELETE_USERS_FAILURE",

  UPDATE_USERS_REQUEST: "UPDATE_USERS_REQUEST",
  UPDATE_USERS_SUCCESS: "UPDATE_USERS_SUCCESS",
  UPDATE_USERS_FAILURE: "UPDATE_USERS_FAILURE",

  CREATE_MESSAGES_REQUEST: "CREATE_MESSAGES_REQUEST",
  CREATE_MESSAGES_SUCCESS: "CREATE_MESSAGES_SUCCESS",
  CREATE_MESSAGES_FAILURE: "CREATE_MESSAGES_FAILURE",

  DELETE_FILE_REQUEST: "DELETE_FILE_REQUEST",
  DELETE_FILE_SUCCESS: "DELETE_FILE_SUCCESS",
  DELETE_FILE_FAILURE: "DELETE_FILE_FAILURE",

  GET_MATRIMONIAL_MATCHES_REQUEST: "GET_MATRIMONIAL_MATCHES_REQUEST",
  GET_MATRIMONIAL_MATCHES_SUCCESS: "GET_MATRIMONIAL_MATCHES_SUCCESS",
  GET_MATRIMONIAL_MATCHES_FAILURE: "GET_MATRIMONIAL_MATCHES_FAILURE",

  UPDATE_PROFILE_STATUS_REQUEST: "UPDATE_PROFILE_STATUS_REQUEST",
  UPDATE_PROFILE_STATUS_SUCCESS: "UPDATE_PROFILE_STATUS_SUCCESS",
  UPDATE_PROFILE_STATUS_FAILURE: "UPDATE_PROFILE_STATUS_FAILURE",

  RESET_MATRIMONIAL_BY_ID_DATA: "RESET_MATRIMONIAL_BY_ID_DATA",

  GET_USER_BY_ID_REQUEST: "GET_USER_BY_ID_REQUEST",
  GET_USER_BY_ID_SUCCESS: "GET_USER_BY_ID_SUCCESS",
  GET_USER_BY_ID_FAILURE: "GET_USER_BY_ID_FAILURE",

  DELETE_USER_REQUEST: "DELETE_USER_REQUEST",
  DELETE_USER_SUCCESS: "DELETE_USER_SUCCESS",
  DELETE_USER_FAILURE: "DELETE_USER_FAILURE",

  UPDATE_VERIFY_USERS_REQUEST: "UPDATE_VERIFY_USERS_REQUEST",
  UPDATE_VERIFY_USERS_SUCCESS: "UPDATE_VERIFY_USERS_SUCCESS",
  UPDATE_VERIFY_USERS_FAILURE: "UPDATE_VERIFY_USERS_FAILURE",

  CREATE_ADD_MEMBER_REQUEST: "CREATE_ADD_MEMBER_REQUEST",
  CREATE_ADD_MEMBER_SUCCESS: "CREATE_ADD_MEMBER_SUCCESS",
  CREATE_ADD_MEMBER_FAILURE: "CREATE_ADD_MEMBER_FAILURE",

  CREATE_USER_ROLE_REQUEST: "CREATE_USER_ROLE_REQUEST",
  CREATE_USER_ROLE_SUCCESS: "CREATE_USER_ROLE_SUCCESS",
  CREATE_USER_ROLE_FAILURE: "CREATE_USER_ROLE_FAILURE",

  UPDATE_USER_COMMUNITY_REQUEST: "UPDATE_USER_COMMUNITY_REQUEST",
  UPDATE_USER_COMMUNITY_SUCCESS: "UPDATE_USER_COMMUNITY_SUCCESS",
  UPDATE_USER_COMMUNITY_FAILURE: "UPDATE_USER_COMMUNITY_FAILURE",

  SET_REDIRECT_PAGE: "SET_REDIRECT_PAGE",

  GET_TICKETS_REQUEST: "GET_TICKETS_REQUEST",
  GET_TICKETS_SUCCESS: "GET_TICKETS_SUCCESS",
  GET_TICKETS_FAILURE: "GET_TICKETS_FAILURE",

  GET_ARCHIVE_TICKETS_REQUEST: "GET_ARCHIVE_TICKETS_REQUEST",
  GET_ARCHIVE_TICKETS_SUCCESS: "GET_ARCHIVE_TICKETS_SUCCESS",
  GET_ARCHIVE_TICKETS_FAILURE: "GET_ARCHIVE_TICKETS_FAILURE",

  CREATE_TICKETS_REQUEST: "CREATE_TICKETS_REQUEST",
  CREATE_TICKETS_SUCCESS: "CREATE_TICKETS_SUCCESS",
  CREATE_TICKETS_FAILURE: "CREATE_TICKETS_FAILURE",

  UPDATE_TICKETS_REQUEST: "UPDATE_TICKETS_REQUEST",
  UPDATE_TICKETS_SUCCESS: "UPDATE_TICKETS_SUCCESS",
  UPDATE_TICKETS_FAILURE: "UPDATE_TICKETS_FAILURE",

  DELETE_TICKETS_REQUEST: "DELETE_TICKETS_REQUEST",
  DELETE_TICKETS_SUCCESS: "DELETE_TICKETS_SUCCESS",
  DELETE_TICKETS_FAILURE: "DELETE_TICKETS_FAILURE",

  GET_TICKET_BY_ID_REQUEST: "GET_TICKET_BY_ID_REQUEST",
  GET_TICKET_BY_ID_SUCCESS: "GET_TICKET_BY_ID_SUCCESS",
  GET_TICKET_BY_ID_FAILURE: "GET_TICKET_BY_ID_FAILURE",

  GET_ARCHIVETICKET_BY_ID_REQUEST: "GET_ARCHIVETICKET_BY_ID_REQUEST",
  GET_ARCHIVETICKET_BY_ID_SUCCESS: "GET_ARCHIVETICKET_BY_ID_SUCCESS",
  GET_ARCHIVETICKET_BY_ID_FAILURE: "GET_ARCHIVETICKET_BY_ID_FAILURE",

  GET_MAINTENANCE_WINDOWS_REQUEST: "GET_MAINTENANCE_WINDOWS_REQUEST",
  GET_MAINTENANCE_WINDOWS_SUCCESS: "GET_MAINTENANCE_WINDOWS_SUCCESS",
  GET_MAINTENANCE_WINDOWS_FAILURE: "GET_MAINTENANCE_WINDOWS_FAILURE",

  CREATE_MAINTENANCE_WINDOWS_REQUEST: "CREATE_MAINTENANCE_WINDOWS_REQUEST",
  CREATE_MAINTENANCE_WINDOWS_SUCCESS: "CREATE_MAINTENANCE_WINDOWS_SUCCESS",
  CREATE_MAINTENANCE_WINDOWS_FAILURE: "CREATE_MAINTENANCE_WINDOWS_FAILURE",

  DELETE_MAINTENANCE_WINDOWS_REQUEST: "DELETE_MAINTENANCE_WINDOWS_REQUEST",
  DELETE_MAINTENANCE_WINDOWS_SUCCESS: "DELETE_MAINTENANCE_WINDOWS_SUCCESS",
  DELETE_MAINTENANCE_WINDOWS_FAILURE: "DELETE_MAINTENANCE_WINDOWS_FAILURE",

  UPDATE_MAINTENANCE_WINDOWS_REQUEST: "UPDATE_MAINTENANCE_WINDOWS_REQUEST",
  UPDATE_MAINTENANCE_WINDOWS_SUCCESS: "UPDATE_MAINTENANCE_WINDOWS_SUCCESS",
  UPDATE_MAINTENANCE_WINDOWS_FAILURE: "UPDATE_MAINTENANCE_WINDOWS_FAILURE",

  GET_MAINTENANCE_WINDOWS_BY_ID_REQUEST:
    "GET_MAINTENANCE_WINDOWS_BY_ID_REQUEST",
  GET_MAINTENANCE_WINDOWS_BY_ID_SUCCESS:
    "GET_MAINTENANCE_WINDOWS_BY_ID_SUCCESS",
  GET_MAINTENANCE_WINDOWS_BY_ID_FAILURE:
    "GET_MAINTENANCE_WINDOWS_BY_ID_FAILURE",

  GET_SELECTED_MAINTENANCE_WINDOWS_ID: "GET_SELECTED_MAINTENANCE_WINDOWS_ID",

  CREATE_COMMUNITY_CARDS_REQUEST: "CREATE_COMMUNITY_CARDS_REQUEST",
  CREATE_COMMUNITY_CARDS_SUCCESS: "CREATE_COMMUNITY_CARDS_SUCCESS",
  CREATE_COMMUNITY_CARDS_FAILURE: "CREATE_COMMUNITY_CARDS_FAILURE",

  GET_COMMUNITY_CARDS_REQUEST: "GET_COMMUNITY_CARDS_REQUEST",
  GET_COMMUNITY_CARDS_SUCCESS: "GET_COMMUNITY_CARDS_SUCCESS",
  GET_COMMUNITY_CARDS_FAILURE: "GET_COMMUNITY_CARDS_FAILURE",

  UPDATE_COMMUNITY_CARDS_REQUEST: "UPDATE_COMMUNITY_CARDS_REQUEST",
  UPDATE_COMMUNITY_CARDS_SUCCESS: "UPDATE_COMMUNITY_CARDS_SUCCESS",
  UPDATE_COMMUNITY_CARDS_FAILURE: "UPDATE_COMMUNITY_CARDS_FAILURE",

  DELETE_COMMUNITY_CARDS_REQUEST: "DELETE_COMMUNITY_CARDS_REQUEST",
  DELETE_COMMUNITY_CARDS_SUCCESS: "DELETE_COMMUNITY_CARDS_SUCCESS",
  DELETE_COMMUNITY_CARDS_FAILURE: "DELETE_COMMUNITY_CARDS_FAILURE",

  CREATE_TEMPLATES_REQUEST: "CREATE_TEMPLATES_REQUEST",
  CREATE_TEMPLATES_SUCCESS: "CREATE_TEMPLATES_SUCCESS",
  CREATE_TEMPLATES_FAILURE: "CREATE_TEMPLATES_FAILURE",

  GET_TEMPLATES_REQUEST: "GET_TEMPLATES_REQUEST",
  GET_TEMPLATES_SUCCESS: "GET_TEMPLATES_SUCCESS",
  GET_TEMPLATES_FAILURE: "GET_TEMPLATES_FAILURE",

  UPDATE_TEMPLATES_REQUEST: "UPDATE_TEMPLATES_REQUEST",
  UPDATE_TEMPLATES_SUCCESS: "UPDATE_TEMPLATES_SUCCESS",
  UPDATE_TEMPLATES_FAILURE: "UPDATE_TEMPLATES_FAILURE",

  DELETE_TEMPLATES_REQUEST: "DELETE_TEMPLATES_REQUEST",
  DELETE_TEMPLATES_SUCCESS: "DELETE_TEMPLATES_SUCCESS",
  DELETE_TEMPLATES_FAILURE: "DELETE_TEMPLATES_FAILURE",

  CREATE_TEMPLATES_ELEMENTS_REQUEST: "CREATE_TEMPLATES_ELEMENTS_REQUEST",
  CREATE_TEMPLATES_ELEMENTS_SUCCESS: "CREATE_TEMPLATES_ELEMENTS_SUCCESS",
  CREATE_TEMPLATES_ELEMENTS_FAILURE: "CREATE_TEMPLATES_ELEMENTS_FAILURE",

  GET_TEMPLATES_ELEMENTS_REQUEST: "GET_TEMPLATES_ELEMENTS_REQUEST",
  GET_TEMPLATES_ELEMENTS_SUCCESS: "GET_TEMPLATES_ELEMENTS_SUCCESS",
  GET_TEMPLATES_ELEMENTS_FAILURE: "GET_TEMPLATES_ELEMENTS_FAILURE",

  UPDATE_TEMPLATES_ELEMENTS_REQUEST: "UPDATE_TEMPLATES_ELEMENTS_REQUEST",
  UPDATE_TEMPLATES_ELEMENTS_SUCCESS: "UPDATE_TEMPLATES_ELEMENTS_SUCCESS",
  UPDATE_TEMPLATES_ELEMENTS_FAILURE: "UPDATE_TEMPLATES_ELEMENTS_FAILURE",

  DELETE_TEMPLATES_ELEMENTS_REQUEST: "DELETE_TEMPLATES_ELEMENTS_REQUEST",
  DELETE_TEMPLATES_ELEMENTS_SUCCESS: "DELETE_TEMPLATES_ELEMENTS_SUCCESS",
  DELETE_TEMPLATES_ELEMENTS_FAILURE: "DELETE_TEMPLATES_ELEMENTS_FAILURE",

  GET_TEMPLATES_BY_ID_REQUEST: "GET_TEMPLATES_BY_ID_REQUEST",
  GET_TEMPLATES_BY_ID_SUCCESS: "GET_TEMPLATES_BY_ID_SUCCESS",
  GET_TEMPLATES_BY_ID_FAILURE: "GET_TEMPLATES_BY_ID_FAILURE",

  GET_TEMPLATES_ELEMENTS_BY_ID_REQUEST: "GET_TEMPLATES_ELEMENTS_BY_ID_REQUEST",
  GET_TEMPLATES_ELEMENTS_BY_ID_SUCCESS: "GET_TEMPLATES_ELEMENTS_BY_ID_SUCCESS",
  GET_TEMPLATES_ELEMENTS_BY_ID_FAILURE: "GET_TEMPLATES_ELEMENTS_BY_ID_FAILURE",

  GET_SELECTED_TEMPLATES_ID: "GET_SELECTED_TEMPLATES_ID",

  GET_SELECTED_ELEMENTS_TEMPLATES_ID: "GET_SELECTED_ELEMENTS_TEMPLATES_ID",

  GET_SELECTED_BUSINESS_ID: "GET_SELECTED_BUSINESS_ID",

  GET_SELECTED_TICKET_ID: "GET_SELECTED_TICKET_ID",

  GET_SELECTED_ARCHIVETICKET_ID: "GET_SELECTED_ARCHIVETICKET_ID",

  GET_SELECTED_MATRIMONIAL_ID: "GET_SELECTED_MATRIMONIAL_ID",

  GET_COUNTRIES_REQUEST: "GET_COUNTRIES_REQUEST",
  GET_COUNTRIES_SUCCESS: "GET_COUNTRIES_SUCCESS",
  GET_COUNTRIES_FAILURE: "GET_COUNTRIES_FAILURE",

  CREATE_COUNTRIES_REQUEST: "CREATE_COUNTRIES_REQUEST",
  CREATE_COUNTRIES_SUCCESS: "CREATE_COUNTRIES_SUCCESS",
  CREATE_COUNTRIES_FAILURE: "CREATE_COUNTRIES_FAILURE",

  UPDATE_COUNTRIES_REQUEST: "UPDATE_COUNTRIES_REQUEST",
  UPDATE_COUNTRIES_SUCCESS: "UPDATE_COUNTRIES_SUCCESS",
  UPDATE_COUNTRIES_FAILURE: "UPDATE_COUNTRIES_FAILURE",

  DELETE_COUNTRIES_REQUEST: "DELETE_COUNTRIES_REQUEST",
  DELETE_COUNTRIES_SUCCESS: "DELETE_COUNTRIES_SUCCESS",
  DELETE_COUNTRIES_FAILURE: "DELETE_COUNTRIES_FAILURE",

  GET_SELECTED_COUNTRIES_ID: "GET_SELECTED_COUNTRIES_ID",

  GET_COUNTRIES_BY_ID_REQUEST: "GET_COUNTRIES_BY_ID_REQUEST",
  GET_COUNTRIES_BY_ID_SUCCESS: "GET_COUNTRIES_BY_ID_SUCCESS",
  GET_COUNTRIES_BY_ID_FAILURE: "GET_COUNTRIES_BY_ID_FAILURE",

  GET_CONTACTUS_REQUEST: "GET_CONTACTUS_REQUEST",
  GET_CONTACTUS_SUCCESS: "GET_CONTACTUS_SUCCESS",
  GET_CONTACTUS_FAILURE: "GET_CONTACTUS_FAILURE",

  DELETE_CONTACTUS_REQUEST: "DELETE_CONTACTUS_REQUEST",
  DELETE_CONTACTUS_SUCCESS: "DELETE_CONTACTUS_SUCCESS",
  DELETE_CONTACTUS_FAILURE: "DELETE_CONTACTUS_FAILURE",

  GET_SELECTED_CONTACTUS_ID: "GET_SELECTED_CONTACTUS_ID",

  GET_CONTACTUS_BY_ID_REQUEST: "GET_CONTACTUS_BY_ID_REQUEST",
  GET_CONTACTUS_BY_ID_SUCCESS: "GET_CONTACTUS_BY_ID_SUCCESS",
  GET_CONTACTUS_BY_ID_FAILURE: "GET_CONTACTUS_BY_ID_FAILURE",

  LOGIN_OTP:"LOGIN_OTP",

  GET_JOURNEY_REQUEST: "GET_JOURNEY_REQUEST",
  GET_JOURNEY_SUCCESS: "GET_JOURNEY_SUCCESS",
  GET_JOURNEY_FAILURE: "GET_JOURNEY_FAILURE",

  CREATE_JOURNEY_REQUEST: "CREATE_JOURNEY_REQUEST",
  CREATE_JOURNEY_SUCCESS: "CREATE_JOURNEY_SUCCESS",
  CREATE_JOURNEY_FAILURE: "CREATE_JOURNEY_FAILURE",

  UPDATE_JOURNEY_REQUEST: "UPDATE_JOURNEY_REQUEST",
  UPDATE_JOURNEY_SUCCESS: "UPDATE_JOURNEY_SUCCESS",
  UPDATE_JOURNEY_FAILURE: "UPDATE_JOURNEY_FAILURE",

  DELETE_JOURNEY_REQUEST: "DELETE_JOURNEY_REQUEST",
  DELETE_JOURNEY_SUCCESS: "DELETE_JOURNEY_SUCCESS",
  DELETE_JOURNEY_FAILURE: "DELETE_JOURNEY_FAILURE",

  GET_SELECTED_JOURNEY_ID: "GET_SELECTED_JOURNEY_ID",

  GET_JOURNEY_BY_ID_REQUEST: "GET_JOURNEY_BY_ID_REQUEST",
  GET_JOURNEY_BY_ID_SUCCESS: "GET_JOURNEY_BY_ID_SUCCESS",
  GET_JOURNEY_BY_ID_FAILURE: "GET_JOURNEY_BY_ID_FAILURE",

  GET_JOURNEY_ROUTE_REQUEST: "GET_JOURNEY_ROUTE_REQUEST",
  GET_JOURNEY_ROUTE_SUCCESS: "GET_JOURNEY_ROUTE_SUCCESS",
  GET_JOURNEY_ROUTE_FAILURE: "GET_JOURNEY_ROUTE_FAILURE",

  CREATE_JOURNEY_ROUTE_REQUEST: "CREATE_JOURNEY_ROUTE_REQUEST",
  CREATE_JOURNEY_ROUTE_SUCCESS: "CREATE_JOURNEY_ROUTE_SUCCESS",
  CREATE_JOURNEY_ROUTE_FAILURE: "CREATE_JOURNEY_ROUTE_FAILURE",

  UPDATE_JOURNEY_ROUTE_REQUEST: "UPDATE_JOURNEY_ROUTE_REQUEST",
  UPDATE_JOURNEY_ROUTE_SUCCESS: "UPDATE_JOURNEY_ROUTE_SUCCESS",
  UPDATE_JOURNEY_ROUTE_FAILURE: "UPDATE_JOURNEY_ROUTE_FAILURE",

  DELETE_JOURNEY_ROUTE_REQUEST: "DELETE_JOURNEY_ROUTE_REQUEST",
  DELETE_JOURNEY_ROUTE_SUCCESS: "DELETE_JOURNEY_ROUTE_SUCCESS",
  DELETE_JOURNEY_ROUTE_FAILURE: "DELETE_JOURNEY_ROUTE_FAILURE",

  GET_SELECTED_JOURNEY_ROUTE_ID: "GET_SELECTED_JOURNEY_ROUTE_ID",

  GET_JOURNEY_ROUTE_BY_ID_REQUEST: "GET_JOURNEY_ROUTE_BY_ID_REQUEST",
  GET_JOURNEY_ROUTE_BY_ID_SUCCESS: "GET_JOURNEY_ROUTE_BY_ID_SUCCESS",
  GET_JOURNEY_ROUTE_BY_ID_FAILURE: "GET_JOURNEY_ROUTE_BY_ID_FAILURE",

  GET_USER_DEVICE_REQUEST: "GET_USER_DEVICE_REQUEST",
  GET_USER_DEVICE_SUCCESS: "GET_USER_DEVICE_SUCCESS",
  GET_USER_DEVICE_FAILURE: "GET_USER_DEVICE_FAILURE",

  CREATE_USER_DEVICE_REQUEST: "CREATE_USER_DEVICE_REQUEST",
  CREATE_USER_DEVICE_SUCCESS: "CREATE_USER_DEVICE_SUCCESS",
  CREATE_USER_DEVICE_FAILURE: "CREATE_USER_DEVICE_FAILURE",

  DELETE_USER_DEVICE_REQUEST: "DELETE_USER_DEVICE_REQUEST",
  DELETE_USER_DEVICE_SUCCESS: "DELETE_USER_DEVICE_SUCCESS",
  DELETE_USER_DEVICE_FAILURE: "DELETE_USER_DEVICE_FAILURE",

  UPDATE_USER_DEVICE_REQUEST: "UPDATE_USER_DEVICE_REQUEST",
  UPDATE_USER_DEVICE_SUCCESS: "UPDATE_USER_DEVICE_SUCCESS",
  UPDATE_USER_DEVICE_FAILURE: "UPDATE_USER_DEVICE_FAILURE",

  GET_USER_DEVICE_BY_ID_REQUEST: "GET_USER_DEVICE_BY_ID_REQUEST",
  GET_USER_DEVICE_BY_ID_SUCCESS: "GET_USER_DEVICE_BY_ID_SUCCESS",
  GET_USER_DEVICE_BY_ID_FAILURE: "GET_USER_DEVICE_BY_ID_FAILURE",

  GET_SELECTED_USER_DEVICE_ID:"GET_SELECTED_USER_DEVICE_ID",
};
