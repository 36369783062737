import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import {
  Modal,
  Input,
  Button,
  Typography,
  Row,
  Col,
  message,
  Select,
  Checkbox,
  Spin,
} from "antd";
import { Controller, useForm } from "react-hook-form";
import {
  createBusiness,
  getBusiness,
  updateBusiness,
} from "../../Actions/businessAction";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import _ from "lodash";
import {
  BusinessuserSchema,
  BusinessuserUpdateSchema,
} from "../../Utils/Schema";
import { getSearchUser } from "../../Actions/searchUserAction";
const BusinessProfileModalForm = (props) => {
  const { visible, setVisible, editId, setEditId, setSelectedUserId } = props;
  const dispatch = useDispatch();

  const {
    searchUsers,
    setCommunityId,
    allBusiness,
    isUpdateBusinessLoading,
    isCreateBusinessLoading,
  } = useSelector((state) => {
    const {
      allMatrimonialsSuperAdmin,
      isMatrimonialLoading,
      isCreateMatrimonialLoading,
      isUpdateMatrimonialLoading,
    } = state.matrimonial;
    const { allFamilies } = state.family;
    const { searchUsers } = state.searchUser;
    const { isDeleteFileLoading } = state.message;
    const { setCommunityId } = state.communities;
    const {
      allBusiness,
      isCreateBusinessLoading,
      isUpdateBusinessLoading,
    } = state.business;
    return {
      allMatrimonialsSuperAdmin,
      isMatrimonialLoading,
      allFamilies,
      searchUsers,
      isDeleteFileLoading,
      isCreateMatrimonialLoading,
      isUpdateMatrimonialLoading,
      setCommunityId,
      allBusiness,
      isUpdateBusinessLoading,
      isCreateBusinessLoading,
    };
  });
  // const { family_members } = (allFamilies.length > 0 && allFamilies[0]) || {};
  const the_business = _.filter(allBusiness, function (o) {
    return o.id === editId;
  });

  const userName =
    the_business &&
    the_business.length > 0 &&
    the_business[0]?.user?.first_name;
  const userId =
    the_business && the_business.length > 0 && the_business[0]?.user?.id;
  const userLastName =
    the_business && the_business.length > 0 && the_business[0]?.user?.last_name;
  const userFullName = userName + " " + userLastName;
  // const onFinish = (values) => {
  //
  // };
  const [preloadedBusinessProfileValue, setPreloadedBusinessProfileValue] =
    useState(null);

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: preloadedBusinessProfileValue,
    resolver: yupResolver(
      editId ? BusinessuserUpdateSchema : BusinessuserSchema
    ),
  });
  setValue(
    "user_id",
    editId &&
      the_business[0]?.user?.first_name + " " + the_business[0]?.user?.last_name
  );
  useEffect(() => {
    setPreloadedBusinessProfileValue(the_business[0]);
    reset(the_business[0]);
    dispatch(getSearchUser(`api/user_search?community_id=${setCommunityId}`));
  }, [the_business[0], reset]);
  const [checkedValue, setCheckedValue] = useState(
    the_business[0]?.is_wholesale ? true : false
  );

  const handleCreateBusiness = (data) => {
    data.user_id = data.create_user_id;
    data.is_business = true;
    data.is_retail = checkedValue === "Retail" ? true : false;
    data.is_wholesale = checkedValue === "Wholesale" ? true : false;
    dispatch(
      createBusiness(data, successCreateBusiness, failureCreateBusiness)
    );
  };
  const successCreateBusiness = () => {
    reset();
    setVisible(false);
    setSelectedUserId("");
    setTimeout(() => {
      message.success("Your Business Profile Created Successfully");
    }, 1000);
    dispatch(getBusiness());
    setCheckedValue("");
  };
  const failureCreateBusiness = () => {};

  const hanleEditBusiness = (data) => {
    data.id = editId;
    // data.user_id = selectedUserId ? selectedUserId : userId
    data.user_id = userId;
    data.is_wholesale =
      checkedValue === "Wholesale"
        ? true
        : checkedValue === "Retail"
        ? false
        : the_business[0]?.is_wholesale;
    data.is_retail =
      checkedValue === "Retail"
        ? true
        : checkedValue === "Wholesale"
        ? false
        : the_business[0]?.is_retail;
    // data.is_wholesale = checkedValue === "Wholesale" ? true : the_business[0]?.is_wholesale
    // data.is_retail = checkedValue === "Retail" ? true : the_business[0]?.is_retail

    dispatch(updateBusiness(data, UpdateCallBack));
  };
  const UpdateCallBack = () => {
    reset();

    setVisible(false);
    setEditId("");

    setSelectedUserId("");
    setTimeout(() => {
      message.success("Your Business Profile Edited Successfully");
    }, 1000);
    dispatch(getBusiness());
    setCheckedValue("");
  };
  const handleChangeUserId = (val) => {
    setSelectedUserId(val);
  };
  const handleCancel = () => {
    reset({});
    setVisible(false);
    setEditId("");
    setSelectedUserId("");
    setCheckedValue("");
    clearErrors();
    setValue("company_name", "");

    setValue("service_provide", "");
    setValue("business_details", "");
    setValue("business_location", "");
    setValue("number_of_staff", "");
    setValue("working_here_since", "");
  };

  // hello
  const handledChecked = (event) => {
    const { value } = event.target;
    setCheckedValue(value);
  };
  const servicesOption = [
    { label: "Developer", value: "Developer" },
    { label: "Engineer", value: "Engineer" },
  ];
  useEffect(() => {
    if (!editId) {
      // setValue("user_id","");
      setValue("company_name", "");
      setValue("service_provide", "");
      setValue("business_details", "");
      setValue("business_location", "");

      setValue("number_of_staff", "");
      setEditId("");

      setValue("working_here_since", "");
    }
  }, [setValue, !editId]);
  return (
    <>
      <Modal
        title={
          editId
            ? `Edit Business Profile For ${userFullName}`
            : "Create Business Profile"
        }
        centered
        visible={visible}
        // onOk={() => setVisible(false)}
        onCancel={() => handleCancel()}
        width={700}
        footer={null}
        className="matrimonialProfile"
      >
        <form
          onSubmit={handleSubmit(
            editId ? hanleEditBusiness : handleCreateBusiness
          )}
        >
          <Spin
            spinning={
              editId ? isUpdateBusinessLoading : isCreateBusinessLoading
            }
            size="large"
          >
            <Row gutter={[8, 8]}>
              {!editId && (
                <Col span={12} className="formRows">
                  <Typography className="details" style={{ paddingBottom: 9 }}>
                  First Name
                  </Typography>
                  {editId ? (
                    <Controller
                      as={
                        <Select
                          placeholder="Select user"
                          style={{ width: "100%", borderRadius: "4px" }}
                          size="large"
                          showSearch={true}
                          options={
                            searchUsers &&
                            searchUsers.length > 0 &&
                            searchUsers.map((val, i) => {
                              return {
                                label: val.first_name + " " + val.last_name,
                                value: val.id,
                              };
                            })
                          }
                          filterOption={(input, option) =>
                            option.label
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          // onChange={(e) => handleChangeUserId(e)}
                        ></Select>
                      }
                      className="inputLabel"
                      name="user_id"
                      control={control}
                    />
                  ) : (
                    <Controller
                      as={
                        <Select
                          placeholder="Select user"
                          style={{ width: "100%", borderRadius: "4px" }}
                          size="large"
                          showSearch={true}
                          options={
                            searchUsers &&
                            searchUsers.length > 0 &&
                            searchUsers.map((val, i) => {
                              return {
                                label: val.first_name + " " + val.last_name,
                                value: val.id,
                              };
                            })
                          }
                          filterOption={(input, option) =>
                            option.label
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        ></Select>
                      }
                      className="inputLabel"
                      name="create_user_id"
                      control={control}
                    />
                  )}
                  {errors.create_user_id && (
                    <p style={{ color: "red" }}>First Name is required</p>
                  )}
                </Col>
              )}
              <Col span={editId ? 24 : 12} className="formRows">
                <Typography className="details" style={{ paddingBottom: 9 }}>
                  Established Year
                </Typography>
                <Controller
                  as={<Input size="large" />}
                  name="working_here_since"
                  control={control}
                  className="inputLabel"
                />
                {errors.working_here_since && (
                  <p style={{ color: "red" }}>
                    {errors.working_here_since.message}
                  </p>
                )}
              </Col>
            </Row>

            <Row gutter={[8, 8]}>
              <Col span={12} className="formRows">
                <Typography className="details" style={{ paddingBottom: 9 }}>
                Company Name
                </Typography>
                <Controller
                  as={<Input size="large" />}
                  name="company_name"
                  control={control}
                  className="inputLabel"
                />
                {errors.company_name && (
                  <p style={{ color: "red" }}>Company Name is required</p>
                )}
              </Col>
              <Col span={12} className="formRows">
                <Typography className="details" style={{ paddingBottom: 9 }}>
                Service Provide
                </Typography>
                <Controller
                  as={<Input size="large" />}
                  name="service_provide"
                  control={control}
                  className="inputLabel"
                />
                {errors.service_provide && (
                  <p style={{ color: "red" }}>Service Provide is required</p>
                )}
              </Col>
            </Row>

            <Row gutter={[8, 8]}>
              <Col span={24} className="formRows">
                <Typography className="details" style={{ paddingBottom: 9 }}>
                Business Details
                </Typography>
                <Controller
                  as={<Input size="large" />}
                  name="business_details"
                  control={control}
                  className="inputLabel"
                />
                {errors.business_details && (
                  <p style={{ color: "red" }}>Business Details is required</p>
                )}
              </Col>
            </Row>

            <Row gutter={[8, 8]}>
              <Col span={24} className="formRows">
                <Typography className="details" style={{ paddingBottom: 9 }}>
                Business Location
                </Typography>
                <Controller
                  as={<Input size="large" />}
                  name="business_location"
                  control={control}
                  className="inputLabel"
                />
                {errors.business_location && (
                  <p style={{ color: "red" }}>Business Location is required</p>
                )}
              </Col>
              <Col span={12} className="formRows">
                <Typography className="details" style={{ paddingBottom: 9 }}>
                Number Of Staff
                </Typography>
                <Controller
                  as={<Input size="large" />}
                  name="number_of_staff"
                  control={control}
                  className="inputLabel"
                />
                {errors.number_of_staff && (
                  <p style={{ color: "red" }}>Number of staff is required</p>
                )}
              </Col>
            </Row>
            <Row gutter={[8, 10]}>
              <Col span={24} />
              <Col>
                <Checkbox
                  onChange={handledChecked}
                  checked={
                    checkedValue
                      ? checkedValue === "Wholesale" && true
                      : the_business[0]?.is_wholesale === true && true
                  }
                  value={"Wholesale"}
                >
                  Wholesale
                </Checkbox>
              </Col>
              <Col>
                <Checkbox
                  onChange={handledChecked}
                  checked={
                    checkedValue
                      ? checkedValue === "Retail" && true
                      : the_business[0]?.is_retail === true && true
                  }
                  value={"Retail"}
                >
                  Retail
                </Checkbox>
              </Col>
            </Row>
            <Row gutter={[8, 8]}>
              <Col xl={12} lg={12} md={12} sm={6} xs={6}></Col>
              <Col
                xl={12}
                lg={12}
                md={12}
                sm={18}
                xs={18}
                className="formRows"
                style={{ textAlign: "end" }}
              >
                {editId ? (
                  <Button htmlType="submit" className="modal-buttonAdd">
                    Update
                  </Button>
                ) : (
                  <Button htmlType="submit" className="modal-buttonAdd">
                    Add
                  </Button>
                )}
                <Button
                  // style={{ margin: "0 8px" }}
                  className="modal-buttonCancel"
                  onClick={() => handleCancel()}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </Spin>
        </form>
      </Modal>
    </>
  );
};
export default BusinessProfileModalForm;
