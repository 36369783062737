import React, { useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Typography,
  Divider,
  Button,
  Empty,
  Spin,
  Tooltip,
  Popconfirm,
  message,
} from "antd";
import "../FindOtherPeople.css";

import { useDispatch, useSelector } from "react-redux";
import { deleteBusiness, getBusiness } from "../../Actions/businessAction";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import BusinessEmpty from "../../assets/BusinessEmpty.svg";
const ViewJobProfile = (props) => {
  const {
    setEditId,
    setVisible,
    setViewBusinessProfileId,
    business_profiles,
    setSelectedJobProfileId,
    isPeopleDetailsVisible,
    setIsSelectedJobProfileVisible,
  } = props;

  const { allBusiness, isBusinessLoading } = useSelector((state) => {
    const { allBusiness, isBusinessLoading } = state.business;
    return {
      allBusiness,
      isBusinessLoading,
    };
  });
  const jobProfileData = allBusiness?.filter((o) => {
    return o.is_business === false;
  });
  // const [ setIsModalVisible] = useState(false);
  const handleEdit = (val) => {
    setEditId(val);
    // setIsModalVisible(true);
    // setBusinessForm(true);
    setVisible(true);
  };
  const handleDeleteJobProfileMethod = (businessId) => {
    dispatch(deleteBusiness(businessId, successDeleteJobProfileMethod));
  };
  const successDeleteJobProfileMethod = () => {
    setTimeout(() => {
      message.success("Your Job Profile Deleted Successfully");
    }, 1000);
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getBusiness());
  }, [dispatch]);
  const handleClick = (businessProfileId) => {
    if (isPeopleDetailsVisible) {
      setSelectedJobProfileId(businessProfileId);
      setIsSelectedJobProfileVisible(true);
    } else {
      setViewBusinessProfileId(businessProfileId);
    }
  };

  return (
    <Spin spinning={!isPeopleDetailsVisible && isBusinessLoading} size="large">
      {jobProfileData && jobProfileData.length > 0 ? (
        <Row gutter={[32, 32]}>
          {isPeopleDetailsVisible
            ? business_profiles?.map((val, i) => {
                return (
                  <Col xl={12} lg={17} md={16} sm={24} xs={24} key={i}>
                    <Card className="MatchesCard">
                      <Row gutter={[0, 16]}>
                        <Col xl={20} lg={20} md={18} sm={18} xs={18}>
                          <Row gutter={[16, 16]}>
                            <Col span={24}>
                              <Typography className="profile-name">
                                {val.job_title}
                              </Typography>
                            </Col>
                            <Col>
                              <Typography className="BusinessEstablishedDate">
                                Working here Since:{" "}
                                <Typography
                                  component="span"
                                  className="BusinessEstablishedDateData"
                                >
                                  {val?.working_here_since}
                                </Typography>
                              </Typography>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row gutter={[0, 16]}>
                        <Divider className="JobProfileCardDivider" />
                      </Row>
                      <Row>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                          <Typography className="profile-address">
                            {val.business_location}
                          </Typography>
                        </Col>
                        <Col
                          xl={24}
                          lg={24}
                          md={24}
                          sm={24}
                          xs={24}
                          style={{ textAlign: "end" }}
                        >
                          <Button
                            className=" View-fullProfile"
                            onClick={() => handleClick(val.id)}
                          >
                           View Full Job Information{" "}
                          </Button>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                );
              })
            : jobProfileData?.map((val, i) => {
                return (
                  <Col xl={12} lg={12} md={18} sm={19} xs={24} key={i}>
                    <Card className="MatchesCard">
                      <Row gutter={[0, 16]}>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                          <Row justify="space-between">
                            <Col>
                              <Typography className="profile-name">
                                {val.job_title}
                              </Typography>
                            </Col>
                            <Col style={{ textAlign: "end" }}>
                              <Tooltip title="Edit">
                                <Button
                                  icon={<EditOutlined />}
                                  type="text"
                                  onClick={() => handleEdit(val.id)}
                                />
                              </Tooltip>
                              <Tooltip title="Delete">
                                <Popconfirm
                                  title="Are you sure?"
                                  onConfirm={() =>
                                    handleDeleteJobProfileMethod(val.id)
                                  }
                                  okText="Delete"
                                  cancelText="Cancel"
                                >
                                  <Button
                                    icon={<DeleteOutlined />}
                                    type="text"
                                  />
                                </Popconfirm>
                              </Tooltip>
                            </Col>
                          </Row>
                        </Col>
                        <Row>
                          <Col span={24}>
                            <Typography className="BusinessEstablishedDate">
                              Working here Since:{" "}
                              <Typography
                                component="span"
                                className="BusinessEstablishedDateData"
                              >
                                {val.working_here_since}
                              </Typography>
                            </Typography>
                          </Col>
                        </Row>
                        {/* <Col
                          xl={4}
                          lg={4}
                          md={6}
                          sm={6}
                          xs={6}
                          style={{ textAlign: "end" }}
                        >
                          <Tooltip title={t("btn.27")}>
                            <Button
                              icon={<EditOutlined />}
                              type="text"
                              onClick={() => handleEdit(val.id)}
                            />
                          </Tooltip>
                          <Tooltip title={t("btn.28")}>
                            <Popconfirm
                              title={t("btn.29")}
                              onConfirm={() =>
                                handleDeleteJobProfileMethod(val.id)
                              }
                              okText={t("btn.28")}
                              cancelText={t("btn.19")}
                            >
                              <Button icon={<DeleteOutlined />} type="text" />
                            </Popconfirm>
                          </Tooltip>
                        </Col> */}
                      </Row>
                      <Row gutter={[0, 16]}>
                        <Divider className="JobProfileCardDivider" />
                      </Row>
                      <Row>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                          <Typography className="profile-address">
                            {val.business_location}
                          </Typography>
                        </Col>
                        <Col
                          xl={24}
                          lg={24}
                          md={24}
                          sm={24}
                          xs={24}
                          style={{ textAlign: "end" }}
                        >
                          <Button
                            className=" View-fullProfile"
                            onClick={() => handleClick(val.id)}
                          >
                            View Full Job Information{" "}
                          </Button>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                );
              })}
        </Row>
      ) : (
        <Row justify="center" style={{ paddingTop: "150px" }}>
          <Col>
            <Empty
              image={BusinessEmpty}
              imageStyle={{
                height: 30,
              }}
              description={
                <span className="empty-details"> No Job Profile yet</span>
              }
            ></Empty>
          </Col>
        </Row>
      )}
    </Spin>
  );
};
export default ViewJobProfile;
