import React, { useEffect, useState } from "react";
import { Row, Col, Button, Tabs } from "antd";
import "./Admin.css";
import AddNativeModal from "./AddNativeModal";
import Nativetable from "./NativeTable";

import AddDesignationModal from "./AddDesignationModal";
import DesignationTable from "./DesignationTable";

import { useDispatch, useSelector } from "react-redux";
import { getDesignations } from "../../Actions/designationsAction";

import _ from "lodash";

import { useForm } from "react-hook-form";

import "../../FindThePeoplePage/FindOtherPeople.css";
import { getCommunitiesNative } from "../../Actions/communitiesNativeAction";

import Settings from "./Settings";
import AddEducationModal from "./AddEducationModal";
import EducationTable from "./EducationTable";
import { getEducationsAction } from "../../Actions/educationAction";
import AddIndustryModal from "./AddIndustryModal";

import { getIndustriesAction } from "../../Actions/industryAction";
import IndustryTable from "./IndustryTable";
import VolunteerTable from "./VolunteerTable";
import { history } from "../../Utils/history";
import MeetingTable from "./MeetingTable";
import AddMeetingModal from "./AddMeetingModal";
import { getCommunityMeetings } from "../../Actions/communityMeetingsAction";
import DocumnetTable from "./DocumentTable";
import HandleUsers from "./HandleUsers";
import UploadDocument from "./UploadDocument";
import { getDocuments } from "../../Actions/documentsAction";

const Admin = () => {
  const [editDesignationId, setEditDesignationId] = useState("");
  const {
    allDesignations,
    isCreateDesignationsLoading,
    isGetDesignationsLoading,
    isUpdateDesignationsLoading,
    allCommunitiesNative,
    isCommunitiesNativeLoading,
    allEducations,
    isEducationsLoading,
    isCreateEducationLoading,

    isUpdateEducationLoading,
    allIndustries,
    isCreateIndustryLoading,

    isUpdateIndustryLoading,
    currentUser,

    allMeetings,
    isCommunityMeetingsLoading,
    allDocuments,
  } = useSelector((state) => {
    const {
      allDesignations,
      isCreateDesignationsLoading,
      isUpdateDesignationsLoading,
      isGetDesignationsLoading,
    } = state.designations;

    const { allCommunitiesNative, isCommunitiesNativeLoading } = state.communitiesNative;

    const {
      allEducations,
      isEducationsLoading,
      isCreateEducationLoading,
      isUpdateEducationLoading,
    } = state.educations;
    const { allIndustries, isCreateIndustryLoading, isUpdateIndustryLoading } =
      state.industry;
    const { currentUser } = state.authentication;
    const { allMeetings, isCommunityMeetingsLoading, editMeetingId } = state.communityMeetings;
    const { allDocuments } = state.documents;


    return {
      allDesignations,
      isCreateDesignationsLoading,
      isUpdateDesignationsLoading,
      isGetDesignationsLoading,
      allCommunitiesNative,
      isCommunitiesNativeLoading,
      allEducations,

      isEducationsLoading,
      isCreateEducationLoading,
      isUpdateEducationLoading,
      allIndustries,
      isCreateIndustryLoading,
      isUpdateIndustryLoading,
      currentUser,
      allMeetings,
      isCommunityMeetingsLoading,
      editMeetingId,
      allDocuments,
    };
  });
  const dispatch = useDispatch();
  const { setValue } = useForm({});
  const redirectMethod = () => {

    history.push("/addfamily")
  }

  const d = new Date();
  let y = d.getFullYear();
  const { users_role , users_types} = currentUser || {};

  const isUserAdmin = users_types.includes("admin")
  useEffect(() => {
    dispatch(getDesignations(process.env.REACT_APP_COMMUNITY_ID));
    dispatch(getCommunitiesNative(process.env.REACT_APP_COMMUNITY_ID));
    dispatch(getEducationsAction());
    dispatch(getIndustriesAction());

    dispatch(getCommunityMeetings(process.env.REACT_APP_COMMUNITY_ID, y));
    dispatch(getDocuments(process.env.REACT_APP_COMMUNITY_ID));
    if(!isUserAdmin){
        redirectMethod()

    }
  }, [dispatch]);
  const { TabPane } = Tabs;
  const [tabKey, setTabKey] = useState("1");
  const [isAddNativeModalVisible, setIsAddNativeModalVisible] = useState(false);

  const [isAddDesignationModalVisible, setIsAddDesignationModalVisible] =
    useState(false);
  const [editNativeId, setEditNativeId] = useState("");
  const [isEditDesignationModalVisible, setIsEditDesignationModalVisible] =
    useState(false);
  const [isEditNativeModalVisible, setIsEditNativeModalVisible] =
    useState(false);
  const [isAddEducationModalVisible, setIsAddEducationModalVisible] =
    useState(false);
  const [isEditEducationModalVisible, setIsEditEducationModalVisible] =
    useState(false);
  const [editEducationId, setEditEducationId] = useState("");
  const [isAddIndustryModalVisible, setIsAddIndustryModalVisible] =
    useState(false);
  const [isEditIndustryModalVisible, setIsEditIndustryModalVisible] =
    useState(false);
  const [editIndustryId, setEditIndustryId] = useState("");
  const [isAddMeetingModalVisible, setIsAddMeetingModalVisible] =
    useState(false);
  const [editMeetingId, setEditMeetingId] = useState("");
  const [iseditMeetingModalVisible, setIseditMeetingModalVisible] =
    useState(false);
  const [isPublishMeeting, setIsPublishMeeting] = useState(false);
  const [isUploadDocsVisible, setIsUploadDocsVisible] = useState(false);

  const callback = (key) => {
    setTabKey(key);
  };


  const openAddNativeMethod = () => {
    setValue("name", "");
    setIsAddNativeModalVisible(true);
  };
  const openAddDesignationMethod = () => {
    setIsAddDesignationModalVisible(true);
  };
  const openAddEducationMethod = () => {
    setIsAddEducationModalVisible(true);
  };

  const openAddIndustryMethod = () => {
    setIsAddIndustryModalVisible(true);
  };
  const openAddMeetingMethod = () => {
    setIsAddMeetingModalVisible(true);
  };

  const openUploadDocsMethod = () => {
    setIsUploadDocsVisible(true);
  }


  const selectedDesignation = _.filter(allDesignations, (designations) => {
    return designations.id === editDesignationId;
  });
  const selectedNative = _.filter(allCommunitiesNative, (natives) => {
    return natives.id === editNativeId;
  });
  const selectedEducation = _.filter(allEducations, (educations) => {
    return educations.id === editEducationId;
  });

  const selectedIndustry = _.filter(allIndustries, (industries) => {
    return industries.id === editIndustryId;
  });
  const selectedMeeting = _.filter(allMeetings, (meetings) => {
    return meetings.id === editMeetingId;
  });
  const height = window.innerHeight - 64;
 
  return (
    <div
      style={{
        height: height,
        overflow: "hidden",
        overflowY: "scroll",

        backgroundColor: "#FFFFFF",
        // margin: "10px",
      }}
      className="customLayout"
    >
      <>
        <Row 
        // style={{ padding: "24px" }}
        >
          <Col span={24}>
            <Row justify="space-between">
              <Col>
                <div className="Tabs">
                  <Tabs
                    defaultActiveKey="1"
                    //  onTabClick={onTabClick}
                    animated={false}
                    renderTabBar={(props, DefaultTabBar) => (
                      <DefaultTabBar {...props} className="cMTabsBckground">
                        {(node) => (
                          <div key={node.key} index={node.key}>
                            {node}
                          </div>
                        )}
                      </DefaultTabBar>
                    )}
                    onChange={(e) => callback(e)}
                    moreIcon={false}
                    tabBarExtraContent={false}
                    className="hideBottomLine"
                    tabPosition={"top"}
                  >
                    <TabPane
                      tab={
                        <Button
                          className={
                            tabKey === "1"
                              ? "selectedButton"
                              : "unselectedButton"
                          }
                        >
                         Designation
                        </Button>
                      }
                      key="1"
                    />
                    <TabPane
                      tab={
                        <Button
                          className={
                            tabKey === "2"
                              ? "selectedButton"
                              : "unselectedButton"
                          }
                        >
                          Native
                        </Button>
                      }
                      key="2"
                    ></TabPane>
                    <TabPane
                      tab={
                        <Button
                          className={
                            tabKey === "3"
                              ? "selectedButton"
                              : "unselectedButton"
                          }
                        >
                         Education
                        </Button>
                      }
                      key="3"
                    ></TabPane>
                    <TabPane
                      tab={
                        <Button
                          className={
                            tabKey === "4"
                              ? "selectedButton"
                              : "unselectedButton"
                          }
                        >
                          Industry
                        </Button>
                      }
                      key="4"
                    ></TabPane>
                    <TabPane
                      tab={
                        <Button
                          className={
                            tabKey === "6"
                              ? "selectedButton"
                              : "unselectedButton"
                          }
                        >
                         Volunteer
                        </Button>
                      }
                      key="6"
                    ></TabPane>


                    <TabPane
                      tab={
                        <Button
                          className={
                            tabKey === "8"
                              ? "selectedButton"
                              : "unselectedButton"
                          }
                        >
                          Community Meeting
                        </Button>

                      }
                      key="8"
                    ></TabPane>
                    <TabPane
                      tab={
                        <Button
                          className={
                            tabKey === "9"
                              ? "selectedButton"
                              : "unselectedButton"
                          }
                        >
                          Documents
                        </Button>

                      }
                      key="9"
                    ></TabPane>
                    <TabPane
                      tab={
                        <Button
                          className={
                            tabKey === "7"
                              ? "selectedButton"
                              : "unselectedButton"
                          }
                        >
                          Users
                        </Button>
                      }
                      key="7"
                    ></TabPane>

                    <TabPane
                      tab={
                        <Button
                          className={
                            tabKey === "5"
                              ? "selectedButton"
                              : "unselectedButton"
                          }
                        >
                          Settings
                        </Button>
                      }
                      key="5"
                    ></TabPane>
                  </Tabs>
                </div>
              </Col>
              <Col span={24}>
                <Row justify="end">
                  {tabKey === "1" ? (
                    <Button
                      type="primary"
                      onClick={() => openAddDesignationMethod()}
                      className="button-search"
                    >
                     Add Designation
                    </Button>
                  ) : tabKey === "2" ? (
                    <Button
                      type="primary"
                      onClick={() => openAddNativeMethod()}
                      className="button-search"
                    >
                      Add Native
                    </Button>
                  ) : tabKey === "3" ? (
                    <Button
                      type="primary"
                      className="button-search"
                      onClick={() => openAddEducationMethod()}
                    >
                      Add Education
                    </Button>
                  ) : tabKey === "4" ? (
                    <Button
                      type="primary"
                      className="button-search"
                      onClick={() => openAddIndustryMethod()}
                    >
                      Add Industry
                    </Button>
                  ) : tabKey === "8" ? (
                    <Button
                      type="primary"
                      className="button-search"
                      onClick={() => openAddMeetingMethod()}
                    >
                     Add Meeting
                    </Button>
                  ) : tabKey === "9" ? (
                    <Button
                      type="primary"
                      className="editButton"
                      onClick={() => openUploadDocsMethod()}
                    >
                      Upload Document
                    </Button>
                  ) : (
                    ""
                  )}

                  <AddNativeModal
                    isAddNativeModalVisible={isAddNativeModalVisible}
                    setIsAddNativeModalVisible={setIsAddNativeModalVisible}
                    isEditNativeModalVisible={isEditNativeModalVisible}
                    setIsEditNativeModalVisible={setIsEditNativeModalVisible}
                    editNativeId={editNativeId}
                    selectedNative={selectedNative?.[0]}
                    setEditNativeId={setEditNativeId}
                  />



                    <AddMeetingModal 
                      isAddMeetingModalVisible = {isAddMeetingModalVisible}
                      setIsAddMeetingModalVisible = {setIsAddMeetingModalVisible}
                      editMeetingId = {editMeetingId}
                      setEditMeetingId = {setEditMeetingId}
                      iseditMeetingModalVisible = {iseditMeetingModalVisible}
                      setIseditMeetingModalVisible = {setIseditMeetingModalVisible}
                      selectedMeeting = {selectedMeeting}
                      allMeetings = {allMeetings}
                    />

                    <UploadDocument 
                      isUploadDocsVisible = {isUploadDocsVisible}
                      setIsUploadDocsVisible = {setIsUploadDocsVisible}
                    />


                  <AddDesignationModal
                    isAddDesignationModalVisible={isAddDesignationModalVisible}
                    setIsAddDesignationModalVisible={
                      setIsAddDesignationModalVisible
                    }
                    isEditDesignationModalVisible={
                      isEditDesignationModalVisible
                    }
                    setIsEditDesignationModalVisible={
                      setIsEditDesignationModalVisible
                    }
                    selectedDesignation={selectedDesignation?.[0]}
                    editDesignationId={editDesignationId}
                    isCreateDesignationsLoading={isCreateDesignationsLoading}
                    isUpdateDesignationsLoading={isUpdateDesignationsLoading}
                    setEditDesignationId={setEditDesignationId}
                  />
                  <AddEducationModal
                    isAddEducationModalVisible={isAddEducationModalVisible}
                    setIsAddEducationModalVisible={
                      setIsAddEducationModalVisible
                    }
                    isCreateEducationLoading={isCreateEducationLoading}
                    isEditEducationModalVisible={isEditEducationModalVisible}
                    editEducationId={editEducationId}
                    setIsEditEducationModalVisible={
                      setIsEditEducationModalVisible
                    }
                    selectedEducation={selectedEducation[0]}
                    setEditEducationId={setEditEducationId}
                    isUpdateEducationLoading={isUpdateEducationLoading}
                  />
                  <AddIndustryModal
                    isEditIndustryModalVisible={isEditIndustryModalVisible}
                    isAddIndustryModalVisible={isAddIndustryModalVisible}
                    setIsAddIndustryModalVisible={setIsAddIndustryModalVisible}
                    setIsEditIndustryModalVisible={
                      setIsEditIndustryModalVisible
                    }
                    editIndustryId={editIndustryId}
                    selectedIndustry={selectedIndustry[0]}
                    setEditIndustryId={setEditIndustryId}
                    isCreateIndustryLoading={isCreateIndustryLoading}
                    isUpdateIndustryLoading={isUpdateIndustryLoading}
                  />
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        {tabKey === "1" && (
          <Row justify="space-around">
            <Col span={23}>
              <DesignationTable
                setIsEditDesignationModalVisible={
                  setIsEditDesignationModalVisible
                }
                setEditDesignationId={setEditDesignationId}
                editDesignationId={editDesignationId}
                allDesignations={allDesignations}
                isGetDesignationsLoading={isGetDesignationsLoading}
              />
            </Col>
          </Row>
        )}
        {tabKey === "2" && (
          <Row justify="space-around">
            <Col span={23}>
              <Nativetable
                setEditNativeId={setEditNativeId}
                setIsEditNativeModalVisible={setIsEditNativeModalVisible}
                allCommunitiesNative={allCommunitiesNative}
                isCommunitiesNativeLoading={isCommunitiesNativeLoading}
              />
            </Col>
          </Row>
        )}
        {tabKey === "3" && (
          <Row justify="space-around">
            <Col span={23}>
              <EducationTable
                allEducations={allEducations}
                isEducationsLoading={isEducationsLoading}
                isEditEducationModalVisible={isEditEducationModalVisible}
                setIsEditEducationModalVisible={setIsEditEducationModalVisible}
                setEditEducationId={setEditEducationId}
              />
            </Col>
          </Row>
        )}
        {tabKey === "4" && (
          <Row justify="space-around">
            <Col span={23}>
              <IndustryTable
                allIndustries={allIndustries}
                setEditIndustryId={setEditIndustryId}
                setIsEditIndustryModalVisible={setIsEditIndustryModalVisible}
              />
            </Col>
          </Row>
        )}
        {tabKey === "5" && (
          <Row justify="space-around">
            <Col span={23}>
              <Settings />
            </Col>
          </Row>
        )}
        {tabKey === "6" && (
          <Row justify="space-around">
            <Col span={23}>
              <VolunteerTable setTabKey={setTabKey} />
            </Col>
          </Row>
        )}
        {tabKey === "8" && (
          <Row justify="space-around">
            <Col span={23}>
              <MeetingTable
                setEditMeetingId={setEditMeetingId}
                setIseditMeetingModalVisible={setIseditMeetingModalVisible}
                allMeetings={allMeetings}
                isCommunityMeetingsLoading={isCommunityMeetingsLoading}
                isPublishMeeting={isPublishMeeting}
                setIsPublishMeeting={setIsPublishMeeting}
              />
            </Col>
          </Row>
        )}

        {tabKey === "9" && (
          <Row justify="space-around">
            <Col span={23}>
              <DocumnetTable
                allDocuments = {allDocuments}
              />
            </Col>
          </Row>
        )}

        {tabKey === "7" && <HandleUsers />}
      </>
    </div>
  );
};

export default Admin;
