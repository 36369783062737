import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Spin,
  Typography,
  Modal,
  Input,
  message,
} from "antd";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import {
  createDesignation,
  updateDesignation,
  getDesignations,
} from "../../Actions/designationsAction";

import { designationSchema } from "../../Utils/Schema";
import { yupResolver } from "@hookform/resolvers/yup";
import "../../FindThePeoplePage/FindOtherPeople.css";
import "../AddFamilymember/Addfamily.css";


const AddDesignationModal = (props) => {
  const {
    isAddDesignationModalVisible,
    setIsAddDesignationModalVisible,
    isEditDesignationModalVisible,
    setIsEditDesignationModalVisible,
    selectedDesignation,
    editDesignationId,
    isCreateDesignationsLoading,
    isUpdateDesignationsLoading,
    setEditDesignationId,
  } = props;

  const [preloadedDesignationValues, setPreloadedDesignationValues] =
    useState("");
  const dispatch = useDispatch();

  const {
    handleSubmit,
    formState: { errors },
    control,

    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(designationSchema),

    defaultValues: preloadedDesignationValues,
  });
  useEffect(() => {
    setPreloadedDesignationValues(selectedDesignation);
    reset(selectedDesignation);
  }, [selectedDesignation, reset]);
  //   if(isEditDesignationModalVisible){

  //   setValue("designation_title", isEditDesignationModalVisible && selectedDesignation?.designation_title)

  // }

  const closeAddDesignationModalMethod = () => {
    setIsAddDesignationModalVisible(false);

    setValue("designation_title", "");
    setEditDesignationId("");
    reset();
  };

  const createAddDesignationMethod = (data) => {
    data.published = true;
    data.community_id = process.env.REACT_APP_COMMUNITY_ID;
    dispatch(createDesignation(data, successCreateDesignationMethod));
  };

  const successCreateDesignationMethod = () => {
    setIsAddDesignationModalVisible(false);
    dispatch(getDesignations(process.env.REACT_APP_COMMUNITY_ID));
    setValue("designation_title", "");

    reset();
    setTimeout(() => {
      message.success("Your designation added successfully");
    }, 1000);
  };

  const closeEditDesignationModalMethod = () => {
    setIsEditDesignationModalVisible(false);
    setValue("designation_title", "");
    setEditDesignationId("");
    reset();
  };
  const updateDesignationMethod = (data) => {
    data.id = editDesignationId;
    data.community_id = process.env.REACT_APP_COMMUNITY_ID;
    dispatch(updateDesignation(data, successUpdateDesignationMethod));
  };
  const successUpdateDesignationMethod = () => {
    setIsEditDesignationModalVisible(false);
    setValue("designation_title", "");
    reset();
    dispatch(getDesignations(process.env.REACT_APP_COMMUNITY_ID));
    setTimeout(() => {
      message.success("Your designation updated successfully");
    }, 1000);
  };
  useEffect(() => {
    if (isAddDesignationModalVisible) {
      setValue("designation_title", "");
    }
  }, [setValue, isAddDesignationModalVisible]);
  return (
    <Modal
      visible={
        isEditDesignationModalVisible
          ? isEditDesignationModalVisible
          : isAddDesignationModalVisible
      }
      onCancel={
        isEditDesignationModalVisible
          ? closeEditDesignationModalMethod
          : closeAddDesignationModalMethod
      }
      footer={false}
      title={
        isEditDesignationModalVisible
          ? `${"Edit"} ${"Designation"}`
          : "Add Designation"
      }
    >
      <Spin
        spinning={
          isEditDesignationModalVisible
            ? isUpdateDesignationsLoading
            : isCreateDesignationsLoading
        }
        size="large"
      >
        <form
          onSubmit={handleSubmit(
            isEditDesignationModalVisible
              ? updateDesignationMethod
              : createAddDesignationMethod
          )}
        >
          <Row gutter={[0, 10]}>
            <Col span={24}>
              <Typography className="label" style={{ paddingBottom: "4px" }}>
              Designation Title
              </Typography>
              <Controller
                as={<Input className="inputLabel" />}
                name="designation_title"
                control={control}
              />
              {errors.designation_title && (
                <p style={{ color: "red" }}>
                  {errors.designation_title.message}
                </p>
              )}
            </Col>
            {/* <Col span={24}>
                <Typography className="label" style={{ paddingBottom: "4px" }}>
                    Designation Parent
                </Typography>
                <Controller
                  as={isEditDesignationModalVisible ?  <Input className="inputLabel"  required/> : <Input className="inputLabel"/>}
                  name="designation_parent"
                  control={control}
                />
                {isAddDesignationModalVisible && errors.designation_parent && (
                  <p style={{ color: "red" }}>{errors.designation_parent.message}</p>
                )}
               
                </Col> */}
            {/* <Col span={24}>
                <Typography className="label" style={{ paddingBottom: "4px" }}>
                    Level
                </Typography>
                <Controller
                  as={isEditDesignationModalVisible ?  <Input className="inputLabel"  required/> : <Input className="inputLabel"/>}
                  name="level"
                  control={control}
                />
                {isAddDesignationModalVisible && errors.level && (
                  <p style={{ color: "red" }}>{errors.level.message}</p>
                )}
                
                </Col> */}
            <Col span={24}>
              <Row justify="end" gutter={[10, 0]}>
                <Col>
                  <Button
                    onClick={
                      isEditDesignationModalVisible
                        ? closeEditDesignationModalMethod
                        : closeAddDesignationModalMethod
                    }
                    className="allButtonsCancel"
                  >
                    Cancel
                  </Button>
                </Col>
                <Col>
                  <Button htmlType="submit" className="allButtons">
                    Save
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </form>
      </Spin>
    </Modal>
  );
};

export default AddDesignationModal;
