import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Spin,
  Typography,
  Modal,
  Input,
  message,
} from "antd";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import { educationSchema } from "../../Utils/Schema";
import "../../FindThePeoplePage/FindOtherPeople.css";
import { yupResolver } from "@hookform/resolvers/yup";
import "../AddFamilymember/Addfamily.css";
import {
  createEducationAction,
  getEducationsAction,
  updateEducationAction,
} from "../../Actions/educationAction";


const AddEducationModal = (props) => {
  const {
    isAddEducationModalVisible,
    setIsAddEducationModalVisible,
    isCreateEducationLoading,
    isEditEducationModalVisible,
    editEducationId,
    setIsEditEducationModalVisible,
    selectedEducation,
    setEditEducationId,
    isUpdateEducationLoading,
  } = props;
  const dispatch = useDispatch();

  const [preloadedEducationValues, setPreloadedEucationValues] = useState("");
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(educationSchema),
    defaultValues: preloadedEducationValues,
  });
  useEffect(() => {
    setPreloadedEucationValues(selectedEducation);
    reset(selectedEducation);
  }, [selectedEducation, reset]);
  const handleCreateEducationMethod = (data) => {
    data.published = true;

    dispatch(createEducationAction(data, successCreateEducationMethod));
  };
  const successCreateEducationMethod = () => {
    setIsAddEducationModalVisible(false);
    setTimeout(() => {
      message.success("Your Education Added Successfully");
    }, 1000);
    setValue("name", "");
    dispatch(getEducationsAction());
    reset();
  };

  const closeAddEducationMethod = () => {
    setIsAddEducationModalVisible(false);

    setValue("name", "");
    setEditEducationId("");
    reset();
  };
  const handleEditEducationMethod = (data) => {
    data.id = editEducationId;
    dispatch(updateEducationAction(data, successUpdateEditEducationMethod));
  };

  const successUpdateEditEducationMethod = () => {
    setIsEditEducationModalVisible(false);
    setTimeout(() => {
      message.success("Your Education Edited Successfully");
    }, 1000);
    setValue("name", "");
    reset();
    dispatch(getEducationsAction());
  };
  const closeEditEducationMethod = () => {
    setIsEditEducationModalVisible(false);
    setValue("name", "");
    setEditEducationId("");

    reset();
  };
  useEffect(() => {
    if (isAddEducationModalVisible) {
      setValue("name", "");
    }
  }, [setValue, isAddEducationModalVisible]);
  return (
    <Modal
      visible={
        isEditEducationModalVisible
          ? isEditEducationModalVisible
          : isAddEducationModalVisible
      }
      title={isEditEducationModalVisible ? "Edit Education" :"Add Education"}
      footer={null}
      width={400}
      onCancel={
        isEditEducationModalVisible
          ? closeEditEducationMethod
          : closeAddEducationMethod
      }
    >
      <form
        onSubmit={handleSubmit(
          isEditEducationModalVisible
            ? handleEditEducationMethod
            : handleCreateEducationMethod
        )}
      >
        <Spin
          spinning={
            isEditEducationModalVisible
              ? isUpdateEducationLoading
              : isCreateEducationLoading
          }
          size="large"
        >
          <Row gutter={[0, 10]}>
            <Col span={24}>
              <Typography className="label" style={{ paddingBottom: "4px" }}>
              Education Name
              </Typography>

              <Controller
                as={<Input className="inputLabel" />}
                name="name"
                control={control}
              />
              {errors.name && (
                <p style={{ color: "red" }}>{errors.name.message}</p>
              )}
            </Col>
            <Col span={24}>
              <Row justify="end" gutter={[10, 0]}>
                <Col>
                  <Button
                    className="allButtonsCancel"
                    onClick={
                      isEditEducationModalVisible
                        ? closeEditEducationMethod
                        : closeAddEducationMethod
                    }
                  >
                    Cancel
                  </Button>
                </Col>

                <Col>
                  <Button htmlType="submit" className="allButtons">
                    {isEditEducationModalVisible ? "Edit" :"Save"}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Spin>
      </form>
    </Modal>
  );
};
export default AddEducationModal;
