import React, { useEffect } from "react";
import {
  Row,
  Col,
  // Card,
  Typography,
  // Divider,
  // Avatar,
  Button,
  // Empty,
  Spin,
  Tooltip,
  // Popconfirm,
  message,
} from "antd";
import { history } from "../../Utils/history";

import "../../FindThePeoplePage/FindOtherPeople.css";
import { useDispatch, useSelector } from "react-redux";
// import { history } from "../../Utils/history";

import {
  deleteMatrimonial,
  // getMatrimonialMatchesAction,
  updateMatrimonialProfileStatusAction,
  getMatrimonialSuperAdmin,
  matrimonialById
} from "../../Actions/matrimonialAction";
import moment from "moment";
import {
  // EditOutlined,
  // DeleteOutlined,
  // EyeOutlined,
  // EyeInvisibleOutlined,
  ProfileOutlined,
  TeamOutlined,
} from "@ant-design/icons";
// import matrimonialEmpty from "../../assets/matrimonialEmpty.svg";
// import _ from "lodash";
import "react-image-gallery/styles/css/image-gallery.css";
// import ImageGallery from "react-image-gallery";
import CustomTable from "../ReuseableComponents/CustomTable/CustomTable";
import {
  ChangeStatusColumnAction,
  DeleteColumnAction,
  EditColumnAction,
} from "../ReuseableComponents/CustomTable/CustomTableColumnActions";
const ViewMatrimonialProfile = (props) => {
  const {
    setEditId,
    setVisible,
    setViewMatrimonialProfileId,
    setIsViewMyMatchesVisible,
    currentPage,
    // currentMatchesPage,
    setSelectedUserId,
    viewMatrimonialProfileId
  } = props;
  const {
    allMatrimonialsSuperAdmin,
    // isMatrimonialLoading,
    setCommunityId,
    isMatrimonialSuperAdminLoading,
  } = useSelector((state) => {
    const {
      allMatrimonialsSuperAdmin,
      isMatrimonialLoading,
      isMatrimonialSuperAdminLoading,
    } = state.matrimonial;
    const { setCommunityId } = state.communities;

    return {
      allMatrimonialsSuperAdmin,
      isMatrimonialLoading,
      setCommunityId,
      isMatrimonialSuperAdminLoading,
    };
  });
  const handleEdit = (id) => {
    setEditId(id);
    setVisible(true);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(matrimonialById(viewMatrimonialProfileId));
  }, []);
  // const handleDeleteFamily = (matrimonialId) => {
  //   dispatch(deleteMatrimonial(matrimonialId, SuccessDeleteCallbackMethod));
  // };
  const SuccessDeleteCallbackMethod = () => {
    setTimeout(() => {
      message.success("Your matrimonial profile deleted successfully.");
    }, 1000);
    dispatch(getMatrimonialSuperAdmin(currentPage, setCommunityId));
  };
  useEffect(() => {
    dispatch(getMatrimonialSuperAdmin(currentPage, setCommunityId));
  }, [currentPage]);
  const handleClick = (matrimonialProfileId,Id) => {
    history.push(`/matrimonialDetails/${matrimonialProfileId}`, { id: matrimonialProfileId });

    setViewMatrimonialProfileId(matrimonialProfileId);
    setIsViewMyMatchesVisible(false);
    dispatch(matrimonialById(matrimonialProfileId));
    dispatch({
      type: "GET_SELECTED_MATRIMONIAL_ID",
      payload: matrimonialProfileId,
    });
  };
  const handleViewMyMatchesMethod = (matrimonialProfileId, userId) => {
    setViewMatrimonialProfileId(matrimonialProfileId);
    setIsViewMyMatchesVisible(true);
    setSelectedUserId(userId);
  };

  // let profileStatusData = {};
  // const handleProfileStatusMethod = (Id, Status) => {
  //   profileStatusData.id = Id;
  //   profileStatusData.profile_status =
  //     Status === "Active" ? "in-active" : "active";
  //   dispatch(
  //     updateMatrimonialProfileStatusAction(
  //       profileStatusData,
  //       successMatrimonialProfileStatusMethod
  //     )
  //   );
  // };
  const successMatrimonialProfileStatusMethod = () => {
    setTimeout(() => {
      message.success("Your matrimonial profile status updated successfully.");
    }, 1000);
    dispatch(getMatrimonialSuperAdmin(currentPage, setCommunityId));
  };

  // const images = [
  //   {
  //     original: "https://picsum.photos/id/400/1000/600/",
  //     thumbnail: "https://picsum.photos/id/1018/250/150/",
  //   },
  //   {
  //     original: "https://picsum.photos/id/1015/1000/600/",
  //     thumbnail: "https://picsum.photos/id/1015/250/150/",
  //   },
  //   {
  //     original: "https://picsum.photos/id/1019/1000/600/",
  //     thumbnail: "https://picsum.photos/id/1019/250/150/",
  //   },
  //   {
  //     original: "https://picsum.photos/id/1015/1000/600/",
  //     thumbnail: "https://picsum.photos/id/1015/250/150/",
  //   },
  //   {
  //     original: "https://picsum.photos/id/1015/1000/600/",
  //     thumbnail: "https://picsum.photos/id/1015/250/150/",
  //   },
  //   {
  //     original: "https://picsum.photos/id/1015/1000/600/",
  //     thumbnail: "https://picsum.photos/id/1015/250/150/",
  //   },
  //   {
  //     original: "https://picsum.photos/id/1015/1000/600/",
  //     thumbnail: "https://picsum.photos/id/1015/250/150/",
  //   },
  // ];
  // const renderRightNav = (onClick, disabled) => {
  //   <RightNav onClick={onClick} disabled={disabled} />;
  // };

  const matrimonialColumns = [
    {
      title: "Name",
      // dataIndex: "first_name",
      width: "26.66%",
      render: ({first_name, user,id}) => {
        return (
         <Typography onClick={() => handleClick(id)} > {user.first_name +
          " " +
          user.last_name}</Typography>
          
        );
      },
    },
  //   render: (id,{first_name,allMatrimonialsList}) => {
  //     return (
  //       <Row>
  //         {/* <EditColumnAction id={id} customMethod={openEditDrawer} /> */}
  //         <Tooltip title="View Profile">
  //           <Typography onClick={() => handleClick(id)} >{ allMatrimonialsList.user.first_name}</Typography>
  //         </Tooltip>
  //       </Row>
  //     );
  //   },
  
  //   sorter: (a, b) => (a.first_name.localeCompare(b.first_name)),
  //   sortDirections: ['descend', 'ascend'],
  // },
    {
      title: "Birth Date",
      dataIndex: "birth_date",
      width: "26.66%",
      render: (birth_date, allMatrimonialsList) => {
        return moment(
          allMatrimonialsList.user.birth_month +
            "/" +
            allMatrimonialsList.user.birth_date +
            "/" +
            allMatrimonialsList.user.birth_year
        ).format("Do MMMM YYYY");
      },
    },
    {
      title: "Profile Status",
      dataIndex: "profile_status",
      width: "20%",
    },
    {
      title: "Action",
      key: "action",
      render: ({ id, profile_status, user }) => {
        return (
          <Row>
            <Col>
              {/* <Button type="link" onClick={() => handleEdit(id)}>
                Edit
              </Button> */}
              <EditColumnAction id={id} customMethod={handleEdit} />
            </Col>
            <Col>
              {/* <Popconfirm
                title={`${
                  profile_status === "Active" ? "Inactive" : "Active"
                } this profile ?`}
                onConfirm={() => handleProfileStatusMethod(id, profile_status)}
                okText="Ok"
                cancelText="Cancel"
                placement="topLeft"
              > */}
              <ChangeStatusColumnAction
                id={id}
                status={profile_status}
                actionArray={[updateMatrimonialProfileStatusAction]}
                customMethod={successMatrimonialProfileStatusMethod}
                type="ProfileStatus"
              />
              {/* </Popconfirm> */}
            </Col>
            <Col>
              <Tooltip title="View Profile">
                <Button type="text" onClick={() => handleClick(id)}>
                  {/* {t("btn.26")} */}
                  <ProfileOutlined style={{fontSize:20,color:'gray'}}/>
                  {/* <ProfileOutlined /> */}
                </Button>
              </Tooltip>
            </Col>
            {profile_status === "Active" && (
              <Col>
                <Tooltip title="View Matches">
                  <Button
                    type="text"
                    onClick={() => handleViewMyMatchesMethod(true, user?.id)}
                  >
                    <TeamOutlined style={{fontSize:20,color:'gray'}}/>
                  </Button>
                </Tooltip>
              </Col>
            )}
            <Col>
              <DeleteColumnAction
                id={id}
                actionArray={[deleteMatrimonial]}
                customMethod={SuccessDeleteCallbackMethod}
              />
            </Col>
          </Row>
        );
      },
    },
  ];
  return (
    <Spin spinning={isMatrimonialSuperAdminLoading} size="large">
      {/* {allMatrimonialsSuperAdmin &&
      allMatrimonialsSuperAdmin?.matrimonials?.length > 0 ? ( */}
        <Row gutter={[32, 32]}>
          <Col span={24}>
            <CustomTable
              type="Matrimonial"
              columns={matrimonialColumns}
              data={allMatrimonialsSuperAdmin?.matrimonials}
            />
          </Col>
          {allMatrimonialsSuperAdmin?.matrimonials?.map((val, i) => {
            // const date = moment(
            //               `${val.user.birth_month}-${val.user.birth_date}-${val.user.birth_year}`,
            //               "MM-DD-YYYY"
            //             );
            // const noData1 = _.flatten(
            //   val?.user?.user_addresses?.filter((address, i) => {
            //     return address.length && val.user.user_addresses[i].length === 0
            //       ? 0
            //       : 1;
            //   })
            // );
            // const Images = val?.matrimonial_user_images?.map((val, i) => {
            //   return {
            //     original: val.url,
            //     // thumbnail: val.matrimonial_user_images?.[0]?.url,
            //   };
            // });
            return (
              <Col xl={24} lg={12} md={12} sm={24} xs={24} key={i}>
                {/* <Card className="MatchesCard">
                  {val?.matrimonial_user_images.length > 0 && (
                    <Row>
                      <Col span={24}>
                        <ImageGallery
                          items={Images}
                          additionalClass="ImageViewMatrimonial"
                          showNav={false}
                          showBullets={true}
                          showThumbnails={false}
                          originalClass="ImageViewMatrimonial"
                        />
                      </Col>
                    </Row>
                  )}
                  <div style={{ padding: "24px" }}>
                    <Row gutter={[0, 17]}>
                      <Col span={24}>
                        <Typography
                          className="profile-name"
                          style={{
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {val.user.first_name + " " + val.user.last_name}
                        </Typography>
                      </Col>
                      <Col span={24}>
                        <Row justify="space-between" align="middle">
                          <Col>
                            <Typography className="profile-birthdate">
                              {moment(
                                val.user.birth_month +
                                  "/" +
                                  val.user.birth_date +
                                  "/" +
                                  val.user.birth_year
                              ).format("Do MMMM YYYY")}
                            </Typography>
                          </Col>
                          <Col>
                            <Tooltip title={t("btn.27")}>
                              <Button
                                icon={<EditOutlined />}
                                type="text"
                                onClick={() => handleEdit(val.id, val)}
                              />
                            </Tooltip>

                            <Tooltip title="Profile Status">
                              <Popconfirm
                                title={`${
                                  val.profile_status === "Active"
                                    ? "Inactive"
                                    : "Active"
                                } this profile ?`}
                                onConfirm={() =>
                                  handleProfileStatusMethod(
                                    val.id,
                                    val.profile_status
                                  )
                                }
                                okText="Ok"
                                cancelText={t("btn.19")}
                                placement="topLeft"
                              >
                                <Button
                                  icon={
                                    val.profile_status === "Active" ? (
                                      <EyeInvisibleOutlined />
                                    ) : (
                                      <EyeOutlined />
                                    )
                                  }
                                  type="text"
                                />
                              </Popconfirm>
                            </Tooltip>

                            <Tooltip title={t("btn.28")}>
                              <Popconfirm
                                title={t("btn.29")}
                                onConfirm={() => handleDeleteFamily(val.id)}
                                okText={t("btn.28")}
                                cancelText={t("btn.19")}
                                placement="topLeft"
                              >
                                <Button icon={<DeleteOutlined />} type="text" />
                              </Popconfirm>
                            </Tooltip>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row gutter={[0, 16]}>
                      <Divider />
                    </Row>
                    <Row>
                      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <Typography className="profile-address">
                          {noData1?.length > 0 &&
                            noData1?.map((Address, i) => {
                              return (
                                <Row key={i}>
                                  <Col span={24}>
                                    {Address?.address_line_1 +
                                      " " +
                                      Address.home_number +
                                      " " +
                                      Address.address_line_2 +
                                      " " +
                                      Address.city +
                                      "," +
                                      Address.zip}
                                  </Col>
                                </Row>
                              );
                            })}
                        </Typography>
                      </Col>
                      <Col span={24}>
                        <Row justify="space-between">
                          <Col>
                            {val?.profile_status === "Active" && (
                              <Button
                                className=" View-fullProfile"
                                onClick={() =>
                                  handleViewMyMatchesMethod(true, val?.user?.id)
                                }
                                style={{ padding: "4px 0px" }}
                              >
                                {t("btn.31")}
                              </Button>
                            )}
                          </Col>
                          <Col>
                            <Button
                              className=" View-fullProfile"
                              onClick={() => handleClick(val.id)}
                              style={{ padding: "4px 0px" }}
                            >
                              {t("btn.26")}
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </Card> */}
              </Col>
            );
          })}
        </Row>
      {/* // ) : (
      //   !isMatrimonialLoading && (
      //     <Row justify="center" style={{ paddingTop: "150px" }}>
      //       <Col>
      //         <Empty
      //           image={matrimonialEmpty}
      //           imageStyle={{
      //             height: 38,
      //           }}
      //           description={
      //             <span className="empty-details">{t("emptyScreen.3")}</span>
      //           }
      //         ></Empty>
      //       </Col>
      //     </Row>
      //   )
      // )} */}
    </Spin>
  );
};
export default ViewMatrimonialProfile;

