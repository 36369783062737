import React, { useEffect, useState } from "react";
import { Spin, Table, message, Select, Pagination } from "antd";
import "./Admin.css";
import "../../FindThePeoplePage/FindOtherPeople.css";
import { useDispatch, useSelector } from "react-redux";

import { getCommunitiesNative } from "../../Actions/communitiesNativeAction";
import { getSearchUser } from "../../Actions/searchUserAction";

import {
  createNativeVolunteerAction,
  deleteNativeVolunteerAction,
} from "../../Actions/nativeVolunteerAction";


const VolunteerTable = (props) => {

  const dispatch = useDispatch();

  const {
    allCommunitiesNative,
    isCreateNativeVolunteerLoading,

    isDeleteNativeVolunteerLoading,
  } = useSelector((state) => {
    const { allCommunitiesNative } = state.communitiesNative;
    const { searchUsers } = state.searchUser;
    const {
      allCommunitiesNativeVolunteer,
      isCreateNativeVolunteerLoading,
      isDeleteNativeVolunteerLoading,
    } = state.nativeVolunteers;
    return {
      allCommunitiesNative,
      searchUsers,

      allCommunitiesNativeVolunteer,
      isCreateNativeVolunteerLoading,
      isDeleteNativeVolunteerLoading,
    };
  });
  useEffect(() => {
    dispatch(getCommunitiesNative(process.env.REACT_APP_COMMUNITY_ID));
    dispatch(getSearchUser("api/user_search"));
  }, [dispatch]);
  // const volunteerUsers = allCommunitiesNative.map((val) => {
  //   return val.natives_volunteers_users;
  // });

  const onlyPublishedNatives = allCommunitiesNative.filter((NativesList) => {
    return NativesList.published === true;
  });

  let data = {};
  const [volunteerId, setVolunteerId] = useState("");
  const createVolunteerMethod = (UserId, CommunityNative) => {
    data.community_native_id = CommunityNative;
    data.user_id = UserId;
    dispatch(
      createNativeVolunteerAction(data, successCreateNativeVolunteerMethod)
    );
  };
  const successCreateNativeVolunteerMethod = (SuccessResponse) => {
    setVolunteerId(SuccessResponse.id);
    setTimeout(() => {
      message.success("Volunteer Added Successfully");
    }, 1000);
    dispatch(getCommunitiesNative(process.env.REACT_APP_COMMUNITY_ID));
  };

  const deleteVolunteerMethod = (VolunteerName, NativeId) => {
    if (VolunteerName?.props?.children[1]?.props?.children === undefined) {
      dispatch(
        deleteNativeVolunteerAction(
          volunteerId,
          successDeleteNativeVolunteerMathod
        )
      );
    } else {
      dispatch(
        deleteNativeVolunteerAction(
          VolunteerName?.props?.children[1]?.props?.children,
          successDeleteNativeVolunteerMathod
        )
      );
    }
  };

  const successDeleteNativeVolunteerMathod = () => {
    setTimeout(() => {
      message.success("Volunteer Deleted Successfully");
    }, 1000);
    dispatch(getCommunitiesNative(process.env.REACT_APP_COMMUNITY_ID));
  };

  const columns = [
    {
      title: "Native",
      dataIndex: "name",
      width: "20%",
    },
    {
      title: "Users",

      width: "30%",
      render: ({ id, natives_volunteers_users, native_users, ...p }) => {
        return (
          <div key={id}>
            <Select
              mode="multiple"
              placeholder="Select user"
              style={{
                width: "100%",
                borderRadius: "4px",
              }}
              className="inputLabel"
              showSearch={true}
              // options={SearchUsersByFirstName}
              options={
                native_users &&
                native_users.length > 0 &&
                native_users.map((val, i) => {
                  return {
                    label: val.first_name + " " + val.last_name,
                    value: val.id,
                  };
                })
              }
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onSelect={(e) => createVolunteerMethod(e, id)}
              showArrow={true}
              defaultValue={natives_volunteers_users.map((user) => {
                const userName = (
                  <>
                    {user.volunteer.first_name + " " + user.volunteer.last_name}

                    <div style={{ width: "1px" }}>{user.id}</div>
                  </>
                );

                return userName;
              })}
              onDeselect={(e) => deleteVolunteerMethod(e, id)}
            ></Select>
          </div>
        );
      },
    },
  ];
  return (
    <Spin
      spinning={
        isCreateNativeVolunteerLoading
          ? isCreateNativeVolunteerLoading
          : isDeleteNativeVolunteerLoading
      }
      size="large"
      style={{ zIndex: "100" }}
    >
      <div className="AdminTable">
        <Table
          size="middle"
          className="table"
          columns={columns}
          dataSource={onlyPublishedNatives}
          style={{ zIndex: "1" }}
          // pagination={false}
        />
      </div>
    </Spin>
  );
};

export default VolunteerTable;
