import { Row, Col, Button, Pagination,Breadcrumb} from "antd";
import React, {  useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import MyProfileModalForm from "./MyProfileModalForm";
import ViewMatrimonialProfile from "./ViewMatrimonialProfile";
import ViewMyMatches from "./ViewMyMatches";
import MatrimonialProfileDetails from "./MatrimonialProfileDetails";
import { useSelector, useDispatch } from "react-redux";
import { history } from "../../Utils/history";
// import CustomTable from "../ReuseableComponents/CustomTable/CustomTable";
// import moment from "moment";
// import { DeleteColumnAction } from "../ReuseableComponents/CustomTable/CustomTableColumnActions";
// import {
//   deleteMatrimonial,
//   getMatrimonialMatchesAction,
//   updateMatrimonialProfileStatusAction,
//   getMatrimonialSuperAdmin,
// } from "../../Actions/matrimonialAction";
import { getCommunityById } from "../../Actions/communitiesAction";

const schema = yup.object().shape({});
const MatrimonialPage = () => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [editId, setEditId] = useState("");
  const [viewMatrimonialProfileId, setViewMatrimonialProfileId] = useState("");
  const [isViewMyMatchesVisible, setIsViewMyMatchesVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentMatchesPage, setCurrentMatchesPage] = useState(1);
  const [selectedUserId, setSelectedUserId] = useState("");
  const {} = useForm({
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });
  const {
    allMatrimonialsSuperAdmin,
    // isMatrimonialLoading,
    matrimonialMatches,
    // community,
    // currentUser,
    // setCommunityId,
  } = useSelector((state) => {
    const {
      allMatrimonialsSuperAdmin,
      isMatrimonialLoading,
      matrimonialMatches,
    } = state.matrimonial;
    const { community, setCommunityId } = state.communities;
    const { currentUser } = state.authentication;

    return {
      allMatrimonialsSuperAdmin,
      isMatrimonialLoading,
      matrimonialMatches,
      community,
      currentUser,
      setCommunityId,
    };
  });
  const { total_length } = allMatrimonialsSuperAdmin || {};

  // const isMatrimonialVisibleByAdmin = community?.show_matrimonial;
  // const redirectMethod = () => {
  //   history.push("/add_family");
  // };

  // useEffect(() => {
  //   if (
  //     currentUser?.verified === false
  //       ? true
  //       : isMatrimonialVisibleByAdmin === false
  //   ) {
  //     redirectMethod();
  //   }
  // }, [isMatrimonialVisibleByAdmin]);

  // const height = window.innerHeight - 64;

  // const businessColumns = [
  //   {
  //     title: "Company Name",
  //     dataIndex: "company_name",
  //     width: "20%",
  //     // render: (first_name, allUsersList) => {
  //     //   return allUsersList?.is_poused ? (
  //     //     <div className="hidedRow">{first_name}</div>
  //     //   ) : (
  //     //     first_name
  //     //   );
  //     // },
  //   },

  //   {
  //     title: "Experience",
  //     dataIndex: "experience",
  //     width: "20%",
  //   },
  // ];
  const onClickCommunity = () => {
    history.push("/communities");
    dispatch(getCommunityById(""));
    dispatch({
      type: "GET_SELECTED_COMMUNITY_ID",
      payload: "",
    });
  };
  return (
    <Row gutter={[32, 32]} 
    className="community"
    >
        
        <Col span={24}></Col>
        <Col xl={14} lg={14} md={14} sm={20} xs={20}>
              <Breadcrumb 
              // style={{ margin: 10 }}
              >
                <Breadcrumb.Item
                  onClick={() => onClickCommunity()}
                  className="breadcrumCommunity"
                >
                  Communities
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  // onClick={() => onClickDocument()}
                  className="breadcrumUsers"
                >
                 Matrimonial
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            <Col xl={10} lg={10} md={10} sm={8} xs={8}>
          <Row justify="end" gutter={[8, 8]}>
          <Col xl={12} lg={12} md={18} sm={24} xs={24}>
            {!viewMatrimonialProfileId && (
              <Button
                onClick={() => setVisible(true)}
                className="button-search"
                type="primary"
                style={{ width: "100%" }}
              >
                Create Matrimonial Profile
              </Button>
            )}
            <MyProfileModalForm
              visible={visible}
              setVisible={setVisible}
              editId={editId}
              setEditId={setEditId}
              currentPage={currentPage}
            />
              {/* </Spin> */}
            </Col>
         </Row>
         </Col>
        {/* </Row> */}
        {/* <Row > */}
          <Col span={24}>
            {viewMatrimonialProfileId ? (
              <>
                {isViewMyMatchesVisible ? (
                  <>
                    <ViewMyMatches
                      viewMatrimonialProfileId={viewMatrimonialProfileId}
                      setViewMatrimonialProfileId={setViewMatrimonialProfileId}
                      setIsViewMyMatchesVisible={setIsViewMyMatchesVisible}
                      currentMatchesPage={currentMatchesPage}
                      selectedUserId={selectedUserId}
                    />

                    <Row>
                      <Col span={24}>
                        <Row justify="end" 
                        // style={{ margin: "10px 0px" }}
                        >
                          <Pagination
                            total={matrimonialMatches?.total_length}
                            onChange={(e) => setCurrentMatchesPage(e)}
                          />
                        </Row>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <MatrimonialProfileDetails
                    editId={editId}
                    setEditId={setEditId}
                    visible={visible}
                    setVisible={setVisible}
                    viewMatrimonialProfileId={viewMatrimonialProfileId}
                    setViewMatrimonialProfileId={setViewMatrimonialProfileId}
                  />
                )}
              </>
            ) : (
              <>
                <ViewMatrimonialProfile
                  editId={editId}
                  setEditId={setEditId}
                  visible={visible}
                  setVisible={setVisible}
                  viewMatrimonialProfileId={viewMatrimonialProfileId}
                  setViewMatrimonialProfileId={setViewMatrimonialProfileId}
                  setIsViewMyMatchesVisible={setIsViewMyMatchesVisible}
                  currentPage={currentPage}
                  currentMatchesPage={currentMatchesPage}
                  setSelectedUserId={setSelectedUserId}
                />
                <Row>
                {allMatrimonialsSuperAdmin && total_length > 0 ? (
                  <Col span={24}>
                    <Row justify="center" 
                    // style={{ margin: "20px 0px" }}
                    >
                      <Pagination
                        total={allMatrimonialsSuperAdmin?.total_length}
                        onChange={(e) => setCurrentPage(e)}
                      />
                    </Row>
                  </Col>
                   ):""}
                </Row>
               
              </>
            )}
          </Col>
        {/* </Row> */}
   </Row>
  );
};
export default MatrimonialPage;
