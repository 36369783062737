import React, { useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  Typography,
  Select,
  Spin,
  message,
} from "antd";
import { Controller, useForm } from "react-hook-form";
import {
  createTrustee,
  updateTrustee,
  getTrustees,
} from "../../Actions/trusteesAction";
import { useDispatch, useSelector } from "react-redux";
import { trusteeSchema, trusteeEditSchema } from "../../Utils/Schema";
import { yupResolver } from "@hookform/resolvers/yup";
import "../AddFamilymember/Addfamily.css";
import "./../../FindThePeoplePage/FindOtherPeople.css";

import { getSearchUser } from "../../Actions/searchUserAction";
import { getDesignations } from "../../Actions/designationsAction";
const TrusteeModal = (props) => {
  const {
    isCreateTrusteeModalVisible,
    setIsCreateTrusteeModalVisible,
    trustee,
    isUpdateTrusteeModalVisible,
    setIsUpdateTrusteeModalVisible,
    trusteeArray,
    isCreateTrusteesLoading,
    editTrusteeId,
    isUpdateTrusteesLoading,
  } = props;
  const { Option } = Select;
  const dispatch = useDispatch();
  const { searchUsers, allDesignations } = useSelector((state) => {
    const { searchUsers } = state.searchUser;
    const { allDesignations } = state.designations;

    return {
      searchUsers,
      allDesignations,
    };
  });
  useEffect(() => {
    dispatch(getSearchUser("api/user_search?type=regular"));
    dispatch(getDesignations(process.env.REACT_APP_COMMUNITY_ID));
  }, [dispatch]);
  const {
    handleSubmit,

    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(
      isCreateTrusteeModalVisible ? trusteeSchema : trusteeEditSchema
    ),
  });

  // const designationList = [

  //   { label: "Designation 1", value: "Designation 1" },
  //   { label: "Designation 2", value: "Designation 2" },
  //   { label: "Designation 3", value: "Designation 3" },
  // ];

  if (isUpdateTrusteeModalVisible) {
    setValue("designation_id", trusteeArray?.designation?.designation_title);
  }
  const hideCreateTrusteeModal = () => {
    setIsCreateTrusteeModalVisible(false);
    reset();
  };
  const createTrusteeMethod = (data) => {
    data.community_id = process.env.REACT_APP_COMMUNITY_ID;
    dispatch(createTrustee(data, successCreateTrusteeMethod));
  };
  const successCreateTrusteeMethod = () => {
    setIsCreateTrusteeModalVisible(false);
    setValue("user_id", "");
    reset();
    setTimeout(() => {
      message.success("Trustee created successfully");
    }, 1000);
    dispatch(getTrustees(process.env.REACT_APP_COMMUNITY_ID));
  };
  const hideUpdateTrusteeModal = () => {
    setIsUpdateTrusteeModalVisible(false);
    reset();
  };
  const containsNumber = (str) => {
    return /\d/.test(str);
  };
  const updateTrusteeMethod = (data) => {
    data.id = editTrusteeId;

    data.user_id = trustee.id;
    data.designation_id = containsNumber(data.designation_id)
      ? data.designation_id
      : trusteeArray?.designation.id;
    dispatch(updateTrustee(data, successUpdateTrusteeMethod));
  };

  const successUpdateTrusteeMethod = () => {
    setIsUpdateTrusteeModalVisible(false);

    setTimeout(() => {
      message.success("Birth Date is required");
    }, 1000);
    reset();
    dispatch(getTrustees(process.env.REACT_APP_COMMUNITY_ID));
  };

  const onlyPublishedDesignations = allDesignations.filter(
    (Designations) => Designations.published === true
  );
  return (
    <Modal
      visible={
        isUpdateTrusteeModalVisible
          ? isUpdateTrusteeModalVisible
          : isCreateTrusteeModalVisible
      }
      footer={null}
      onCancel={
        isUpdateTrusteeModalVisible
          ? hideUpdateTrusteeModal
          : hideCreateTrusteeModal
      }
      title={
        isUpdateTrusteeModalVisible ? (
          <Typography>Update Trustee</Typography>
        ) : (
          <Typography>Create Trustee</Typography>
        )
      }
      width={500}
    >
      <Spin
        spinning={
          isUpdateTrusteeModalVisible
            ? isUpdateTrusteesLoading
            : isCreateTrusteesLoading
        }
        size="large"
      >
        <form
          onSubmit={handleSubmit(
            isUpdateTrusteeModalVisible
              ? updateTrusteeMethod
              : createTrusteeMethod
          )}
        >
          <Col span={24} style={{ paddingBottom: "15px" }}>
            <Row gutter={[10, 0]}>
              {isCreateTrusteeModalVisible && (
                <Col span={12}>
                  <Typography
                    className="label"
                    style={{ paddingBottom: "4px" }}
                  >
                   Select user
                  </Typography>
                  <Controller
                    as={
                      <Select
                        placeholder="Select user"
                        style={{ width: "100%", borderRadius: "4px" }}
                        className="inputLabel"
                        showSearch={true}
                        // options={SearchUsersByFirstName}
                        options={
                          searchUsers &&
                          searchUsers.length > 0 &&
                          searchUsers.map((val, i) => {
                            return {
                              label: val.first_name + " " + val.last_name,
                              value: val.id,
                            };
                          })
                        }
                        filterOption={(input, option) =>
                          option.label
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      ></Select>
                    }
                    name="user_id"
                    control={control}
                  />
                  {errors.user_id && (
                    <p style={{ color: "red" }}>User must be selected</p>
                  )}
                </Col>
              )}
              <Col span={isUpdateTrusteeModalVisible ? 24 : 12}>
                <Typography className="label" style={{ paddingBottom: "4px" }}>
                Designation
                </Typography>
                <Controller
                  as={
                    <Select
                      style={{ width: "100%" }}
                      placeholder="Select Designation"
                      // options={designationList}
                      className="inputLabel"
                    >
                      {onlyPublishedDesignations?.map((val, i) => {
                        return (
                          <Option value={val.id} key={i}>
                            {val.designation_title}
                          </Option>
                        );
                      })}
                    </Select>
                  }
                  name="designation_id"
                  control={control}
                />
                {errors.designation_id && (
                  <p style={{ color: "red" }}>Designation must be selected</p>
                )}
              </Col>
            </Row>
          </Col>
          <Col>
            <Row gutter={[10, 0]} justify="end">
              <Col>
                <Button
                  onClick={
                    isUpdateTrusteeModalVisible
                      ? hideUpdateTrusteeModal
                      : hideCreateTrusteeModal
                  }
                  className="allButtonsCancel"
                >
                 Cancel
                </Button>
              </Col>
              <Col>
                {isUpdateTrusteeModalVisible ? (
                  <Button htmlType="submit" className="allButtons">
                    Update
                  </Button>
                ) : (
                  <Button htmlType="submit" className="allButtons">
                   Create
                  </Button>
                )}
              </Col>
            </Row>
          </Col>
        </form>
      </Spin>
    </Modal>
  );
};
export default TrusteeModal;
