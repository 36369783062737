import React from 'react';
import { Card, Typography, Row, Col } from 'antd';
import "./Admin.css";
import "./ViewMeetings.css"

function ViewMeetings (props) {

    const { selectedMeeting } = props;
    return(
        <div className="site-card-border-less-wrapper">
            <Row gutter={[0, 15]}>
                <Col xl={10} lg={10} md={10} sm={22} xs={22}>
                <Card
                    className='MeetingCard'
                    title={selectedMeeting?.title}
                    bordered={false}
                    style={{
                        width: 300,
                        fontSize: "0.9rem",
                        fontFamily: "", 
                        marginTop: "30px"
                    }}
                >
                    <Typography><b>Number :  </b>{selectedMeeting?.number}</Typography>
                    <Typography><b>Title :  </b>{selectedMeeting?.title}</Typography>
                    <Typography><b>Place :  </b>{selectedMeeting?.place}</Typography>
                    <Typography><b>Description :  </b>{selectedMeeting?.description}</Typography>
                    <Typography><b>Financial Year :  </b>{selectedMeeting?.year}</Typography>
                </Card>
                </Col>
            </Row>
        </div>
    )
}

export default ViewMeetings
