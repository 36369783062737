import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Tooltip,
  Spin,
  Pagination,
  Breadcrumb,
  Tabs,
  Typography,
} from "antd";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { getSearchUser } from "../../Actions/searchUserAction";
import CustomTable from "../ReuseableComponents/CustomTable/CustomTable";
import UsersDrawer from "./UsersDrawer";
import { history } from "../../Utils/history";
import { getCommunityById } from "../../Actions/communitiesAction";
import { getFamilies } from "../../Actions/familyAction";
import moment from "moment";
import { getBusiness } from "../../Actions/businessAction";

const Users = (props) => {
  const { setValName, setSelectedUser } = props;
  const dispatch = useDispatch();
  const { TabPane } = Tabs;
  const [tabKey, setTabkey] = useState("1");

  const { searchUsers, isSearchUserLoading, setCommunityId } = useSelector(
    (state) => {
      const { setCommunityId } = state.communities;
      const { searchUsers, isSearchUserLoading } = state.searchUser;
      return {
        isSearchUserLoading,
        setCommunityId,
        searchUsers,
      };
    }
  );

  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    dispatch(
      getSearchUser(
        `api/user_search?community_id=${setCommunityId}&page=${currentPage}`
      ),
      getFamilies(
        `api/families?community_id=${setCommunityId}&page=${currentPage}`
      ),
      getBusiness(
        `api/business_profiles?community_id=${setCommunityId}&page=${currentPage}`
      )
    );
  }, [currentPage, setCommunityId]);
  const { total_length } = searchUsers || {};
  const [isUsersDrawerVisible, setIsUsersDrawerVisible] = useState(false);
  const [editId, setEditId] = useState("");
  const openEditDrawer = (Id) => {
    setIsUsersDrawerVisible(true);
    setEditId(Id);
    // history.push(`/communitytrustee/${Id}`, { Id: Id });
    history.push(`/users/${Id}`, { id: Id });
    // history.push(`/communities/users/${Id}`, { id: Id });
  };
  const openFamilyByIdMethod = (Id) => {
    history.push(`/families/${Id}`, { id: Id });
  };
  const openBusinessyByIdMethod = (Id) => {
    history.push(`/communitybusiness/${Id}`, { id: Id });
  };

  const usersColumns = [
    {
      title: "Full Name",
      dataIndex: "id",
      width: "15%",
      render: (id, { first_name, last_name }) => {
        return (
          <Row>
            <Tooltip title="View Profile">
              <Typography onClick={() => openEditDrawer(id)}>
                {first_name} {last_name}
              </Typography>
            </Tooltip>
          </Row>
        );
      },

      sorter: (a, b) => a.first_name.localeCompare(b.first_name),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Native",
      dataIndex: "native",
      width: "10%",
      render: (val) => {
        return _.uniq(val, (Name) => {
          return Name;
        }).join(",");
      },
      // sorter: (a, b) => a.val.localeCompare(b.val),
      sorter: (a, b) =>
        a.native?.map((val) => {
          return val.localeCompare(
            b.native?.map((val) => {
              return val;
            })
          );
        }),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Contact Numbe",
      dataIndex: "phone_with_country_code",
      width: "10%",
    },
    {
      title: "Family Number",
      dataIndex: "families_info",
      width: "10%",
      render: (id, { families_info }) => {
        return (
          <Row>
            <Tooltip title="View Full Family Detail">
              {families_info?.map((val, i) => {
                return (
                  <Typography
                    onClick={() => openFamilyByIdMethod(val?.family_id)}
                  >
                    {val?.family_number}
                  </Typography>
                );
              })}
            </Tooltip>
          </Row>
        );
      },
    },
    {
      title: "Name of Business/Job",
      dataIndex: "business_profiles",
      width: "15%",
      render: (id, { business_profiles }) => {
        return (
          <Row>
            <Tooltip title="View Full Business Detail">
              {business_profiles?.map((val, i) => {
                return (
                  <Typography onClick={() => openBusinessyByIdMethod(val?.id)}>
                    {val?.company_name}
                  </Typography>
                );
              })}
            </Tooltip>
          </Row>
        );
      },

      // sorter: (a, b) => a.val?.company_name.localeCompare(b.val?.company_name),
      // sortDirections: ["descend", "ascend"],
    },
    {
      title: "Number of Logins",
      dataIndex: "no_of_logins",
      width: "10%",
      sorter: (a, b) => a.no_of_logins - b.no_of_logins,
    },
    {
      title: "Create Date",
      width: "10%",
      render: (data) => {
        const created_at = moment.utc(data.created_at).format("L");
        return <>{created_at}</>;
      },
      sorter: (a, b) => a.created_at.localeCompare(b.created_at),
      sortDirections: ["descend", "ascend"],
    },

    {
      title: "Status",
      dataIndex: "id",
      width: "10%",
      
      render: (id, { verified, is_poused }) => {
        return (
          <Row>
            <Col>
              {is_poused === true ? (
                <Typography>blocked</Typography>
              ) : (
                <Typography>
                  {verified === true ? (
                    <Typography>verified</Typography>
                  ) : (
                    <Typography>unverified</Typography>
                  )}
                </Typography>
              )}
            </Col>
          </Row>
        );
      },
      sorter: (a, b) => a.verified-b.verified,
      sortDirections: ["descend", "ascend"],
    },
  ];
  const onClickCommunity = () => {
    history.push("/communities");
    dispatch(getCommunityById(""));
    dispatch({
      type: "GET_SELECTED_COMMUNITY_ID",
      payload: "",
    });
  };
  // const handleVerifiedUser = () => {
  //   dispatch(
  //     getSearchUser(
  //       `api/user_search?community_id=${setCommunityId}&page=${currentPage}&verified=true`
  //     )
  //   );
  // };

  const onClickCommunity2 = () => {
    setSelectedUser("");
    setValName("");
  };
  // const handleOpenCommunity2 = (Id) => {
  //   setValName(Id);
  //   setSelectedUser(true);
  // };
  const onTabClick = (key) => {
    setTabkey(key);
    if (key === "1") {
      dispatch(
        getSearchUser(
          `api/user_search?community_id=${setCommunityId}&page=${currentPage}`
        )
      );
    } else if (key === "2") {
      dispatch(
        getSearchUser(
          `api/user_search?community_id=${setCommunityId}&page=${currentPage}&verified=true`
        )
      );
    } else if (key === "3") {
      dispatch(
        getSearchUser(
          `api/user_search?community_id=${setCommunityId}&page=${currentPage}&verified=false`
        )
      );
    } else if (key === "4") {
      dispatch(
        getSearchUser(
          `api/user_search?community_id=${setCommunityId}&page=${currentPage}&blocked=true`
        )
      );
    }
  };
  const items = [
    {
      key: "1",
      label: (
        <Button
          className={tabKey === "1" ? "selectedButton" : "unselectedButton"}
        >
          Users ({searchUsers.all_users_count})
        </Button>
      ),
    },
    {
      key: "2",
      label: (
        <Button
          className={tabKey === "2" ? "selectedButton" : "unselectedButton"}
        >
          Verified Users ({searchUsers.verified_users_count})
        </Button>
      ),
    },
    {
      key: "3",
      label: (
        <Button
          className={tabKey === "3" ? "selectedButton" : "unselectedButton"}
        >
          Unverified Users ({searchUsers.unverified_users_count})
        </Button>
      ),
    },
    {
      key: "4",
      label: (
        <Button
          className={tabKey === "4" ? "selectedButton" : "unselectedButton"}
        >
          Blocked Users ({searchUsers.blocked_users_count})
        </Button>
      ),
    },
  ];
  return (
    <Spin spinning={isSearchUserLoading} size="large">
      <Row
        gutter={[32, 32]}
        className="community"
        // style={{ margin: 10 }}
      >
        <Col span={24}></Col>
        <Col xl={16} lg={16} md={16} sm={24} xs={24}>
          <Breadcrumb
          // style={{ margin: 10 }}
          >
            <Breadcrumb.Item
              onClick={() => onClickCommunity()}
              className="breadcrumCommunity"
            >
              Communities
            </Breadcrumb.Item>
            <Breadcrumb.Item
              onClick={() => onClickCommunity2()}
              className="breadcrumUsers"
            >
              users
            </Breadcrumb.Item>
            <Breadcrumb.Item className="breadcrumUsers">
              {/* {the_name[0]?.first_name} */}
              {/* {searchUsers.users[0].first_name} */}
            </Breadcrumb.Item>
            {/* );
          })}  */}
          </Breadcrumb>
        </Col>

        <Col xl={8} lg={8} md={8} sm={24} xs={24}>
          <Row justify="end" gutter={[8, 8]}>
            <Col xl={16} lg={16} md={16} sm={16} xs={16}>
              <Row justify="end">
                <Button
                  type="primary"
                  onClick={() => setIsUsersDrawerVisible(true)}
                >
                  Add Users
                </Button>
                <UsersDrawer
                  isUsersDrawerVisible={isUsersDrawerVisible}
                  editId={editId}
                  setIsUsersDrawerVisible={setIsUsersDrawerVisible}
                  setEditId={setEditId}
                  currentPage={currentPage}
                />
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <Tabs
                items={items}
                defaultActiveKey="1"
                onTabClick={onTabClick}
                animated={false}
                renderTabBar={(props, DefaultTabBar) => (
                  <DefaultTabBar {...props} className="cMTabsBckground">
                    {(node) => (
                      <div key={node.key} index={node.key}>
                        {node}
                      </div>
                    )}
                  </DefaultTabBar>
                )}
              >
              
              </Tabs>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <CustomTable
            columns={usersColumns}
            data={searchUsers?.users}
            // data={tabKey === "1" ? searchUsers?.users :  tabKey === "2" ? filteredVerifiedUser : tabKey === "3" ? filteredUnVerifiedUser :tabKey === "4" ? filteredBlockedUser :[]}
          ></CustomTable>
        </Col>
        {searchUsers && total_length > 0 ? (
          <Col span={23}>
            <Row
              justify="center"
              //  style={{"margin": (IsMD || IsLG || IsXL) ? "75vh" : "100%"}}>
              //  style={{ margin: "160px 0px" }}
            >
              <Pagination
                total={searchUsers?.total_length}
                onChange={(e) => setCurrentPage(e)}
                showSizeChanger={false}
              />
            </Row>
          </Col>
        ) : (
          ""
        )}
      </Row>
    </Spin>
  );
};
export default Users;
