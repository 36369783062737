import React, { useState } from "react";
import {
  Row,
  Col,
  Typography,
  Card,
  Avatar,
  Popconfirm,
  Tooltip,
  message,
  Divider,
} from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { getFamilies } from "../../Actions/familyAction";
import AddFamilyMember from "./addFamilyMemberModal";
import moment from "moment";
import "./Addfamily.css";
import "../../FindThePeoplePage/FindOtherPeople.css";
import ViewBusinessProfile from "../../FindThePeoplePage/BusinessProfiles/ViewBusinessProfile";
import BusinessProfileDetails from "../../FindThePeoplePage/BusinessProfiles/BusinessProfileDetails";
import ViewJobProfile from "../../FindThePeoplePage/JobProfile/ViewJobProfile";
import "../../Events/EventCard.css";
import { deleteUserFamily } from "../../Actions/userFamilyAction";
import JobProfileDetails from "../../FindThePeoplePage/JobProfile/JobProfileDetails";
const ViewMemberDetails = (props) => {
  const { allFamilies } = useSelector((state) => {
    const { allFamilies } = state.family;

    return {
      allFamilies,
    };
  });
  const {
    selectedMemberId,
    setSelectedMemberId,
    setIsViewMemberDetails,
    peopleDetail,
    isPeopleDetailsVisible,
    selectedFamilyId,
    secondColor,
  } = props;
  // const the_member = _.filter(membersArray?.[0], (member) => {
  //   return member.id === selectedMemberId;
  // });

  const family = allFamilies?.filter((family) => {
    return family.id === selectedFamilyId;
  });
  const { user_families } = family[0] ? family[0] : [];
  const the_member = user_families?.filter((mem) => {
    return mem.id === selectedMemberId;
  });

  const {
    first_name,
    education,
    industry,
    birth_date,
    birth_month,
    birth_year,
    image,
    id: userId,
  } = the_member?.[0]?.user || {};
  const dispatch = useDispatch();
  const [isUpdateMemberModalVisible, setIsUpdateMemberModalVisible] =
    useState(false);
  const [selectedBusinessProfileId, setSelectedBusinessProfileId] =
    useState("");
  const [
    isSelectedBusinessProfileVisible,
    setIsSelectedBusinessProfileVisible,
  ] = useState(false);
  const [selectedJobProfileId, setSelectedJobProfileId] = useState("");

  const [isSelectedJobProfileVisible, setIsSelectedJobProfileVisible] =
    useState(false);
  const deleteMem = (memberId) => {
    dispatch(deleteUserFamily(memberId, DeleteCallBack));
  };
  const DeleteCallBack = () => {
    setSelectedMemberId("");
    setIsViewMemberDetails(false);
    setTimeout(() => {
      message.success("Your family member deleted successfully");
    }, 1000);
    dispatch(getFamilies());
  };
  const handleUpdateMemberModal = () => {
    setIsUpdateMemberModalVisible(true);
  };

  const { business_profiles } = peopleDetail || {};

  const selectedBusiness = business_profiles?.filter((business) => {
    return business.id === selectedBusinessProfileId;
  });
  const selectedJobProfile = business_profiles?.filter((Businesses) => {
    return Businesses.id === selectedJobProfileId;
  });
  const color = "#" + Math.floor(Math.random() * 1677).toString(14);
  const onlyBusinessData = business_profiles?.filter((Businesses) => {
    return Businesses.is_business === true;
  });
  const onlyJobProfileData = business_profiles?.filter((Businesses) => {
    return Businesses.is_business === false;
  
  });
  return (
    <>
      {isSelectedBusinessProfileVisible ? (
        <BusinessProfileDetails
          selectedBusiness={selectedBusiness[0]}
          isSelectedBusinessProfileVisible={isSelectedBusinessProfileVisible}
        />
      ) : isSelectedJobProfileVisible ? (
        <JobProfileDetails
          selectedJobProfile={selectedJobProfile[0]}
          isSelectedJobProfileVisible={isSelectedJobProfileVisible}
        />
      ) : (
        <Row gutter={[0, 15]}>
          <Col xl={13} xs={24} lg={13} md={20} sm={24}>
            <Card className="familyDetailsCard">
              {!isPeopleDetailsVisible && (
                <Row justify="end" gutter={[30, 10]}>
                  <Col span={24} />
                  <Col>
                    <Tooltip title="Edit">
                      <EditOutlined onClick={() => handleUpdateMemberModal()} />
                    </Tooltip>
                    <AddFamilyMember
                      isUpdateMemberModalVisible={isUpdateMemberModalVisible}
                      setIsUpdateMemberModalVisible={
                        setIsUpdateMemberModalVisible
                      }
                      the_member={the_member[0]}
                    />
                  </Col>

                  <Col>
                    <Popconfirm
                      onConfirm={() => deleteMem(the_member?.[0]?.id)}
                      title="Are you sure?"
                      okText="Delete"
                      placement="topRight"
                      cancelText="Cancel"
                    >
                      <Tooltip title="Delete">
                        <DeleteOutlined />
                      </Tooltip>
                    </Popconfirm>
                  </Col>
                </Row>
              )}
              <Row gutter={[0, { xs: 10 , sm:10}]}>
                <Col xl={7} sm={24} md={7} xs={24} lg={9}>
                  <Avatar
                    shape="circle"
                    size={145}
                    style={{ backgroundColor: secondColor || color }}
                    src={image}
                  >

                    <Typography style={{ fontSize: "70px", color: "white" }}>
                      {first_name?.[0]}
                      {peopleDetail?.first_name?.[0]}
                    </Typography>
                  </Avatar>
                </Col>
                <Col span={1}/>
                <Col xl={16} sm={24} md={15} lg={13}>
                  <Row gutter={[17, 15]}>
                    <Col
                      span={24}
                      className="title"
                      style={{ fontSize: "21px" }}
                    >
                      {first_name}
                      {peopleDetail?.first_name} {peopleDetail?.last_name}
                    </Col>

                    <Col span={24} />
                  </Row>
                  {!isPeopleDetailsVisible && (
                    <Col
                      span={24}
                      className="memberStyle"
                      style={{ padding: "0px" }}
                    >
                      {birth_year === null
                        ? "Edit your data"
                        : moment(birth_year).fromNow("years old")}{" "}
                      {birth_year && "old"}
                    </Col>
                  )}
                  <Row gutter={[0, 5]}>
                    <Col span={24} />
                    <Col>
                      <Row gutter={[5, 0]}>
                        <Col>
                          <Typography
                            className="newMemberDetails"
                            style={{ fontSize: "14px" }}
                          >
                            {isPeopleDetailsVisible
                              ? "Email"
                              : "Education"}
                            :
                          </Typography>
                        </Col>
                        <Col>
                          <Typography className="newMemberSubDetails">
                            {isPeopleDetailsVisible
                              ? peopleDetail?.email
                              : education === null
                              ? "Edit your data"
                              : education?.name}
                          </Typography>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <Row gutter={[5, 0]}>
                        <Col>
                          <Typography className="newMemberDetails">
                            {isPeopleDetailsVisible
                              ?"Contact"
                              : "Birth Date"}
                            :
                          </Typography>
                        </Col>
                        <Col>
                          <Typography className="newMemberSubDetails">
                            {isPeopleDetailsVisible ? (
                              peopleDetail?.phone
                            ) : birth_date === null ? (
                              "Edit your data"
                            ) : (
                              <>
                                {birth_date}/{birth_month}/{birth_year}
                              </>
                            )}
                          </Typography>
                        </Col>
                      </Row>
                    </Col>

                    <Col span={24}>
                      <Row gutter={[5, 0]}>
                        <Col>
                          <Typography className="newMemberDetails">
                            {isPeopleDetailsVisible
                              ?  "Industry"
                              :  "Industry"}
                            :
                          </Typography>
                        </Col>
                        <Col>
                          <Typography className="newMemberSubDetails">
                            {isPeopleDetailsVisible
                              ? "Industry"
                              : industry === null
                              ? "Edit your data"
                              : industry?.name}
                          </Typography>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {/* <Row gutter={[0, 35]}>
          <Col span={24} />
          <Col
            xl={10}
            lg={10}
            md={16}
            sm={19}
            xs={24}
            style={{ backgroundColor: "#F5F6F8", borderRadius: "5px" }}
          >
            {isPeopleDetailsVisible ? (
              <PageHeader
                  title={t("content.35")}
                  className="pageHeaderDetails"
                  extra={[
                    <Typography className="pageHeaderSubDetails">
                    {peopleDetail?.email}        
                    </Typography>,
                  ]}
                ></PageHeader>
            ) : (
              <Row justify="space-around" style={{ padding: "13px 6px" }}>
                <Col>
                  <Typography className="familyMemberDetailsCard">{t("content.18")}</Typography>
                </Col>
                <Col />
                <Col />
                <Col>
                  <Typography className="memberStyle">
                    {education_type}
                  </Typography>
                </Col>
              </Row>
            )}
          </Col>
        </Row> */}

              {/* <Row gutter={[0, 35]}>
          <Col span={24} />
          <Col
            xl={10}
            lg={10}
            md={16}
            sm={19}
            xs={24}
            style={{ backgroundColor: "#F5F6F8", borderRadius: "5px" }}
          >
            {isPeopleDetailsVisible ? (
              
              <PageHeader
              title={t("content.34")}
              className="pageHeaderDetails"
              extra={[
                <Typography className="pageHeaderSubDetails">
                 {peopleDetail?.phone}            
                </Typography>,
              ]}
            ></PageHeader>
            ) : (
              <Row justify="space-around" style={{ padding: "13px 6px" }}>
                <Col>
                  <Typography className="familyMemberDetailsCard">{t("content.20")}</Typography>
                </Col>
                <Col />
                <Col />
                <Col>
                  <Typography className="memberStyle">
                    {birth_date}/{birth_month}/{birth_year}
                  </Typography>
                </Col>
              </Row>
            )}
          </Col>
        </Row> */}

              {/* <Row gutter={[0, 35]}>
          <Col span={24} />
          <Col
            xl={10}
            lg={10}
            md={16}
            sm={19}
            xs={24}
            style={{ backgroundColor: "#F5F6F8", borderRadius: "5px" }}
          >
            {isPeopleDetailsVisible ? (
           
            <PageHeader
                  title={t("content.80")}
                  className="pageHeaderDetails"
                  extra={[
                    <Typography className="pageHeaderSubDetails">
                      IT
                    </Typography>,
                  ]}
                ></PageHeader>
            )
            :
            <Row justify="space-around" style={{ padding: "13px 6px" }}>
              <Col className="familyMemberDetailsCard">{t("content.80")}</Col>
              <Col />
              <Col />
              <Col className="memberStyle">{industry}</Col>
            </Row>
            }
          </Col>
        </Row> */}
            </Card>
          </Col>
          {isPeopleDetailsVisible && onlyBusinessData.length > 0 && (
            <Col span={24}>
              <Divider className="Divider">Business Profile</Divider>
            </Col>
          )}
          {isPeopleDetailsVisible && onlyBusinessData?.length > 0 && (
            <Col xl={24}>
              <ViewBusinessProfile
                business_profiles={onlyBusinessData}
                isPeopleDetailsVisible={isPeopleDetailsVisible}
                setSelectedBusinessProfileId={setSelectedBusinessProfileId}
                setIsSelectedBusinessProfileVisible={
                  setIsSelectedBusinessProfileVisible
                }
              />
            </Col>
          )}
          {isPeopleDetailsVisible && onlyJobProfileData.length > 0 && (
            <Col span={24}>
              <Divider className="Divider">Job Profiles</Divider>
            </Col>
          )}
          {isPeopleDetailsVisible && onlyJobProfileData.length > 0 && (
            <Col span={24}>
              <ViewJobProfile
                business_profiles={onlyJobProfileData}
                setSelectedJobProfileId={setSelectedJobProfileId}
                isPeopleDetailsVisible={isPeopleDetailsVisible}
                setIsSelectedJobProfileVisible={setIsSelectedJobProfileVisible}
              />
            </Col>
          )}
        </Row>
      )}
    </>
  );
};
export default ViewMemberDetails;
