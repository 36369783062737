import React, { useState, useEffect } from "react";
import {
  Modal,
  Col,
  Row,
  Input,
  Typography,
  Button,
  Spin,
  message,
  Drawer,
} from "antd";
import { Controller, useForm } from "react-hook-form";
import {
  createFamilyAddressAction,
  updateFamilyAddressAction,
} from "../../Actions/familyAddressAction";
import { useDispatch, useSelector } from "react-redux";
import { getFamilies, getFamilyById } from "../../Actions/familyAction";
import "./Addfamily.css";
import "./../../FindThePeoplePage/FindOtherPeople.css";
import _ from "lodash";
const AddAddressModal = (props) => {
  const {
    isAddAddressModalVisible,
    setIsAddAddressModalVisible,
    editFamilyAddressId,
    setIsEditAddressModalVisible,
    familyIdFromObject,
    allFamilyAddress,
    isUpdateAddress,
    setEditFamilyAddressId,
  } = props;
  const selectedFamilyAddress = _.filter(allFamilyAddress, (val) => {
    return val.id === editFamilyAddressId;
  });
  const [preloadedAddressValue, setPreloadedAddressValue] = useState("");
  const { isCreateFamilyAddressLoading, isUpdateFamilyAddressLoading } =
    useSelector((state) => {
      const { isCreateFamilyAddressLoading, isUpdateFamilyAddressLoading } =
        state.familyAddresses;
      return {
        isCreateFamilyAddressLoading,
        isUpdateFamilyAddressLoading,
      };
    });

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    clearErrors,
    setValue,
  } = useForm({
    defaultValues: preloadedAddressValue,
    // resolver: yupResolver(familyAddressSchema)
  });
  useEffect(() => {
    if (editFamilyAddressId) {
      setPreloadedAddressValue(selectedFamilyAddress[0]);
      reset(selectedFamilyAddress[0]);
    }
  }, [selectedFamilyAddress[0], reset]);
  const dispatch = useDispatch();
  const handleAddAddressMethod = (data) => {
    data.family_id = familyIdFromObject;
    dispatch(createFamilyAddressAction(data, successAddFamilyAddressMethod));
  };
  const successAddFamilyAddressMethod = () => {
    reset();
    setIsAddAddressModalVisible(false);
    setEditFamilyAddressId("");
    dispatch(getFamilyById(familyIdFromObject));
    setValue("home_number", "");
    setValue("landmark", "");
    setValue("city", "");
    setValue("state", "");
    setValue("zip", "");
    setValue("country", "");
    setValue("address_line_1", "");
    setValue("address_line_2", "");
    clearErrors();
    setTimeout(() => {
      message.success("Your address added successfully");
    }, 1000);
  };
  const hideAddAddressModal = () => {
    setIsAddAddressModalVisible(false);
    setEditFamilyAddressId("");

    setValue("home_number", "");
    setValue("landmark", "");
    setValue("city", "");
    setValue("state", "");
    setValue("zip", "");
    setValue("country", "");
    setValue("address_line_1", "");
    setValue("address_line_2", "");
    clearErrors();
  };
  // const hideEditAddressModal = () => {
  //   setIsEditAddressModalVisible(false);
  //   clearErrors();
  //   reset();
  // };
  const handleEditFamilyAddressMethod = (data) => {
    data.id = editFamilyAddressId;
    dispatch(updateFamilyAddressAction(data, successUpdateFamilyAddress));
  };

  const successUpdateFamilyAddress = () => {
    reset();
    setIsAddAddressModalVisible(false);
    setEditFamilyAddressId("");
    dispatch(getFamilyById(familyIdFromObject));
    setValue("home_number", "");
    setValue("landmark", "");
    setValue("city", "");
    setValue("state", "");
    setValue("zip", "");
    setValue("country", "");
    setValue("address_line_1", "");
    setValue("address_line_2", "");
    clearErrors();
    setTimeout(() => {
      message.success("Your address updated successfully");
    }, 1000);
  };
  const closeEditAddressDrawerMethod = () => {
    setIsAddAddressModalVisible(false);
    reset(selectedFamilyAddress[0]);
    clearErrors();
  };

  useEffect(() => {
    if (!editFamilyAddressId) {
      setEditFamilyAddressId("");
    }
  }, [setValue, !editFamilyAddressId]);
  return (
    <Drawer
      open={isAddAddressModalVisible}
      footer={null}
      onClose={hideAddAddressModal}
      title={
        editFamilyAddressId ? (
          <Typography>Edit Address</Typography>
        ) : (
          <Typography>Add Address</Typography>
        )
      }
    >
      <form
        onSubmit={handleSubmit(
          editFamilyAddressId
            ? handleEditFamilyAddressMethod
            : handleAddAddressMethod
        )}
      >
        <Spin
          spinning={
            isCreateFamilyAddressLoading || isUpdateFamilyAddressLoading
          }
          size="large"
        >
          <Row gutter={[0, 10]}>
            <Col span={24}>
              <Typography className="label" style={{ paddingBottom: "8px" }}>
              House Details
              </Typography>
              <Controller
                as={<Input required className="inputLabel" />}
                name="home_number"
                control={control}
              />
              {errors.home_number && (
                <p style={{ color: "red" }}>{errors.home_number.message}</p>
              )}
            </Col>
            <Col span={24}>
              <Typography className="label" style={{ paddingBottom: "8px" }}>
              Landmark
              </Typography>
              <Controller
                as={<Input required className="inputLabel" />}
                name="landmark"
                control={control}
              />
              {errors.landmark && (
                <p style={{ color: "red" }}>{errors.landmark.message}</p>
              )}
            </Col>
            <Col span={24}>
              <Row gutter={[10, 0]}>
                <Col span={12}>
                  <Typography
                    className="label"
                    style={{ paddingBottom: "8px" }}
                  >
                    House Address
                  </Typography>
                  <Controller
                    as={<Input required className="inputLabel" />}
                    name="address_line_1"
                    control={control}
                  />
                </Col>
                <Col span={12}>
                  <Typography
                    className="label"
                    style={{ paddingBottom: "8px" }}
                  >
                   Near By Address
                  </Typography>
                  <Controller
                    as={<Input required className="inputLabel" />}
                    name="address_line_2"
                    control={control}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[10, 0]}>
                <Col span={12}>
                  <Typography
                    className="label"
                    style={{ paddingBottom: "8px" }}
                  >
                    City
                  </Typography>
                  <Controller
                    as={<Input required className="inputLabel" />}
                    name="city"
                    control={control}
                  />
                  {errors.city && (
                    <p style={{ color: "red" }}>{errors.city.message}</p>
                  )}
                </Col>
                <Col span={12}>
                  <Typography
                    className="label"
                    style={{ paddingBottom: "8px" }}
                  >
                    State
                  </Typography>
                  <Controller
                    as={<Input required className="inputLabel" />}
                    name="state"
                    control={control}
                  />
                  {errors.state && (
                    <p style={{ color: "red" }}>{errors.state.message}</p>
                  )}
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[10, 0]}>
                <Col span={12}>
                  <Typography
                    className="label"
                    style={{ paddingBottom: "8px" }}
                  >
                    Zipcode
                  </Typography>
                  <Controller
                    as={<Input required className="inputLabel" />}
                    name="zip"
                    control={control}
                  />
                  {errors.zip && (
                    <p style={{ color: "red" }}>{errors.zip.message}</p>
                  )}
                </Col>
                <Col span={12}>
                  <Typography
                    className="label"
                    style={{ paddingBottom: "8px" }}
                  >
                   Country
                  </Typography>
                  <Controller
                    as={<Input className="inputLabel" />}
                    control={control}
                    name="country"
                  />
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row justify="end" gutter={[10, 0]}>
                <Col>
                  <Button
                    onClick={() => hideAddAddressModal()}
                    className="allButtonsCancel"
                  >
                   Cancel
                  </Button>
                </Col>
                {editFamilyAddressId ? (
                  <Col>
                    <Button htmlType="submit" className="allButtons">
                    Update
                    </Button>
                  </Col>
                ) : (
                  <Col>
                    <Button htmlType="submit" className="allButtons">
                    Add
                    </Button>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </Spin>
      </form>
      {/* </Spin> */}
    </Drawer>
  );
};

export default AddAddressModal;
