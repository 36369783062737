import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import {
  Modal,
  Input,
  Button,
  Typography,
  Row,
  Col,
  message,
  Select,
  Spin,
} from "antd";
import { Controller, useForm } from "react-hook-form";
import {
  createBusiness,
  getBusiness,
  updateBusiness,
} from "../../Actions/businessAction";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import _ from "lodash";
import { JobProfileSchema, UpdateJobProfileSchema } from "../../Utils/Schema";

const JobProfileModalForm = (props) => {
  const { visible, setVisible, editId, setEditId } = props;
  const dispatch = useDispatch();
  const {
    allBusiness,
    searchUsers,
    isCreateBusinessLoading,
    isUpdateBusinessLoading,
  } = useSelector((state) => {
    const {
      allBusiness,
      isBusinessLoading,
      isCreateBusinessLoading,
      isUpdateBusinessLoading,
    } = state.business;
    const { searchUsers } = state.searchUser;

    return {
      allBusiness,

      isBusinessLoading,
      searchUsers,
      isCreateBusinessLoading,
      isUpdateBusinessLoading,
    };
  });
  const the_business = _.filter(allBusiness, function (o) {
    return o.id === editId;
  });
  const [preloadedBusinessValue, setPreloadedBusinessValue] = useState(null);
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: preloadedBusinessValue,
    resolver: yupResolver(editId ? UpdateJobProfileSchema : JobProfileSchema),
  });

  useEffect(() => {
    setPreloadedBusinessValue(the_business[0]);
    reset(the_business[0]);
  }, [the_business[0], reset]);
  const userName =
    the_business &&
    the_business.length > 0 &&
    the_business[0]?.user?.first_name;
  const userId =
    the_business && the_business.length > 0 && the_business[0]?.user?.id;
  const userLastName =
    the_business && the_business.length > 0 && the_business[0]?.user?.last_name;
  const userFullName = userName + " " + userLastName;
  // const onFinish = (values) => {

  // };
  // const handleChangeUserId = (val) => {

  //   setSelectedUserId(val);
  // };
  if (editId) {
    setValue("user_id", userFullName);
  }
  const handleCancel = () => {
    setVisible(false);
    setEditId("");
    // setValue("user_id","");
    setValue("job_title", "");
    setValue("service_provide", "");
    setValue("working_here_since", "");
    setValue("business_location", "");

    setValue("experiance", "");
    setValue("business_details", "");
    setValue("company_name", "");
    clearErrors();
  };
  const handleCreateBusiness = (data) => {
    data.is_business = false;

    dispatch(
      createBusiness(data, successCreateBusiness, failureCreateBusiness)
    );
  };
  const successCreateBusiness = () => {
    // reset();
    setVisible(false);
    setValue("user_id", "");
    setValue("job_title", "");

    setValue("service_provide", "");
    setValue("working_here_since", "");
    setValue("business_location", "");
    setValue("experiance", "");
    setValue("business_details", "");
    setValue("company_name", "");
    setEditId("");
    dispatch(getBusiness());
    setTimeout(() => {
      message.success("Your Job Profile Created Successfully");
    }, 1000);
  };
  const failureCreateBusiness = () => {};
  const handleEditBusiness = (data) => {
    data.id = editId;

    // data.user_id = selectedUserId ? selectedUserId : userId
    data.user_id = userId;
    dispatch(updateBusiness(data, UpdateCallBack));
  };
  const UpdateCallBack = () => {
    setVisible(false);

    setEditId("");
    reset();

    dispatch(getBusiness());
    setTimeout(() => {
      message.success("Your Job Profile Updated Successfully");
    }, 1000);
  };

  useEffect(() => {
    if (!editId) {
      // setValue("user_id","");
      setValue("job_title", "");
      setValue("working_here_since", "");
      setValue("business_details", "");
      setValue("business_location", "");
      setValue("experiance", "");
      setValue("company_name", "");
      setEditId("");
    }
  }, [setValue, !editId]);

  return (
    <>
      <Modal
        title={
          editId ? `Edit Job Profile For ${userFullName}` : "Create Job Profile"
        }
        centered
        visible={visible}
        // onOk={() => setVisible(false)}
        onCancel={handleCancel}
        width={700}
        footer={null}
        className="matrimonialProfile"
      >
        <form
          onSubmit={handleSubmit(
            editId ? handleEditBusiness : handleCreateBusiness
          )}
        >
          <Spin
            spinning={
              editId ? isUpdateBusinessLoading : isCreateBusinessLoading
            }
            size="large"
          >
            <Row gutter={[8, 8]}>
              {!editId && (
                <Col span={12} className="formRows">
                  <Typography className="details" style={{ paddingBottom: 9 }}>
                  First Name
                  </Typography>
                  {/* <Controller
                as={<Input size="large" />}
                name="first_name"
                control={control}
                className="inputLabel"
              /> */}
                  <Controller
                    as={
                      <Select
                        // {...register('user_id')}
                        placeholder="Select user"
                        style={{ width: "100%", borderRadius: "4px" }}
                        size="large"
                        showSearch={true}
                        // defaultValue={editId && userName}
                        options={
                          searchUsers &&
                          searchUsers.length > 0 &&
                          searchUsers.map((val, i) => {
                            return {
                              label: val.first_name + " " + val.last_name,
                              value: val.id,
                            };
                          })
                        }
                        filterOption={(input, option) =>
                          option.label
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }

                        // onChange={(e) => handleChangeUserId(e)}
                      ></Select>
                    }
                    name="user_id"
                    control={control}
                  />
                  {errors.user_id && (
                    <p style={{ color: "red" }}>{errors.user_id.message}</p>
                  )}
                </Col>
              )}
              <Col span={editId ? 24 : 12} className="formRows">
                <Typography className="details" style={{ paddingBottom: 9 }}>
                  {/* {t("content.48")} */}
                  Job Title
                </Typography>
                <Controller
                  as={<Input size="large" />}
                  name="job_title"
                  control={control}
                  className="inputLabel"
                />
                {errors.job_title && (
                  <p style={{ color: "red" }}>{errors.job_title.message}</p>
                )}
              </Col>
            </Row>
            <Row gutter={[8, 8]}>
              <Col span={24} className="formRows">
                <Typography className="details" style={{ paddingBottom: 9 }}>
                  {/* {t("content.67")} */}
                  Company Name
                </Typography>
                <Controller
                  as={<Input size="large" />}
                  name="company_name"
                  control={control}
                  className="inputLabel"
                />
                {errors.company_name && (
                  <p style={{ color: "red" }}>{errors.company_name.message}</p>
                )}
              </Col>
            </Row>
            <Row gutter={[8, 8]}>
              <Col span={24} className="formRows">
                <Typography className="details" style={{ paddingBottom: 9 }}>
                  {/* {t("content.67")} */}
                  Working Here Since
                </Typography>
                <Controller
                  as={<Input size="large" />}
                  name="working_here_since"
                  control={control}
                  className="inputLabel"
                />
                {errors.working_here_since && (
                  <p style={{ color: "red" }}>
                    {errors.working_here_since.message}
                  </p>
                )}
              </Col>
            </Row>
            <Row gutter={[8, 8]}>
              <Col span={24} className="formRows">
                <Typography className="details" style={{ paddingBottom: 9 }}>
                  {/* {t("content.67")} */}
                  Job Details
                </Typography>
                <Controller
                  as={<Input size="large" />}
                  name="business_details"
                  control={control}
                  className="inputLabel"
                />
                {errors.business_details && (
                  <p style={{ color: "red" }}>
                    {errors.business_details.message}
                  </p>
                )}
              </Col>
            </Row>

            <Row gutter={[8, 8]}>
              <Col span={24} className="formRows">
                <Typography className="details" style={{ paddingBottom: 9 }}>
                Company Address
                </Typography>
                <Controller
                  as={<Input size="large" />}
                  name="business_location"
                  control={control}
                  className="inputLabel"
                />
                {errors.business_location && (
                  <p style={{ color: "red" }}>
                    {errors.business_location.message}
                  </p>
                )}
              </Col>
            </Row>

            <Row gutter={[8, 8]}>
              <Col span={24} className="formRows">
                <Typography className="details" style={{ paddingBottom: 9 }}>
                Experience
                </Typography>
                <Controller
                  as={<Input size="large" />}
                  name="experiance"
                  control={control}
                  className="inputLabel"
                />
                {errors.experiance && (
                  <p style={{ color: "red" }}>{errors.experiance.message}</p>
                )}
              </Col>
            </Row>

            <Row gutter={[8, 8]}>
              <Col xl={12} lg={12} md={12} sm={6} xs={6}></Col>
              <Col
                xl={12}
                lg={12}
                md={12}
                sm={18}
                xs={18}
                className="formRows"
                style={{ textAlign: "end" }}
              >
                {editId ? (
                  <Button htmlType="submit" className="modal-buttonAdd">
                    Update
                  </Button>
                ) : (
                  <Button htmlType="submit" className="modal-buttonAdd">
                   Add
                  </Button>
                )}
                <Button
                  className="modal-buttonCancel"
                  onClick={handleCancel}
                  // style={{ margin: "0 8px" }}
                >
                 Cancel
                </Button>
              </Col>
            </Row>
          </Spin>
        </form>
      </Modal>
    </>
  );
};
export default JobProfileModalForm;
