import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Spin,
  Breadcrumb,
  Button,
  message,
  Pagination,
  Tooltip,
  Popconfirm,
} from "antd";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { history } from "../../../Utils/history";
import { getContactusAction } from "../../../Actions/ContacusAction";
import { useForm } from "react-hook-form";
import { getCommunityById } from "../../../Actions/communitiesAction";
import CustomTable from "../../ReuseableComponents/CustomTable/CustomTable";
import { deleteUserContactusAction } from "../../../Actions/ContacusAction";
import Delete from "../../../assets/delete.svg";
import moment from "moment";

const Contactus = () => {
  const { isContactusLoading, allContactus } = useSelector((state) => {
    const { allContactus, isContactusLoading } = state.contactus;
    return {
      isContactusLoading,
      allContactus,
    };
  });
  const { total_length } = allContactus || {};

  const [valName, setValName] = useState("");
  const [selectedcontactus, setSelectedContactus] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const { reset } = useForm({});
  useEffect(() => {
    dispatch(getContactusAction(`api/contacts?page=${currentPage}`));
  }, [currentPage]);
  const onClickCommunity = () => {
    history.push("/communities");
    dispatch(getCommunityById(""));
    dispatch({
      type: "GET_SELECTED_COMMUNITY_ID",
      payload: "",
    });
  };
  const onClickContacts = () => {
    setSelectedContactus("");
    setValName("");
    reset();
  };

  const handleDeleteContacts = (Id) => {
    dispatch(deleteUserContactusAction(Id, successDelete, failureDelete));
  };
  const successDelete = () => {
    setTimeout(() => {
      message.success("Your Contacts Deleted Successfully");
    }, 1000);
    dispatch(getContactusAction(`api/contacts?page=${currentPage}`));
  };
  const failureDelete = () => {
    setTimeout(() => {
      message.error("Something went wrong.");
    }, 1000);
  };

  const Columns = [
    {
      title: " Name",
      dataIndex: "name",
      width: "10%",
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: " Community Name",
      dataIndex: "community_name",
      width: "10%",
      sorter: (a, b) => a.community_name.localeCompare(b.community_name),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Email",
      dataIndex: "email",
      width: "12%",
      sorter: (a, b) => a.email.localeCompare(b.email),
      sortDirections: ["descend", "ascend"],
    },
   
    {
      title: "Phone Number",
      width: "13%",
      render: (id, { country_code, phone }) => {
        return (
          <Row>
                {country_code}-{phone}
          </Row>
        );
      },

      sorter: (a, b) => a.phone.localeCompare(b.phone),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Description",
      dataIndex: "description",
      width: "25%",
      sorter: (a, b) => a.description.localeCompare(b.description),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Create Date",
      width: "12%",
      render: (data) => {
        const created_at = moment.utc(data.created_at).format("L");
        return <>{created_at}</>;
      },
      sorter: (a, b) => a.created_at.localeCompare(b.created_at),
      sortDirections: ["descend", "ascend"],
    },
    
    
    {
      title: "Action",
      dataIndex: "id",
      width: "5%",
      render: (id) => {
        return (
          <Row justify="space-around">
            <Col>
              <Tooltip title="Delete">
                <Popconfirm
                  title="Are you sure?"
                  onConfirm={() => handleDeleteContacts(id)}
                  okText="Ok"
                  cancelText="Cancel"
                >
                  <Button icon={<img src={Delete} />} type="text" />
                </Popconfirm>
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },
  ];
  return (
    <Spin spinning={isContactusLoading}>
      <Row gutter={[32, 32]} 
      className="community"

      // style={{ margin: 10 }}
      >
        <Col span={24}></Col>
        <Col xl={16} lg={16} md={16} sm={20} xs={20}>
          <Breadcrumb 
          // style={{ margin: 10 }}
          >
            <Breadcrumb.Item
              onClick={() => onClickCommunity()}
              className="breadcrumCommunity"
            >
              Communities
            </Breadcrumb.Item>
            <Breadcrumb.Item
              onClick={() => onClickContacts()}
              className="breadcrumUsers"
            >
              Contact Us
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col xl={8} lg={8} md={8} sm={24} xs={24}>
          <Row justify="end" gutter={[8, 8]}>
            <Col xl={0} lg={0} md={0} sm={6} xs={6}></Col>
          </Row>
        </Col>

        <Col span={24}>
          <Row gutter={[32, 32]}>
            <Col span={24}>
              <CustomTable columns={Columns} data={allContactus.contacts} />
            </Col>
          </Row>
        </Col>
        {allContactus && total_length > 0 ? (
        <Col span={24}>
          <Row justify="center" 
          // style={{ margin: "20px 0px" }}
          >
            <Pagination
              total={allContactus?.total_length}
              onChange={(e) => setCurrentPage(e)}
              responsive={true}
              size="large"
              current={currentPage}
              showSizeChanger={false}
            />
          </Row>
        </Col>
        ):""}
      </Row>
    </Spin>
  );
};

export default Contactus;

