import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import "./FindOtherPeople.css";
import { Link } from "react-router-dom";
import {
  Layout,
  Menu,
  Typography,
  Row,
  Col,
  Dropdown,
  Input,
  Select,
  Button,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getUsers, logoutCurrentUser } from "../Actions/authenticationAction";
import { Route } from "react-router-dom";
import {
  MenuOutlined,
  UserOutlined,
  BarChartOutlined,
  LogoutOutlined,
  DownOutlined,
  ContactsFilled ,
  FolderOutlined,
  FlagOutlined,
  UsergroupAddOutlined,
  ToolOutlined,
  ReconciliationOutlined ,
  BookOutlined,
  CreditCardOutlined,
  SnippetsOutlined,
  CalendarOutlined,
  TeamOutlined ,
  AuditOutlined ,
  ApartmentOutlined,
  EnvironmentOutlined ,
  IdcardOutlined ,
  UserAddOutlined ,
  SolutionOutlined,
  ShoppingOutlined,
  LikeOutlined,
  ControlOutlined
} from "@ant-design/icons";
import { useBreakpoints } from "../Screens/CommonLogics/ResponsiveBreakPoints";
import { Controller, useForm } from "react-hook-form";
import { history } from "../Utils/history";
import { getCommunityById } from "../Actions/communitiesAction";
import { values } from "lodash";
const { Sider, Header, Content } = Layout;
const { Search } = Input;
const { Title } = Typography;

const Slider = (props) => {
  const { IsMD, IsSM, IsXS, IsLG, IsXL, IsXXL } = useBreakpoints();
  const [collapsed, setCollapsed] = useState(
    IsXS || IsSM || IsMD ? true : false
  );
  const [isEditProfileModalVisible, setIsEditProfileModalVisible] =
    useState(false);
  const { itemKeyValue } = props;
  const dispatch = useDispatch();
  const [itemKey, setItemKey] = useState(itemKeyValue ? itemKeyValue : 1);
  const [currentPage, setCurrentPage] = useState(1);
  const [page, setPage] = useState(1);
  const toggle = () => {
    setCollapsed(!collapsed);
  };
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: {},
  });
  const {
    currentUser,
    community,
    allCommunities,
    setCommunityId,
    searchUsers,
    allBusiness,
    allMatrimonialsSuperAdmin,
    allFamilies,
    allDocuments,
    allEvents,
    allCommunitiesNative,
    allTrustees,
    allMeetings,
    allDesignations,
    allEducations,
    allIndustries,
    allTickets,
    allMaintenanceWindows,
    allCommunityCards,
    allTemplates,
    allCountries,
    allContactus,
    allJourney,
  } = useSelector((state) => {
    const { currentUser } = state.authentication;
    const { community, allCommunities, setCommunityId } = state.communities;
    const { searchUsers } = state.searchUser;
    const { allBusiness } = state.business;
    const { allMatrimonialsSuperAdmin } = state.matrimonial;
    const { allFamilies } = state.family;
    const { allDocuments } = state.documents;
    const { allEvents } = state.events;
    const { allTrustees } = state.trustees;
    const { allMeetings } = state.communityMeetings;
    const { allCommunitiesNative } = state.communitiesNative;
    const { allDesignations } = state.designations;
    const { allEducations } = state.educations;
    const { allIndustries } = state.industry;
    const { allTickets, allArchiveTickets } = state.tickets;
    const { allMaintenanceWindows } = state.maintenance;
    const { allCommunityCards } = state.communityCards;
    const { allCommunitiesNativeVolunteer } = state.nativeVolunteers;
    const { allTemplates } = state.templates;
    const { allCountries } = state.countries;
    const { allContactus } = state.contactus;
    const { allJourney } = state.journey;
    return {
      currentUser,
      community,
      allCommunities,
      setCommunityId,
      searchUsers,
      allBusiness,
      allMatrimonialsSuperAdmin,
      allFamilies,
      allDocuments,
      allEvents,
      allCommunitiesNative,
      allTrustees,
      allMeetings,
      allDesignations,
      allEducations,
      allIndustries,
      allTickets,
      allArchiveTickets,
      allMaintenanceWindows,
      allCommunityCards,
      allCommunitiesNativeVolunteer,
      allTemplates,
      allCountries,
      allContactus,
      allJourney,
    };
  });
  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch, setCommunityId]);

  const { users_role, users_types } = currentUser || {};
  const { communities } = allCommunities;

  const logoutUserMethod = () => {
    dispatch(logoutCurrentUser());
  };

  const { component, ...rest } = props;
  const Component = component;
  const heading = [
    {
      title:"Trustees",
      path: "/communities",
      icon: <BarChartOutlined />,
    },
    {
      title: "users",
      path: "/users",
      icon: <UserOutlined />,
    },
    {
      title: "families",
      path: "/families",
      icon: <UserOutlined />,
    },
    {
      title: "journey",
      path: "/journey",
      icon: <UserOutlined />,
    },
    {
      title: "documents",
      path: "/documents",
      icon: <UserOutlined />,
    },
    {
      title: "Events",
      path: "/communityevents",
      icon: <UserOutlined />,
    },
    {
      title: "business",
      path: "/communitybusiness",
      icon: <UserOutlined />,
    },
    {
      title: "matrimonial",
      path: "/communitymatrimonial",
      icon: <UserOutlined />,
    },
    {
      title: "communitynative",
      path: "/communitynative",
      icon: <UserOutlined />,
    },
    {
      title: "communitytrustee",
      path: "/communitytrustee",
      icon: <UserOutlined />,
    },
    {
      title: "communitymeetings",
      path: "/communitymeetings",
      icon: <UserOutlined />,
    },
    {
      title: "designations",
      path: "/designations",
      icon: <UserOutlined />,
    },
    {
      title: "educations",
      path: "/educations",
      icon: <UserOutlined />,
    },
    {
      title: "Industries",
      path: "/industries",
      icon: <UserOutlined />,
    },
    {
      title: "Tickets",
      path: "/ticket",
      icon: <UserOutlined />,
    },
    {
      title: "MaintenanceWindow",
      path: "/maintenancewindow",
      icon: <UserOutlined />,
    },
    {
      title: "CommunityCards",
      path: "/communitycards",
      icon: <UserOutlined />,
    },
    {
      title: "Templates",
      path: "/templates",
      icon: <UserOutlined />,
    },
    {
      title: "countries",
      path: "/countries",
      icon: <UserOutlined />,
    },
    {
      title: "contactus",
      path: "/contactus",
      icon: <ContactsFilled />,
    },
  ];
  const customClick = (data) => {
    setItemKey(data);
    // setCollapsed(true);
  };
  const { SubMenu } = Menu;

  const businessDropDownMenu = (
    <Menu>
      <Menu.Item key="9">
        <Link to="/businessProfile">Business Profile</Link>
      </Menu.Item>
      <Menu.Item key="10">
        <Link to="/jobProfile">Job Profile</Link>
      </Menu.Item>
    </Menu>
  );

  const handleOpenCommunity = (Id) => {
    dispatch(getCommunityById(Id));
    dispatch({
      type: "GET_SELECTED_COMMUNITY_ID",

      payload: Id,
    });
  };

  let headingTitle = "";
  let defaultSelectedSliderKey = "";

  if (history.location.pathname === "/communities") {
    headingTitle = `community (${
      allCommunities && allCommunities.total_length > 0
        ? allCommunities.total_length
        : 0
    })`;
    defaultSelectedSliderKey = 88;
  } else if (history.location.pathname === "/users") {
    headingTitle = `Users (${
      searchUsers && searchUsers.total_length > 0 ? searchUsers.total_length : 0
    })`;
    defaultSelectedSliderKey = 4;
  } else if (history.location.pathname === "/families") {
    headingTitle = `Families (${
      allFamilies && allFamilies.total_length > 0 ? allFamilies.total_length : 0
    })`;
    defaultSelectedSliderKey = 5;
  } else if (history.location.pathname === "/documents") {
    headingTitle = `Documents (${
      allDocuments && allDocuments.total_length > 0
        ? allDocuments.total_length
        : 0
    })`;
    defaultSelectedSliderKey = 6;
  } else if (history.location.pathname === "/communityevents") {
    headingTitle = `Events (${
      allEvents && allEvents.total_length > 0 ? allEvents.total_length : 0
    })`;
    defaultSelectedSliderKey = 7;
  } else if (history.location.pathname === "/communitybusiness") {
    headingTitle = `Business (${
      allBusiness && allBusiness.total_length > 0 ? allBusiness.total_length : 0
    })`;
    defaultSelectedSliderKey = 8;
  } else if (history.location.pathname === "/matrimonial") {
    headingTitle = `Matrimonial (${
      allMatrimonialsSuperAdmin && allMatrimonialsSuperAdmin.total_length > 0 ? allMatrimonialsSuperAdmin.total_length : 0
    })`;
    defaultSelectedSliderKey = 9;
  } else if (history.location.pathname === "/communitynative") {
    headingTitle = `Community Native  (${
      allCommunitiesNative && allCommunitiesNative.total_length > 0
        ? allCommunitiesNative.total_length
        : 0
    })`;
    defaultSelectedSliderKey = 10;
  } else if (history.location.pathname === "/communitytrustee") {
    headingTitle = `Community Trustee  (${
      allTrustees && allTrustees.total_length > 0 ? allTrustees.total_length : 0
    })`;
    defaultSelectedSliderKey = 11;
  } else if (history.location.pathname === "/communitymeetings") {
    headingTitle = `Community Meetings  (${
      allMeetings && allMeetings.total_length > 0 ? allMeetings.total_length : 0
    })`;
    defaultSelectedSliderKey = 12;
  } else if (history.location.pathname === "/designations") {
    headingTitle = `Designations (${
      allDesignations && allDesignations.total_length > 0
        ? allDesignations.total_length
        : 0
    })`;
    defaultSelectedSliderKey = 14;
  } else if (history.location.pathname === "/educations") {
    headingTitle = `Educations (${
      allEducations && allEducations.total_length > 0
        ? allEducations.total_length
        : 0
    })`;
    defaultSelectedSliderKey = 27;
  } else if (history.location.pathname === "/journey") {
    headingTitle = `Blog (${
      allJourney && allJourney.total_length > 0
        ? allJourney.total_length
        : 0
    })`;
    defaultSelectedSliderKey = 20;
  } else if (history.location.pathname === "/countries") {
    headingTitle = `Countries (${
      allCountries && allCountries.total_length > 0 ? allCountries.total_length : 0
    })`;
    defaultSelectedSliderKey = 25;
  } else if (history.location.pathname === "/contactus") {
    headingTitle = `Contacts (${
      allContactus && allContactus.total_length > 0 ? allContactus.total_length : 0
    })`;
    defaultSelectedSliderKey = 26;
  } else if (history.location.pathname === "/native_volunteers") {
    headingTitle = `Native Volunteer (${
      allCommunitiesNative && allCommunitiesNative.total_length > 0
        ? allCommunitiesNative.total_length
        : 0
    })`;
    defaultSelectedSliderKey = 13;
  } else if (history.location.pathname === "/communities") {
    headingTitle = `Educations (${
      allEducations && allEducations.total_length > 0
        ? allEducations.total_length
        : 0
    })`;
    defaultSelectedSliderKey = 17;
  } else if (history.location.pathname === "/maintenancewindow") {
    headingTitle = `Maintenance (${
      allMaintenanceWindows && allMaintenanceWindows.length > 0
        ? allMaintenanceWindows.length
        : 0
    })`;
    defaultSelectedSliderKey = 88;
  } else if (history.location.pathname === "/industries") {
    headingTitle = `Industries (${
      allIndustries && allIndustries.total_length > 0
        ? allIndustries.total_length
        : 0
    })`;
    defaultSelectedSliderKey = 3;
  } else if (history.location.pathname === "/ticket") {
    headingTitle = `Tickets  
     (${
       allTickets && allTickets.total_length > 0 ? allTickets.total_length : 0
     })`;
    //  `ArchiveTickets(${allArchiveTickets && allArchiveTickets.total_length > 0 ? allArchiveTickets.total_length : (0)})`
    defaultSelectedSliderKey = 16;
  } else if (history.location.pathname === "/communitycards") {
    headingTitle = `CommunityCards 
     (${
       allCommunityCards && allCommunityCards.total_length > 0
         ? allCommunityCards.total_length
         : 0
     })
    `;
    defaultSelectedSliderKey = 18;
  } else if (history.location.pathname === "/templates") {
    headingTitle = `Templates 
     (${
       allTemplates && allTemplates.total_length > 0
         ? allTemplates.total_length
         : 0
     })
    `;
    defaultSelectedSliderKey = 19;
  }
  const handleCloseCommunity = () => {
    history.push("/communities");
    dispatch(getCommunityById(""));
    dispatch({
      type: "GET_SELECTED_COMMUNITY_ID",
      payload: "",
    });
  };
  const items = [
    {
      label: <Typography>Samaj Admin</Typography>,
      key: "100",
      className: "sidebarSamajTitle",
    },
  {
      label: "Centralized Fields",
      key: "submenu2",
      children: [
        {
          label: <Typography className="users"> Blog</Typography>,
          key: "20",
          icon: <ControlOutlined />,
          onClick: () => history.push("/journey"),
          className: "menuItem",
        },
        {
      label: <Typography className="users"> Educations</Typography>,
      key: "27",
      icon: <BookOutlined />,
      // <img src={Education} style={{ height: 20 }} />,
      onClick: () => history.push("/educations"),
      className: "menuItem",
    },

    {
      label: <Typography className="users"> Industries</Typography>,
      key: "3",
      icon:<ReconciliationOutlined />,
      //  <img src={Industry} style={{ height: 20 }} />,
      onClick: () => history.push("/industries"),
      className: "menuItem",
    },
    {
      label: <Typography className="users"> Maintenance</Typography>,
      key: "88",
      icon: <ToolOutlined />,
      // <img src={Maintenance} style={{ height: 20 }} />,
      onClick: () => history.push("/maintenancewindow"),
      className: "menuItem",
    },
    {
      label: <Typography className="users"> Countries</Typography>,
      key: "25",
      icon: <FlagOutlined />,
      onClick: () => history.push("/countries"),
      className: "menuItem",
    },
    {
      label: <Typography className="users"> Contact Us</Typography>,
      key: "26",
      icon: <ContactsFilled />,
      onClick: () => history.push("/contactus"),
      className: "menuItem",
    },
    
   
    {
      label: <Typography className="users"> Templates</Typography>,
      key: "19",
      icon: <IdcardOutlined />,
      // <img src={Tickets} style={{ height: 20 }} />,
      className: "menuItem",
      onClick: () => history.push("/templates"),
    },
    {
      label: <Typography className="users"> Tickets</Typography>,
      key: "16",
      icon: <CreditCardOutlined />,
      // <img src={Tickets} style={{ height: 20 }} />,
      className: "menuItem",
      onClick: () => history.push("/ticket"),
    },
  ],

  className: "menuItem",
},

    {
      label: <Typography className="users"> Communities</Typography>,
      key: "22",
      icon:<UsergroupAddOutlined />,
      //  <img src={Users} style={{ height: 20 }} />,
      // onClick:() => onClickCommunity(),
      onClick:() => handleCloseCommunity(),
      // onClick: () => history.push("/communities"),
      className: "menuItem",
    },
   
    community?.name && {
      label: community?.name,
      key: "submenu",
      children: [
        {
          label: <Typography className="users"> Business</Typography>,
          key: "8",
          icon: 
          <ShoppingOutlined />,
          // <FolderOutlined />,
          // <img src={business} style={{ height: 20 }}/>,
          className: "menuItem",
          onClick: () => history.push("/communitybusiness"),
        },
        {
          label: <Typography className="users"> Community Cards</Typography>,
          key: "18",
          icon: <FolderOutlined />,
          // <img src={CommunityCard} style={{ height: 20 }} />,
          className: "menuItem",
          onClick: () => history.push("/communitycards"),
        },
        {
          label: <Typography className="users"> Designation</Typography>,
          key: "14",
          icon: 
          <UserOutlined />,
          // <img src={Designation} style={{ height: 20 }} />,
          className: "menuItem",
          onClick: () => history.push("/designations"),
        },
        {
          label: <Typography className="users"> Documents</Typography>,
          key: "6",
          icon: <SnippetsOutlined />,
          // <img src={documents} style={{ height: 20 }}/>,
          className: "menuItem",
          onClick: () => history.push("/documents"),
        },
        {
          label: <Typography className="users"> Events</Typography>,
          key: "7",
          icon: <CalendarOutlined />,
          // <img src={events} style={{ height: 20 }}/>,
          className: "menuItem",
          onClick: () => history.push("/communityevents"),
        },
        {
          label: <Typography className="users"> Families</Typography>,
          key: "5",
          icon: <TeamOutlined />,
          // <img src={families} style={{ height: 20 }} />,
          className: "menuItem",
          onClick: () => history.push("/families"),
        },
        {
          label: <Typography className="users"> Matrimonial</Typography>,
          key: "9",
          icon: <AuditOutlined />,
          // <img src={matrimonial} style={{ height: 20 }}/>,
          className: "menuItem",
          onClick: () => history.push("/matrimonial"),
        },
        {
          label: <Typography className="users"> Meetings</Typography>,
          key: "12",
          icon: <ApartmentOutlined />,
          // <img src={Meeting} style={{ height: 20 }} />,
          className: "menuItem",
          onClick: () => history.push("/communitymeetings"),
        },
        {
          label: <Typography className="users"> Native</Typography>,
          key: "10",
          icon: <EnvironmentOutlined />,
          // <img src={Native} style={{ height: 20 }} />,
          className: "menuItem",
          onClick: () => history.push("/communitynative"),
        },
        {
          label: <Typography className="users"> Native Volunteers</Typography>,
          key: "13",
          icon: <LikeOutlined />,
          // <img src={Volunteer} style={{ height: 20 }} />,
          className: "menuItem",
          onClick: () => history.push("/native_volunteers"),
        },
       
        {
          label: <Typography className="users"> Trustee</Typography>,
          key: "11",
          icon: <SolutionOutlined />,
          // <img src={Trustee} style={{ height: 20 }} />,
          className: "menuItem",
          onClick: () => history.push("/communitytrustee"),
        },
        {
          label: <Typography className="users"> Users</Typography>,
          key: "4",
          icon: <UserAddOutlined />,
          // <img src={Users} style={{ height: 20 }} />,
          className: "menuItem",
          onClick: () => history.push("/users"),
        },
      ],

      className: "menuItem",
    },

    {
      label: "Log Out",
      key: "15",
      icon: <LogoutOutlined />,
      onClick: () => logoutUserMethod(),
      className: "menuItem",
    },
  ];

  const openEditProfileMethod = () => {
    setIsEditProfileModalVisible(true);
  };
  const isUserVolunteer = users_types?.includes("volunteer");
  const isUserAdmin = users_types?.includes("admin");

  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <>
            <div
            >
              <Layout className="hidden">
                <Sider
                  trigger={null}
                  collapsible
                  collapsed={
                    // isEditProfileModalVisible ? collapsed === true : !collapsed
                    collapsed
                  }
                  style={{
                    background: "#FBFBFB",
                    borderadius: "2px",
                    borderRight: "1px solid #EEEEEE",
                  }}
                  collapsedWidth={0}
                  width={IsXS || IsSM ? "50%" : 250}
                  className="SideBar"
                >
                  <Menu
                    onClick={(e) => customClick(e.key)}
                    mode="inline"
                    style={{ background: "#FBFBFB" }}
                    items={items}
                    // defaultOpenKeys={IsXXL || IsXL || IsLG || IsMD || IsXS? "submenu" && "submenu2" :""}
                    defaultOpenKeys={[ "submenu"]}
                    selectedKeys={[
                      `${
                        defaultSelectedSliderKey
                          ? defaultSelectedSliderKey
                          : 
                          itemKey
                      }`,
                    ]}
                  />

                </Sider>
                <Layout>
                  <Header className="header-background" 
                  style={{ padding: 0 }}
                  >
                    <Row
                      align="middle"
                      justify="start"
                      gutter={[{ md: 16, lg: 16, sm: 16, xs: 20 }, 8]}
                    >
                      <Col
                        xl={1}
                        lg={2}
                        md={1}
                        sm={1}
                        xs={2}
                        onClick={() => toggle()}
                        style={{ cursor: "pointer" }}
                      >
                        <MenuOutlined style={{ paddingLeft: 4 }} />{" "}
                      </Col>

                      <Col xl={6} lg={8} md={10} sm={12} xs={13}>
                        {heading[itemKey - 1]?.title !==
                         "Business Profile" && (
                          <Title
                            level={5}
                            style={{ marginTop: "7px" }}
                            className="headerText"
                          >
                            {headingTitle}
                          </Title>
                        )}
                        {heading[itemKey - 1]?.title ===
                          "Business Profile" && (
                          <Dropdown
                            overlay={businessDropDownMenu}
                            trigger={["click"]}
                          >
                            <Title level={5} style={{ cursor: "pointer" }}>
                              Business Profile <DownOutlined />
                            </Title>
                          </Dropdown>
                        )}
                      </Col>
                      <Col xl={2} lg={2} md={1} sm={0} xs={0}></Col>
                    </Row>
                  </Header>

                  <Content>
                    <Component />
                  </Content>
                </Layout>
              </Layout>
            </div>
          </>
        );
      }}
    />
  );
};

export default Slider;

